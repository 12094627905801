import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from 'react-redux';

import { createStore } from 'redux';
import rootReducer from './state/reducer'; // You'll create this file shortly
import ReactImageMagnify from "react-image-magnify";
const store = createStore(rootReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

// import { Provider } from "react-redux";
// import { configureStore } from "@reduxjs/toolkit";
// import rootReducer from "./reducer/index";


// const store = configureStore({
//   reducer: rootReducer,
// });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  < >
    <Provider store={store}>

    <App />
    </Provider>
  </>
);

reportWebVitals();
