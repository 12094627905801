import React, { useEffect, useState } from "react";
// import "./styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Product from "../assets/img/product.png";
export default function ModalSLiderBig(props) {
  const [selectedImage, setSelectedImage] = useState(Product);

  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  // };

  const { data, setMainImage, MainImage } = props;

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: data.length>4?4:data.length,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
            slidesToShow: data.length>3?3:data.length
        }
    },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: data.length>2?2:data.length
        }
      }
    ]
  };


  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  function makeAtLeastSixElements(arr) {
    const originalLength = arr.length;
    const desiredLength = Math.max(6, originalLength); 
    while (arr.length < desiredLength) {
      arr.push(...arr.slice(0, desiredLength - arr.length)); 
    }
    return arr.slice(0, 6); 
  }
  

  return (
    <div style={{ padding: "20px 0px" }}>
      <Slider {...settings}>
        {data && data.map((d, index) => {
          return (
            <div
              key={index}
              onClick={() =>
                setMainImage(data[index].Image)
              }
            >
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${d.Image}`}
                alt="img"
                style={{
                  width: "150px",
                  // border: data.indexOf(MainImage) === index ? "1px solid #000000" : "",
                }} 
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
