import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import { Puff } from "react-loader-spinner";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { useSelector } from "react-redux";

const AddressForm = ({loadAddresses2}) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [addresses, setaddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const authToken = localStorage.getItem("token"); // Retrieve the token from local storage

  const config = {
    headers: {
      Authorization: authToken, // Include the token in the "Authorization" header
    },
  };
  const loggedinUserInfo = useSelector((state) => state.loggedinUserInfo);

  const loadAddresses = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_URL + "/shippingAddress/get-shipping-address"
      ) // Replace with your API endpoint
      .then((response) => {
        setaddresses(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error loading addresses: " + error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadAddresses();
    getCountryList();
  }, []);

  const getCountryList=()=>{
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          "/countryMaster/get-country-master")
      .then((response) => {
        setCountries(response.data);
      })
      .catch((error) => {
        console.error("Error getting countries: " + error);
      });
  }

  const addAddress = (values) => {
    axios
      .post(
        process.env.REACT_APP_BASE_URL +
          "/shippingAddress/add-shipping-Address",
        {
          FirstName: values.FirstName,
          LastName: values.LastName,
          MobileNo: values.MobileNo,
          EmailId: values.EmailId,
          CountryName: values.country,
          CityName: values.CityName,
          StateName: values.StateName,
          Pincode: values.Pincode,
          Address: values.Address,
          OrderNotes: values.OrderNotes,
          IsDefault: values.IsDefault,
        }
      ) // Replace with your API endpoint
      .then((response) => {
        setIsFormOpen(false);
        loadAddresses();
        loadAddresses2();
      })
      .catch((error) => {
        console.error("Error adding Address: " + error);
      });
  };

  const makeAddressDefault = (addressId) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/shippingAddress/update-shipping-Address/${addressId}`,
        { IsDefault: "true" }
      ) // Replace <<url>> with your API base URL
      .then((response) => {
        loadAddresses();
        loadAddresses2();
        // Handle the success response (e.g., update the UI or show a success message)
      })
      .catch((error) => {
        console.error("Error setting address as default: " + error);
      });
  };

  const handleDelete = async (addressId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/shippingAddress/delete-shipping-Address/${addressId}`
      );

      loadAddresses();
      loadAddresses2();
      // You can handle the response as needed, such as showing a success message to the user.
    } catch (error) {
      console.error("Error deleting record:", error);
      // Handle errors, show an error message, etc.
    }
  };

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  // const countries = [
  //   "India",
  //   "Albania",
  //   "Algeria",
  //   "Andorra",
  //   "Angola",
  //   "United States of America",
  // ];

  const schema = yup.object().shape({
    FirstName: yup.string().required("First name is required"),
    LastName: yup.string().required("Last name is required"),
    MobileNo: yup
      .string()
      .required("Invalid mobile number")
      .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits"),
    EmailId: yup
      .string()
      .required("EmailId is required")
      .email("Invalid EmailId format")
      .matches(
        /@.*(\.com|\.net)$/,
        "Not proper email format"
      ),
    country: yup.string().required("Country is required"),
    CityName: yup.string().required("CityName is required"),
    StateName: yup.string().required("StateName is required"),
    Pincode: yup.string().required("Pincode is required"),
    Address: yup.string().required("Address is required"),
  });

  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
                  <Row>
                    <Col lg={12}>
                      <div className="tab-content dashboard_content">
                        <div
                          className="tab-pane fade show active"
                          id="MyAccount"
                        >
                          <div className="login">
                            <div className="login_form_container">
                              <div className="account_login_form">
                                <Row className="mt-30">
                                  <Col lg={12}>
                                    <div className="add-address">
                                      <Link
                                        to="#"
                                        className={`collapsed ${
                                          isFormOpen ? "" : "collapsed"
                                        }`}
                                        data-toggle="collapse"
                                        data-target="#collapseOne"
                                        aria-controls="collapseOne"
                                        aria-expanded={
                                          isFormOpen ? "true" : "false"
                                        }
                                        onClick={toggleForm}
                                      >
                                        <div className="box-1">
                                          <div className="a-box">
                                            <BsPlusLg
                                              className={`bi bi-plus ${
                                                isFormOpen ? "rotate" : ""
                                              }`}
                                            />
                                            <h2>Add Address</h2>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="mt-30">
                                  <Col lg={12}>
                                    <div className="">
                                      <Row className="head-add  row d-flex">
                                        {addresses
                                          ? addresses.map((address, index) => (
                                              <Col
                                                key={address.id}
                                                lg={6}
                                                className="mb-5 p-3 address-col"
                                              >
                                                <h5 className="text-white">
                                                  {address.FirstName}{" "}
                                                  {address.LastName}
                                                </h5>
                                                <p>{address.EmailId}</p>
                                                <p>{address.Address}</p>
                                                <div className="order_button mt-3 text-center">
                                                  {address.IsDefault ? (
                                                    <Button
                                                      type="button"
                                                      style={{ fontSize: 15 }}
                                                      className="me-2"
                                                    >
                                                      Default
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      type="button"
                                                      style={{ fontSize: 15 }}
                                                      className="me-2"
                                                      onClick={() =>
                                                        makeAddressDefault(
                                                          address._id
                                                        )
                                                      }
                                                    >
                                                      Make This Default
                                                    </Button>
                                                  )}
                                                  <Button
                                                    type="button"
                                                    onClick={() => {
                                                      handleDelete(address._id);
                                                    }}
                                                  >
                                                    Delete
                                                  </Button>
                                                </div>
                                              </Col>
                                            ))
                                          : ""}
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                                {isFormOpen && (
                                  <Formik
                                    validationSchema={schema}
                                    onSubmit={addAddress}
                                    initialValues={{
                                      FirstName: "",
                                      LastName: "",
                                      MobileNo: "",
                                      EmailId: "",
                                      country: "",
                                      CityName: "",
                                      StateName: "",
                                      Pincode: "",
                                      Address: "",
                                      OrderNotes: "",
                                      IsDefault: false,
                                    }}
                                  >
                                    {({
                                      handleSubmit,
                                      handleChange,
                                      values,
                                      touched,
                                      errors,
                                    }) => (
                                      <Form noValidate onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                          <Col lg={6}>
                                            <Form.Group
                                              controlId="validationFormik101"
                                              className="position-relative"
                                            >
                                              <Form.Label>
                                                First name*
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="FirstName"
                                                value={values.FirstName}
                                                onChange={handleChange}
                                                isValid={
                                                  touched.FirstName &&
                                                  !errors.FirstName
                                                }
                                                isInvalid={
                                                  touched.FirstName &&
                                                  !!errors.FirstName
                                                }
                                                placeholder="First Name"
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                {errors.FirstName}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={6}>
                                            <Form.Group
                                              controlId="validationFormik102"
                                              className="position-relative"
                                            >
                                              <Form.Label>
                                                Last name*
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="LastName"
                                                value={values.LastName}
                                                onChange={handleChange}
                                                isValid={
                                                  touched.LastName &&
                                                  !errors.LastName
                                                }
                                                isInvalid={
                                                  touched.LastName &&
                                                  !!errors.LastName
                                                }
                                                placeholder="Last Name"
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                {errors.LastName}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Col lg={6}>
                                            <Form.Group
                                              controlId="validationFormik103"
                                              className="position-relative"
                                            >
                                              <Form.Label>MobileNo*</Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="MobileNo"
                                                value={values.MobileNo}
                                                onChange={handleChange}
                                                isValid={
                                                  touched.MobileNo &&
                                                  !errors.MobileNo
                                                }
                                                isInvalid={
                                                  touched.MobileNo &&
                                                  !!errors.MobileNo
                                                }
                                                placeholder="MobileNo"
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                {errors.MobileNo}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={6}>
                                            <Form.Group
                                              controlId="validationFormik104"
                                              className="position-relative"
                                            >
                                              <Form.Label>
                                                EmailId Address*
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="EmailId"
                                                value={values.EmailId}
                                                onChange={handleChange}
                                                isValid={
                                                  touched.EmailId &&
                                                  !errors.EmailId
                                                }
                                                isInvalid={
                                                  touched.EmailId &&
                                                  !!errors.EmailId
                                                }
                                                placeholder="EmailId"
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                {errors.EmailId}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                        <Row className="mb-3">
                                          <Col lg={3}>
                                            <Form.Group
                                              controlId="validationFormik106"
                                              className="position-relative"
                                            >
                                              <Form.Label>Country*</Form.Label>
                                              <Form.Control
                                                as="select"
                                                name="country"
                                                value={values.country}
                                                onChange={handleChange}
                                                isValid={
                                                  touched.country &&
                                                  !errors.country
                                                }
                                                isInvalid={
                                                  touched.country &&
                                                  !!errors.country
                                                }
                                              >
                                                <option value="">
                                                  Select a country
                                                </option>
                                                {countries.length>0 && countries.map(
                                                  (country, index) => (
                                                    <option
                                                      key={index}
                                                      value={country.CountryName}
                                                    >
                                                      {country.CountryName}
                                                    </option>
                                                  )
                                                )}
                                              </Form.Control>
                                              <Form.Control.Feedback type="invalid">
                                                {errors.country}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={3}>
                                            <Form.Group
                                              controlId="validationFormik108"
                                              className="position-relative"
                                            >
                                              <Form.Label>CityName*</Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="CityName"
                                                value={values.CityName}
                                                onChange={handleChange}
                                                isValid={
                                                  touched.CityName &&
                                                  !errors.CityName
                                                }
                                                isInvalid={
                                                  touched.CityName &&
                                                  !!errors.CityName
                                                }
                                                placeholder="CityName"
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                {errors.Pincode}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={3}>
                                            <Form.Group
                                              controlId="validationFormik107"
                                              className="position-relative"
                                            >
                                              <Form.Label>
                                                StateName*
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="StateName"
                                                value={values.StateName}
                                                onChange={handleChange}
                                                isValid={
                                                  touched.StateName &&
                                                  !errors.StateName
                                                }
                                                isInvalid={
                                                  touched.StateName &&
                                                  !!errors.StateName
                                                }
                                                placeholder="StateName"
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                {errors.StateName}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={3}>
                                            <Form.Group
                                              controlId="validationFormik108"
                                              className="position-relative"
                                            >
                                              <Form.Label>Pincode*</Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="Pincode"
                                                value={values.Pincode}
                                                onChange={handleChange}
                                                isValid={
                                                  touched.Pincode &&
                                                  !errors.Pincode
                                                }
                                                isInvalid={
                                                  touched.Pincode &&
                                                  !!errors.Pincode
                                                }
                                                placeholder="Pincode"
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                {errors.Pincode}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                        <Form.Group className="position-relative mb-3">
                                          <Form.Label>Address*</Form.Label>
                                          <Form.Control
                                            type="text"
                                            name="Address"
                                            value={values.Address}
                                            onChange={handleChange}
                                            isValid={
                                              touched.Address && !errors.Address
                                            }
                                            isInvalid={
                                              touched.Address &&
                                              !!errors.Address
                                            }
                                            placeholder="Address"
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.Address}
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="position-relative mb-3">
                                          <Form.Label>Order Notes</Form.Label>
                                          <Form.Control
                                            type="text"
                                            name="OrderNotes"
                                            value={values.OrderNotes}
                                            onChange={handleChange}
                                            isValid={
                                              touched.OrderNotes &&
                                              !errors.OrderNotes
                                            }
                                            isInvalid={
                                              touched.OrderNotes &&
                                              !!errors.OrderNotes
                                            }
                                            placeholder="Notes about your order, e.g. special notes for delivery"
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.OrderNotes}
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="position-relative mb-3 d-flex justify-content-between">
                                          <Button type="submit">Save</Button>
                                        </Form.Group>
                                      </Form>
                                    )}
                                  </Formik>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
  );
};

export default AddressForm;