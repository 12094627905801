import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { getNewArrival } from "../helpers/backen_helper";
import ProductCard from "./ProductCard";
import { Carousel } from "react-bootstrap";
import Slider from "react-slick";

const NewAraival = () => {
  var settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const currency = useSelector((state) => state.selectedCurrency);
  const [newArrivalData, setNewArrivalData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await getNewArrival();
      setNewArrivalData(response);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  



  useEffect(() => {
    fetchData();
  }, []);
  return (
     <React.Fragment>
       <div className="container pb-100 pt-100 ">
         <div className="row">
           <div className="section-title">
             <h2>Featured Products</h2>
           </div>

           <div className="slider-container">
           <Slider {...settings}>
           {newArrivalData&&newArrivalData.map((n,index) => (
             <div className="col-sm-6 col-lg-3 slick" key={index}>
               <ProductCard
                 imageSource={n.Image}
                 productId={n._id}
                 productTitle={n.ProductName}
                 price={n.MRP}
                 ProductColorId={n.ProductColorId}
                 ProductGroupSizeId={n.ProductGroupSizeId}
               >
               </ProductCard>

                </div>
           ))}
           </Slider>
           </div>
     </div>
   </div>
 </React.Fragment>

    

  );
};

export default NewAraival;
