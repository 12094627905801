import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OfferImg from "../assets/img/offerImg.png";
import DealShap from "./../assets/img/deal-shape1.png";

const DealOfTheDay = () => {
  const hotOfferData = [
    {
      parsentage: "-20%",
      img: OfferImg,
      title: "Best Skate",
      price: "$130.00",
      deal: "$150.00",
      date: "31 December",
      color: "#ff0f4e",
    },
    {
      parsentage: "-20%",
      img: OfferImg,
      title: "Best Skate",
      price: "$130.00",
      deal: "$150.00",
      date: "32 December",
      color: "#50a3ff",
    },
  ];
  const loggedinUserInfo = useSelector((state) => state.loggedinUserInfo);
  return (
    <React.Fragment>
      <div className="deal-area pt-100 pb-70">
        {/* <div className="container">
          <div className="row">
            {hotOfferData?.map((data,index) => (
              <div className="col-lg-6" key={index}>
                <div className="section-title">
                  <h2>Hot Offers</h2>
                </div>
                <div
                  className="deal-item two"
                  style={{ backgroundColor: data.color }}
                >
                  <span className="percent">{data.parsentage}</span>
                  <Link className="deal-btn" to="/view-all-offer">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth={0}
                      viewBox="0 0 1024 1024"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 0 0 0-48.4z" />
                    </svg>
                  </Link>
                  <div className="inner align-items-center">
                    <div className="left">
                      <img src={data.img} alt="Deal" />
                    </div>
                    <div className="right">
                      <h3>{data.title}</h3>
                      <ul className="price">
                        <li>{data.price}</li>
                        <li>
                          <del>{data.deal}</del>
                        </li>
                      </ul>
                      <h4>
                        Get This Product Within: <span>{data.date}</span>
                      </h4>
                      <ul className="cart-wishlist">
                        <li>
                          <a href="/">
                            <i className="bx bxs-cart">
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 1024 1024"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M922.9 701.9H327.4l29.9-60.9 496.8-.9c16.8 0 31.2-12 34.2-28.6l68.8-385.1c1.8-10.1-.9-20.5-7.5-28.4a34.99 34.99 0 0 0-26.6-12.5l-632-2.1-5.4-25.4c-3.4-16.2-18-28-34.6-28H96.5a35.3 35.3 0 1 0 0 70.6h125.9L246 312.8l58.1 281.3-74.8 122.1a34.96 34.96 0 0 0-3 36.8c6 11.9 18.1 19.4 31.5 19.4h62.8a102.43 102.43 0 0 0-20.6 61.7c0 56.6 46 102.6 102.6 102.6s102.6-46 102.6-102.6c0-22.3-7.4-44-20.6-61.7h161.1a102.43 102.43 0 0 0-20.6 61.7c0 56.6 46 102.6 102.6 102.6s102.6-46 102.6-102.6c0-22.3-7.4-44-20.6-61.7H923c19.4 0 35.3-15.8 35.3-35.3a35.42 35.42 0 0 0-35.4-35.2zM305.7 253l575.8 1.9-56.4 315.8-452.3.8L305.7 253zm96.9 612.7c-17.4 0-31.6-14.2-31.6-31.6 0-17.4 14.2-31.6 31.6-31.6s31.6 14.2 31.6 31.6a31.6 31.6 0 0 1-31.6 31.6zm325.1 0c-17.4 0-31.6-14.2-31.6-31.6 0-17.4 14.2-31.6 31.6-31.6s31.6 14.2 31.6 31.6a31.6 31.6 0 0 1-31.6 31.6z" />
                              </svg>
                            </i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
        <div className="container">
          <div
            className="deal-black"
            style={{ width: "50%", marginLeft: "300px" }}
          >
            <img src={DealShap} alt="Shape" />
            <h3>free shipping on purchases of $400!</h3>
            <Link to="/product-list/3">View Products</Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default DealOfTheDay;
