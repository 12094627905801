import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import SizeIcon from "./../img/size.svg";

function SizeChartModal(props) {
  const [lgShow, setLgShow] = useState(false);

  return (
    <>
      <span>
        <img
          onClick={() => setLgShow(true)}
          src={SizeIcon}
          alt="icon"
          style={{ cursor: "pointer" }}
        />
      </span>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Size Chart
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: props?.Description }} />

          <div dangerouslySetInnerHTML={{ __html: props?.NoteDescription }} />

        </Modal.Body>
      </Modal>
    </>
  );
}

export default SizeChartModal;
