import Rating from "@mui/material/Rating";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

import Slider from "react-slick";
import Form from "react-bootstrap/Form";
import {
  AiFillYoutube,
  AiOutlineHeart,
  AiOutlineInstagram,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import ReactImageZoom from "react-image-zoom";
import { Puff } from "react-loader-spinner";
import { Link, useParams } from "react-router-dom";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ModalSLiderBig from "../Component/ModalSLiderBig";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import SizeChartModal from "../assets/modal/SizeChart";
import Shape1 from "./../assets/img/shape1.png";
import Shape2 from "./../assets/img/shape2.png";

import { Input } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import { addToCart, addToWishlist } from "../helpers/backen_helper";
import "./../css/product.css";
import ReltedProduct from "../Component/ReltedProduct";
import ProductCard from "../Component/ProductCard";
import BottomHeader from "../Component/BottomHeader";
const ProductDetails = () => {
  const { ProductId, ProductGroupSizeId, ProductColorId } = useParams();

  const currency = useSelector((state) => state.selectedCurrency);

  const [productData, setProductData] = useState([]);
  const [ProductSizeData, setProductSizeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [MainImage, setMainImage] = useState(null);
  const [groupId, setGroupID] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const [rating, setRating] = useState(0); // Initial rating value
  const [productRating, setProductRating] = useState(0);

  const onStarClick = (nextValue) => {
    setRating(nextValue);
  };

  const validSegment = (string) => {
    if (!string || typeof string !== "string") {
      return "sr";
    }

    const newString = string
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase();

    return newString; // Return the modified string
  };

  const userLoggedIN = useSelector((state) => state.loggedinUserInfo);
  var settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/productMaster/get-Specific-Product/${ProductId}/${ProductColorId}/${ProductGroupSizeId}`
      );

      setProductData(response.data);

      setProductSizeData(response.data.productSizes);

      setMainImage(response.data.ProductsImages[0].Image);

      setGroupID(response.data.productDetails.ProductGroupId);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [Message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Send the Message using Axios POST request
      await axios.post(`${process.env.REACT_APP_BASE_URL}/review/addreview`, {
        Rating: rating,
        ProductParametersId: parseInt(productData._id),
        ProductId: productData.ProductId,
        Message: Message,
      });

      // Reset the form after successful submission
      setMessage("");
      getReviews();
    } catch (error) {
      console.error("Error submitting Message:", error);
    }
  };
  const getReviews = async (pId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/review/getreviews/${pId}`
      );
      const reviewsData = response.data;
      let ratings = [];
      let averageRating = 0;

      if (reviewsData && reviewsData.length > 0) {
        ratings = reviewsData.map((item) => item.Rating);
        const totalRatings = ratings.reduce((acc, rating) => acc + rating, 0);
        averageRating = totalRatings / ratings.length;
        setProductRating(averageRating);
      }
      setReviews(reviewsData);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  const handleCommentChange = (event) => {
    setMessage(event.target.value);
  };

  const [reviews, setReviews] = useState([]);
  const [quantity, setQuantity] = useState(0);

  const [inputValue, setInputValue] = useState(1);

  // Function to handle the input change
  const handleInputChange = (event) => {
    if (event.target.value < 1) {
      return;
    }
    setInputValue(event.target.value);
  };

  const dispatch = useDispatch();

  // const handleImageError = (event) => {
  //   event.target.src = noImage;
  // };

  const handleAddToCart = async (productId, productParameterId) => {
    if (localStorage.getItem("token")) {
      await addToCart(
        productId,
        productParameterId,
        dispatch,
        parseInt(quantity)
      );
      navigate("/my-cart");
      notify("product added to your cart", "✅");
    } else {
      navigate("/login");
    }
  };
  const notify = (message, icon) =>
    toast(message, {
      duration: 2000,
      position: "top-right",
      style: {},
      className: "",
      icon: icon,
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });

  const handleAddToWishlist = async (productId, productParameterId) => {
    if (localStorage.getItem("token")) {
      addToWishlist(productId, productParameterId, dispatch);

      notify("product added to your wishlist", "✅");
    } else {
      navigate("/login");
    }
  };
  const increment = () => {
    setQuantity(quantity + 1);
  };
  const decrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const handleSizeChange = (event) => {
    const selectedSizeId = event.target.value;
    const { ProductName } = productData.productDetails || {};

    if (selectedSizeId) {
      const url = `/product-details/${validSegment(
        ProductName
      )}/${ProductId}/${ProductColorId}/${selectedSizeId}`;
      navigate(url); // Navigate to the new URL
    }
  };

  const moveToElement = (id) => {
    const specificElement = document.getElementById(id);

    if (specificElement) {
      specificElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    setLoading(true);

    fetchData().then(() => {
      setLoading(false);
    });

    getReviews(ProductId);
  }, [ProductColorId, ProductGroupSizeId]);

  return loading ? (
    <div className="loader-container">
      <Puff
        color="#000"
        height={50}
        width={50}
        timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
      />
    </div>
  ) : (
    <React.Fragment>
      <Toaster />
      <HaderTwo />
      <SliderTwo Title="Product Details" SubTitle="Product Details" />
      <BottomHeader productPage={true}/>

      <section>
        <div className="container">
          <Row className="productRow">
            <div className="col-lg-6 col-md-6">
              <div className="details-gallery">
                <ReactImageZoom
                  {...{
                    width: 550,
                    height: 550,
                    zoomStyle: { height: 1200, width: "auto" },
                    zoomWidth: 550,
                    zoomPosition: "original",
                    img: `${process.env.REACT_APP_BASE_URL}/${MainImage}`,
                    alt: "no image",
                  }}
                />

                <div className="container"></div>
                {productData.ProductsImages &&
                  productData.ProductsImages.length > 0 && (
                    <ModalSLiderBig
                      data={productData.ProductsImages.filter(
                        (image) => image.IsActive
                      )}
                      setMainImage={setMainImage}
                      MainImage={MainImage}
                    />
                  )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="details-content">
                <h3 className="details-name">
                  <Link to="#">
                    {productData.productDetails
                      ? productData.productDetails.ProductName
                      : null}
                  </Link>
                </h3>
                <Row>
                  <Col md={8} className="col">
                    <div className="mt-2">
                      {productRating === 0 ? (
                        <button
                          onClick={() => {
                            if (userLoggedIN) {
                              moveToElement("rating");
                            } else {
                              navigate("/login");
                            }
                          }}
                          className="button-style"
                        >
                          Be the first person to rate this product!
                        </button>
                      ) : (
                        <div
                          onClick={() => {
                            moveToElement("reviews");
                          }}
                        >
                          <Rating
                            name="half-rating"
                            defaultValue={productRating}
                            precision={0.5}
                            readOnly
                          />{" "}
                          {productRating.toFixed(1)}/5
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col className="text-end col" md={4} sm={6}>
                    <div className="details-meta">
                      <img
                        style={{ height: "30px", width: "auto" }}
                        src={`${process.env.REACT_APP_BASE_URL}/${productData.Brand
                            ? productData.Brand.BrandImage
                            : null
                          }`}
                      ></img>
                    </div>
                  </Col>
                </Row>

                <h3 className="details-price mt-3">
                  <span>
                    {" "}
                    {currency.symbol}
                    {" "}
                    {productData
                      ? (productData.MRP * currency.value).toFixed(2)
                      : null}{" "}
                    <small></small>
                  </span>
                </h3>
                <p className="details-desc">
                  {productData.productDetails
                    ? productData.productDetails.ProductShortDesc
                    : null}
                </p>

                <div className="">
  <h6>Available Color Options</h6>
  <div className="slider-container">
    {productData.ProductColorImages ? (
      productData.ProductColorImages && productData.ProductColorImages.length < 4 ? (
        <div className="row">
          {productData.ProductColorImages.map((item, index) => (
            <div className=" col-4 mb-3 d-flex " key={index}>
              <Link
                to={`/product-details/${item?.ProductName ? validSegment(productData.productDetails && productData.productDetails.ProductName) : "somthing"}/${ProductId}/${item.ProductColorId}/${ProductGroupSizeId}`}
              >
                <Card className="p-1">
                  <img
                    className="productImg"
                    src={`${process.env.REACT_APP_BASE_URL}/${item.Image}`}
                    alt={`Image ${index}`}
                  />
                </Card>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <Slider {...settings}>
          {productData.ProductColorImages && productData.ProductColorImages.map((item, index) => (
            <div className="slick-item" key={index}>
              <Link
                to={`/product-details/${item?.ProductName ? validSegment(productData.productDetails && productData.productDetails.ProductName) : "somthing"}/${ProductId}/${item.ProductColorId}/${ProductGroupSizeId}`}
              >
                <Card className="p-1 m-1">
                  <img
                    className="productImg"
                    src={`${process.env.REACT_APP_BASE_URL}/${item.Image}`}
                    alt={`Image ${index}`}
                  />
                </Card>
              </Link>
            </div>
          ))}
        </Slider>
      )
    ) : (
      <p>No product color images available.</p>
    )}
  </div>
</div>


                <Row className="mt-3">
                  {ProductGroupSizeId > 0 && (
                    <Col lg={5} md={6} sm={8}>
                      <label htmlFor="sizeSelect">Size:</label>
                      <Form.Select
                        aria-label="Default select example"
                        value={
                          productData.ProductGroupSize &&
                          productData.ProductGroupSize._id
                        }
                        onChange={handleSizeChange}
                      >
                        <option value="">Select a size</option>
                        {ProductSizeData
                          ? ProductSizeData.map((size) => (
                            <option key={size._id} value={size._id}>
                              {size.Size}
                            </option>
                          ))
                          : null}
                      </Form.Select>
                    </Col>
                  )}
                  <Col lg={4} md={6} sm={3}>
                    <label htmlFor="quantityInput">Quantity:</label>
                    <div className="main" style={{ display: "flex" }}>
                      <div className="innerbutton obj">
                        <button
                          className="plus-minus-button increase-minus"
                          onClick={decrement}
                        >
                          -
                        </button>
                      </div>
                      <div className="innermain">

                        {quantity}

                      </div>
                      <div className="innerbutton">
                        <button
                          className="plus-minus-button"
                          onClick={increment}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </Col>
                  {ProductGroupSizeId > 0 &&
                    productData?.Chart &&
                    productData.Chart.length > 0 && (
                      <Col lg={3} className="sizeSection">
                        <label>Size Chart:</label>
                        <h5>
                          <SizeChartModal
                            Description={productData.Chart[0]?.Description}
                            NoteDescription={
                              productData.Chart[0]?.NoteDescription
                            }
                          />
                        </h5>
                      </Col>
                    )}
                </Row>

                <Row className="addBtn">
                  <Col lg="4">
                    <button
                      className="product-add"
                      title="Add to Cart"
                      style={{
                        backgroundColor: "#c40316",
                        border: "1px solid #c40316",
                      }}
                      onClick={() =>
                        handleAddToCart(productData.ProductId, productData._id)
                      }
                    >
                      <i className="fas fa-shopping-basket">
                        <AiOutlineShoppingCart className="addCart" />
                      </i>
                      <span>add to cart</span>
                    </button>
                  </Col>
                  <Col lg="4">
                    <button
                      className="product-add"
                      style={{
                        backgroundColor: "#4eba5c",
                        border: "1px solid #4eba5c",
                      }}
                      title="wish list"
                      onClick={() =>
                        handleAddToWishlist(
                          productData.ProductId,
                          productData._id
                        )
                      }
                    >
                      <i className="fas fa-shopping-basket">
                        <AiOutlineHeart className="addCart" />
                      </i>
                      <span>wish list</span>
                    </button>
                  </Col>
                </Row>

                <div className="details-list-group">
                  <label className="details-list-title">Share:</label>
                  <ul className="details-share-list">
                    <li>
                      <Link
                        to="https://www.facebook.com/SimmonsRana"
                        className="icofont-facebook"
                        title="Facebook"
                        target="_blanck"
                        style={{
                          backgroundColor: "rgb(59, 89, 152) ",
                        }}
                      >
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://twitter.com/racingrana"
                        className="icofont-twitter"
                        title="Twitter"
                        target="_blanck"
                        style={{ backgroundColor: "#00aced" }}
                      >
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.youtube.com/@simmonsranaracing5226"
                        className="icofont-linkedin"
                        title="Youtube"
                        target="_blanck"
                        style={{ backgroundColor: "#c40316" }}
                      >
                        <AiFillYoutube />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.instagram.com/simmonsranaracing/"
                        className="icofont-instagram"
                        target="_blanck"
                        title="Instagram"
                        style={{ backgroundColor: "#bc2a8d" }}
                      >
                        <AiOutlineInstagram />
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* <div className="details-add-group">
                  <button className="product-add" title="By Now">
                    <span> By now</span>
                  </button>
                </div> */}
              </div>
            </div>
          </Row>
        </div>
      </section>
      <section className="inner-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {productData?.productDetails?.ProductDescription && (
                <div className="product-details-frame">
                  <h3 className="frame-title">Description</h3>
                  <div className="tab-descrip">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: productData.productDetails
                          ? productData.productDetails.ProductDescription
                          : null,
                      }}
                    ></div>
                  </div>
                </div>
              )}
              {productData?.productDetails?.Specification && (
                <div className="product-details-frame">
                  <h3 className="frame-title">Specification</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: productData.productDetails
                        ? productData.productDetails.Specification
                        : null,
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <TopProduct Title="Related This Items" DetailsBtn="view all related" /> */}
      </section>
      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="details-item">
                {reviews.length !== 0 && (
                  <div className="details-comments">
                    <h3 id="reviews">Reviews</h3>
                    <ul>
                      {reviews &&
                        reviews.map((review, index) => (
                          <li key={index}>
                            <h4>{review.custommerName}</h4>
                            <div className="mainDivRating">
                              <StarRatingComponent
                                name="rating"
                                starCount={5} // Number of stars
                                value={review.Rating}
                              />
                            </div>
                            <span>{formatDate(review.ATime)}</span>
                            <p>{review.Message}</p>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
                {userLoggedIN && (
                  <div className="details-form" id="rating">
                    <h3>Leave A Comment</h3>
                    <Card className="p-3">
                      <div className="mainDivRating m-3">
                        <StarRatingComponent
                          name="rating"
                          starCount={5} // Number of stars
                          value={rating}
                          onStarClick={onStarClick}
                        />
                      </div>
                      {rating > 0 ? (
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <textarea
                              id="your-Messages"
                              rows={8}
                              className="form-control"
                              placeholder="Comment"
                              value={Message}
                              onChange={handleCommentChange}
                            />
                          </div>
                          <button type="submit" className="btn common-btn">
                            Send Review
                            <img src={Shape1} alt="Shape1" />
                            <img src={Shape2} alt="Shape2" />
                          </button>
                        </form>
                      ) : null}
                    </Card>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReltedProduct />
      <Subscribe />
      <Footer />
      <ScrollButton />
    </React.Fragment>
  );
};
export default ProductDetails;
