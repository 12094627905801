import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineLogout } from "react-icons/ai";
import {
  FaHeart,
  FaHistory,
  FaRegAddressBook,
  FaSearchLocation,
  FaUser,
} from "react-icons/fa";

import Shape1 from "../assets/img/shape1.png";
import Shape2 from "../assets/img/shape2.png";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logoutUserAction } from "../state/action";

const SidebarMyprofile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggenUser = useSelector((state) => state.loggedinUserInfo);
  const [loggedOut, setLoggedOut] = useState(false);

  const handleSignout = async () => {
    localStorage.removeItem("token");
    dispatch(logoutUserAction(null));
    setLoggedOut(true);
  };

  useEffect(() => {
    if (loggedOut && loggenUser === null) {
      navigate("/");
      setLoggedOut(false); // Reset loggedOut state after navigation
    }
  }, [loggedOut, loggenUser, navigate]);

  return (
    <div>
      <div className="sorting-menu">
        <Row>
          <Col className="myAcountCol" lg="6">
            <Link className="iconLink" to="/myProfile">
              <div className="filter" data-filter=".armchair">
                <div className="products-thumb myProfileIcon">
                  <img src={Shape1} alt="Shape" />
                  <img src={Shape2} alt="Shape" />
                  <i className="flaticon-headphones">
                    <FaUser className="categoriesIcon" />
                  </i>
                  <span>My Account</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col className="myAcountCol" lg="6">
            <Link className="iconLink" to="/address-book">
              <div className="filter" data-filter=".sofa">
                <div className="products-thumb myProfileIcon">
                  <img src={Shape1} alt="Shape" />
                  <img src={Shape2} alt="Shape" />
                  <i className="flaticon-microphone">
                    <FaRegAddressBook className="categoriesIcon" />
                  </i>
                  <span>Address Books</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col className="myAcountCol" lg="6">
            <Link className="iconLink" to="/my-wishList">
              <div className="filter" data-filter=".computer">
                <div className="products-thumb myProfileIcon">
                  <img src={Shape1} alt="Shape" />
                  <img src={Shape2} alt="Shape" />
                  <i className="flaticon-laptop">
                    <FaHeart className="categoriesIcon" />
                  </i>
                  <span> My WishList</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col className="myAcountCol" lg="6">
            <Link className="iconLink" to="/order-history">
              <div className="filter" data-filter=".desk">
                <div className="products-thumb myProfileIcon">
                  <img src={Shape1} alt="Shape" />
                  <img src={Shape2} alt="Shape" />
                  <i className="flaticon-laptop">
                    <FaHistory className="categoriesIcon" />
                  </i>
                  <span> Order History</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col className="myAcountCol" lg="6">
            <Link className="iconLink" to="/traack-my-order">
              <div className="filter" data-filter=".center">
                <div className="products-thumb myProfileIcon">
                  <img src={Shape1} alt="Shape" />
                  <img src={Shape2} alt="Shape" />
                  <i className="flaticon-laptop">
                    <FaSearchLocation className="categoriesIcon" />
                  </i>
                  <span> Track Your Order</span>
                </div>
              </div>
            </Link>
          </Col>
          {/* <Col className="myAcountCol" lg="6">
                  <Link className="iconLink" to="#">
                    <div className="filter" data-filter=".book">
                      <div className="products-thumb">
                        <img src={Shape1} alt="Shape" />
                        <img src={Shape2} alt="Shape" />
                        <i className="flaticon-laptop">
                          <FaHistory className="categoriesIcon" />
                        </i>
                        <span>Custom Product Order History</span>
                      </div>
                    </div>
                  </Link>
                </Col> */}
          <Col className="myAcountCol" lg="6">
            <Link className="iconLink" onClick={handleSignout}>
              <div className="filter" data-filter=".center-table">
                <div className="products-thumb myProfileIcon">
                  <img src={Shape1} alt="Shape" />
                  <img src={Shape2} alt="Shape" />
                  <i className="flaticon-laptop">
                    <AiOutlineLogout className="categoriesIcon" />
                  </i>
                  <span>Logout </span>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SidebarMyprofile;
