export const storeLoggedinUserInfo = (payload) => ({
    type: 'STORE_LOGGEDIN_USER_INFO',
    payload,
  });

  export const logoutUserAction = (payload) => ({
    type: 'LOGOUT',
    payload,
  });

  export const storeCart = (payload) => ({
    type: 'STORE_CART',
    payload,
  });

  export const storeWishlist = (payload) => ({
    type: 'STORE_WISHLIST',
    payload,
  });

  export const storeNumberOfItemsInCart = (payload) => ({
    type: 'STORE_NUMBER_OF_ITEMS_OF_CART',
    payload,
  });

  export const storeNews = (payload) => ({
    type: 'STORE_NEWS',
    payload,
  });

  export const storeGalleryCategory = (payload) => ({
    type: 'STORE_GALLARY_CATEGORY',
    payload,
  });
  
  export const storeGallery = (payload) => ({
    type: 'STORE_GALLARY',
    payload,
  });


  export const setCategory = (payload) => ({
    type: 'SET_CATEGORY',
    payload,
  });


  export const setNews = (payload) => ({
    type: 'SET_NEWS',
    payload,
  });

  export const storeDownloads = (payload) =>({
    type: 'SET_DOWNLOAD',
    payload,
  })

  export const storeDownloadForm = (payload) =>({
    type: 'SET_DOWNLOAD_FORM',
    payload,
  })

  export const storeSelectedCurrency = (payload) =>({
    type: 'SET_SELECTED_CURRENCY',
    payload,
  })

  export const storeCartTotla = (payload) =>({
    type: 'STORE_CART_TOTAL',
    payload,
  })

  export const relatedProduct = (payload) =>({
    type: 'RELATED_PRODUCT',
    payload,
  })