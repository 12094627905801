import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Puff } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";

import * as Yup from "yup";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import Subscribe from "../Component/Subscribe";
import Shape1 from "./../assets/img/shape1.png";
import Shape2 from "./../assets/img/shape2.png";
import { useDispatch } from "react-redux";
// import {signUp, } from "../services/operations/authAPI";
import axios from "axios";
import {
  getLoggedinUserInfo,
  loginCustomer,
} from "../helpers/backen_helper";
import { storeLoggedinUserInfo } from "../state/action";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid Email format")
      .matches(/@.*(\.com|\.net)$/, "Not proper email format"),
    password: Yup.string()
      .required("Password is a required")
      .min(8, "Password must be at least 8 characters"),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Password must match")
      .required("Confirm Password is Required"),
    name: Yup.string().required("Full name is a required"),

    contact: Yup.string()
      .min(10, "Mobile number must be contain atleast 10 characters")
      .max(12, "Mobile number must be not more than  12 characters")
      .required("Mobile number is required"),
  });

  const onSubmit = async (values) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/customer/signup",
        values
      );

      const loginresponse = await loginCustomer({
        email: values.email,
        password: values.password,
      });

      const authToken = loginresponse.token;
      localStorage.setItem("token", authToken);
      axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
      
      const loggedUser = await getLoggedinUserInfo();
      dispatch(storeLoggedinUserInfo(loggedUser.data));

      navigate(-2);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          {/* <SliderTwo Title="Register" SubTitle="Register" /> */}
          <div className="user-area ptb-100">
            <div className="container">
              <Formik
                validationSchema={schema}
                initialValues={{
                  email: "",
                  password: "",
                  name: "",
                  contact: "",
                  confirmPassword: "",
                }}
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <div className="login">
                    <div className="form">
                      {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                      <div className="user-item">
                        <form noValidate onSubmit={handleSubmit}>
                          <h2>Register</h2>
                          {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                          <div className="form-group">
                            <input
                              type="text"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              placeholder="Full name"
                              className="form-control"
                              id="name"
                            />
                            {/* If validation is not passed show errors */}
                            <p className="error">
                              {errors.name && touched.name && errors.name}
                            </p>
                          </div>
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              placeholder="Enter email id / username"
                              className="form-control"
                              id="email"
                            />
                            {/* If validation is not passed show errors */}
                            <p className="error">
                              {errors.email && touched.email && errors.email}
                            </p>
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              name="contact"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.contact}
                              placeholder="Contact number"
                              className="form-control"
                              id="contact"
                            />
                            {/* If validation is not passed show errors */}
                            <p className="error">
                              {errors.contact &&
                                touched.contact &&
                                errors.contact}
                            </p>
                          </div>
                          {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                          <div className="form-group">
                            <input
                              type="password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              placeholder="Enter password"
                              className="form-control"
                            />
                            {/* If validation is not passed show errors */}
                            <p className="error">
                              {errors.password &&
                                touched.password &&
                                errors.password}
                            </p>
                          </div>
                          <div className="form-group">
                            <input
                              type="password"
                              name="confirmPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              placeholder="Confirm password"
                              className="form-control"
                            />
                            {/* If validation is not passed show errors */}
                            <p className="error">
                              {errors.confirmPassword &&
                                touched.confirmPassword &&
                                errors.confirmPassword}
                            </p>
                          </div>
                          {/* Click on submit button to submit the form */}
                          {/* <div className="form-group">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue
                                  id="flexCheckDefault4"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault4"
                                >
                                  I accept all{" "}
                                  <a href="/termAndConditions">
                                    Terms &amp; Conditions
                                  </a>
                                </label>
                              </div>
                            </div> */}
                          <button type="submit" className="btn common-btn">
                            Sign up
                            <img src={Shape1} alt="Shape" />
                            <img src={Shape2} alt="Shape" />
                          </button>
                          <h5>
                            Already Have An Account?{" "}
                            <Link to="/login">Login</Link>
                          </h5>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </div>
          <Subscribe />
          <Footer />
        </div>
      )}
    </React.Fragment>
  );
};

export default Register;
