import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";

// import Blog1 from "./../assets/img/offer-1.jpg";
// import Blog2 from "./../assets/img/offer-2.jpg";
// import Blog3 from "./../assets/img/offer-3.jpg";

const Blog = () => {
  const [newsData, setNewsData] = useState([]);
  

  const fetchData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL+"/eventMaster/get-event-master"
      );
      setNewsData(response.data);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    fetchData(); // Call the fetchData function when the component mounts
  }, []);
  return (
    <React.Fragment>

      <section class="blog-area two pt-100 pb-70">
        <div class="container">
          <div class="section-title">

            <h2>Read our latest news & events</h2>
          </div>
          <div className="row justify-content-center">
            {newsData&&newsData?.map((n,index) => (
              <div className="col-sm-6 col-lg-4" key={index}>
                <div className="blog-item">
                  <div className="top">
                    <Link to={`/newsDetails/${n._id}`}>
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/${n.EventImage}`}
                        alt="Blog"
                      />
                    </Link>
                    <span>26 Nov</span>
                  </div>
                  <div className="bottom">
                    <h3>
                      <Link to={`/newsDetails/${n._id}`}>{n.EventName}</Link>
                    </h3>
                    <p>
                      {/* Text Will Be Coming Soon...Text Will Be Coming Soon...Text
                      Will Be Coming Soon... */}
                      {n.ShortDesc}
                    </p>
                    <Link className="blog-btn" to={`/newsDetails/${n._id}`}>
                      Read More
                      <span>
                        <i className="bx bx-plus">
                          <AiOutlinePlus />
                        </i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
            {/* <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <div className="top">
                  <Link to="/newsDetails">
                    <img src={Blog2} alt="Blog" />
                  </Link>
                  <span>27 Nov</span>
                </div>
                <div className="bottom">
                  <h3>
                    <Link to="/newsDetails">Title 2....</Link>
                  </h3>
                  <p>
                    Text Will Be Coming Soon...Text Will Be Coming Soon...Text
                    Will Be Coming Soon...
                  </p>
                  <Link className="blog-btn" to="/newsDetails">
                    Read More
                    <span>
                      <i className="bx bx-plus">
                        <AiOutlinePlus />
                      </i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <div className="top">
                  <Link to="/newsDetails">
                    <img src={Blog3} alt="Blog" />
                  </Link>
                  <span>28 Nov</span>
                </div>
                <div className="bottom">
                  <h3>
                    <Link to="/newsDetails">Title 3....</Link>
                  </h3>
                  <p>
                    Text Will Be Coming Soon...Text Will Be Coming Soon...Text
                    Will Be Coming Soon...
                  </p>
                  <Link className="blog-btn" to="/newsDetails">
                    Read More
                    <span>
                      <i className="bx bx-plus">
                        <AiOutlinePlus />
                      </i>
                    </span>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default Blog;
