import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Shape1 from "./../assets/img/shape1.png";
import Shape2 from "./../assets/img/shape2.png";
import Shap6 from "./../assets/img/shape6.png";
import Shap7 from "./../assets/img/shape7.png";

const BestProduct = () => {
  const [bannerData, setBannerData] = useState({});
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/bannerMaster/get-best-product`
      );
      setBannerData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="buy-area two ptb-100">
        <div className="buy-shape">
          <img src={Shap6} alt="Shape" />
          <img src={Shap7} alt="Shape" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="buy-content">
                <h2>{bannerData?.BannerTitle}</h2>
                <p>{bannerData?.BannerSubTitle}</p>

                <Link
                  className="common-btn two"
                  to={bannerData[0]?.ButtonUrl || "#"}
                  style={{ marginTop: "10px" }}
                >
                  {bannerData?.ButtonText || "Continue Shoping"}
                  <img src={Shape1} alt="Shape" />
                  <img src={Shape2} alt="Shape" />
                </Link>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="buy-img">
                <img
                  src={
                    process.env.REACT_APP_BASE_URL +
                    "/" +
                    bannerData[0]?.BannerImage
                  }
                  alt="Buy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default BestProduct;
