import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import BottomHeader from "../Component/BottomHeader";
import Delivery from "../Component/Delivery";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import TrustableBrand from "../Component/TrustableBrand";
import AboutImg from "./../assets/img/Aboutus3.jpg";
import AboutImgTwo from "./../assets/img/about6.jpg";

import AboutImgOne from "./../assets/img/images.jpg";

const Innovation = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="Innovation" SubTitle="Innovation" />
          <BottomHeader />
          <section className="about-area pt-100 pb-70">
            <Container>
              <Card
                style={{
                  padding: "24px",
                  margin: "3px",
                }}
              >
                <Row className=" ">
                  <div className="top">
                    <h2>INNOVATION:</h2>
                    <p>
                      The meaning for us is to improve the way something is
                      done. Make it Better, Stronger, Lighter.
                    </p>
                    <p>
                      We have been asked many times over the last ten years,
                      what’s our philosophy. We think about it almost every day.
                    </p>
                    <p>
                      Philosophy, well that’s a big word for us. We still don’t
                      have a clear answer. If we say what it might be, it’s like
                      a tattoo, it’s there for the rest of our life.
                    </p>
                    <p>David Simmons says</p>
                    <p>
                      “I will start with a little insight on our train of
                      thought. Sense I can remember I have been Intrigue by the
                      simplest things. When I went to school for the first time,
                      I had trouble paying attention to the teacher when she was
                      speaking. I could not concentrate on what she was saying
                      because of the mat on the floor. It was red and white with
                      a loose thread. I was thinking how did it get that way?
                      How did the patterns get there? How did they weave the
                      threads together? All though school I was distracted by
                      the, how and why, of my surroundings. It made life
                      difficult, but I worked though it and graduated.
                    </p>
                    <p>
                      My thought process is mostly mechanical. When involved in
                      an object that I’m passionate about (cars, boats, guitars,
                      bikes, and skates) I can’t stop thinking about it until
                      it’s perfect in my eyes. Perfect to me is strongest,
                      lightest, simplest, best performing, while looking good.
                      When I design, I try to break it down into the simplest
                      concept.
                    </p>
                    <p>
                      As you look at my speed skates and cycling shoes you may
                      think, why, how, and that is exactly what I want.
                    </p>
                    <p>
                      To create a complex object with a simple appearance is
                      difficult, and creating a simple method to make that
                      object is more challenging. The focus of my designs (My
                      philosophy) are about light weight, efficiency,
                      simplicity, and the least amount of moving parts.
                      Combining these principles is an art that I enjoy very
                      much.
                    </p>
                  </div>
                  <Col lg="6">
                    <div className="about-content">
                      <div className="top">
                        {/* <h2>INNOVATION:</h2>
                  <p>
                    The meaning for us is to improve the way something is done.
                    Make it Better, Stronger, Lighter.
                  </p>
                  <p>
                    We have been asked many times over the last ten years,
                    what’s our philosophy. We think about it almost every day.
                  </p>
                  <p>
                    Philosophy, well that’s a big word for us. We still don’t
                    have a clear answer. If we say what it might be, it’s like a
                    tattoo, it’s there for the rest of our life.
                  </p>
                  <p>David Simmons says</p>
                  <p>
                    “I will start with a little insight on our train of thought.
                    Sense I can remember I have been Intrigue by the simplest
                    things. When I went to school for the first time, I had
                    trouble paying attention to the teacher when she was
                    speaking. I could not concentrate on what she was saying
                    because of the mat on the floor. It was red and white with a
                    loose thread. I was thinking how did it get that way? How
                    did the patterns get there? How did they weave the threads
                    together? All though school I was distracted by the, how and
                    why, of my surroundings. It made life difficult, but I
                    worked though it and graduated.
                  </p> */}

                        <p>
                          I take a lot of pride in my study of making stock
                          boots.
                        </p>
                        <p>
                          I am 100% self-taught I don’t like to see how other
                          boots are made.
                        </p>
                        <p>It is part of the game I play with myself..</p>
                        <p>
                          Some people like a crossword puzzle, or watching a
                          football game.
                        </p>
                        <p>
                          In one day I have at least 5 projects going on up
                          there. The longest thought now has been going on for
                          at least 10 years.
                        </p>
                        <p>
                          It’s never been about the money, I’m forced to be a
                          business man to survive.
                        </p>
                        <p>
                          So when it comes to me producing or creating
                          something, No matter how much time or expense, I will
                          work on it until its perfect in my eyes and my
                          customers are satisfied.
                        </p>
                        <p>
                          I like to see people enjoy themselves and achieve
                          their goals, and I can help them with the art/work I
                          do!
                        </p>
                        <p>
                          When I started Skating (25 years ago), the first thing
                          I noticed is that my toes went numb (sleepy toe) after
                          just a few miles. Like everybody else, I just
                          dealtwith it for years. As I skate, I’m always
                          thinking about how something is designed, especially
                          if it doesn’t feel right. I have my #1 rule; fix it or
                          throw it away. So I threw out my boot that I bought
                          from the local skate store and started making my own.
                          Even though I’ve spent a lifetime working on this, I
                          have never really cared about how much time I have
                          spent to designing shoes. I have a passion for the way
                          something is supposed to feel and perform. Simmons
                          Rana Racing doesn’t follow tradition. We do not use
                          the old designs in which the toes are up so high. Ours
                          are much lower and use a more neutral position of the
                          foot. Why? When the shoes were made 50 years ago they
                          were made of leather and they flexed.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="about-img">
                      <img src={AboutImg} alt="About" />
                      <img src={AboutImgOne} alt="About" />
                      <img src={AboutImgTwo} alt="About" />
                    </div>
                  </Col>
                  <p>
                    The builders compensated for that by making the toes up, so
                    when you pushed down, the toes would flattenout. This then
                    became the traditional way of making a boot. Nobody ever
                    questioned it.
                  </p>
                  <p>
                    Now that carbon is used, there is no more flexing and
                    therefore no need to make a shoe the old way. Your foot is
                    already in the position to make the most power. No
                    compensation, no loss.”
                  </p>
                  <p>
                    We do not mindlessly copy other boots and follow the
                    tradition. We create our own. We apply the same simple
                    thinking in Building our Simmons Rana Products and that is
                    why we remain "SIMPLY THE BEST"
                  </p>
                </Row>
              </Card>
            </Container>
          </section>
          <TrustableBrand />
          <Delivery />
          {/* <ClientTestimal /> */}
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default Innovation;
