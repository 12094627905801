import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Puff } from "react-loader-spinner";

import Accordion from "react-bootstrap/Accordion";
import BottomHeader from "../Component/BottomHeader";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";

const FAQ = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="FAQ" SubTitle="FAQ" />
          <BottomHeader />

          <div className="common-faq-area ptb-100">
            <Container>
              <div className="section-title">
                <h2>What Can We Do For You ?</h2>
              </div>
              <div className="faq-item">
                <ul className="accordion">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="faq-head">
                        Can we re-skin/ restore our boots?
                      </Accordion.Header>
                      <Accordion.Body>
                        Yes you can re-skin your boots. The carbon fiber shells
                        last a long time. If you would like to restore the
                        outside of your boot to new again, (laces, leather,
                        buckle, etc.) or change colors the price is $300.00. We
                        do not re-skin or restore the Rush Boots as it will be
                        better to buy a new pair when you consider shipping and
                        cost.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        How can we give our custom boot castings?
                      </Accordion.Header>
                      <Accordion.Body>
                        You can get your feet casted either at Cape Coral,
                        Florida USA by David Simmons or Ahmedabad, India by
                        Rahul Rana. We are also present at World Championships,
                        Indoor and Outdoor National USA, Indian National
                        Championships. You can also send us your casting
                        yourself. Here is a link on how to cast our feet
                        yourself
                        https://www.youtube.com/watch?time_continue=7&amp;v=ME3xvqArasA
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        What is the Warranty on Simmons Rana Boots and Speed
                        Frames?
                      </Accordion.Header>
                      <Accordion.Body>
                        All our boots are under warranty for a period of 1 year
                        from the date of Purchase. Any manufacturing defects
                        will be either repaired or exchanged within the time
                        frame. Any damages due to the wear and tear from the
                        usage is not covered under waranty. We have not had a
                        single issue with our frames which are built to last for
                        a long long time.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        What if once we order a boot and it is of smaller or
                        bigger size? Can it be exchanged?
                      </Accordion.Header>
                      <Accordion.Body>
                        We send orders as per size mentioned by the customer but
                        in case there is an issue related to size you will have
                        to send us the pair that needs to be exchanged unused
                        and ship it to our address. Upon receiving the product
                        we will exchange the size as per your requirement. You
                        will have to pay the shipping cost as per actual for the
                        new pair we send you the boot. We will inform you the
                        amount to be deposited in our account upon your request
                        via email. You can send your request at
                        order@simmonsrana.com
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        Do we have to pay any additional costs?
                      </Accordion.Header>
                      <Accordion.Body>
                        All shipments are paid for shipping charges when it
                        leaves our factory but every country has different
                        custom clearance process and the duty applied by their
                        individual country has to be paid by the customer.
                        Different countries has different custom tariff. Some
                        countries are free to import sports articles and those
                        countries will not have to pay the duty.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        What is the estimated delivery time once an order is
                        placed?
                      </Accordion.Header>
                      <Accordion.Body>
                        We are always well stocked and most of the orders are
                        shipped immediately upon receipt of payment in our
                        account. We use FedEx or DHL for all our international
                        shipments and DTDC for local India. The maximum amount
                        of time for international shipment is between 5 to 9
                        working days. Provided there is no Customs issue in your
                        destination country
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </ul>
              </div>
            </Container>
          </div>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default FAQ;
