import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import BottomHeader from "../Component/BottomHeader";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import SidebarMyprofile from "../Component/sidebarMyprofile";
import Shape1 from "../assets/img/shape1.png";
import Shape2 from "../assets/img/shape2.png";
import "../css/myProfile.css";
import { logoutUserAction } from "../state/action";
const MyProfile = () => {
  const loggedinUserInfo = useSelector((state) => state.loggedinUserInfo);
  const navigate = useNavigate();
  const notify = (message, icon) =>
    toast(message, {
      duration: 2000,
      position: "top-right",
      style: {},
      className: "",
      icon: icon,
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  const schema = Yup.object().shape({
    FName: Yup.string().required("Name is  required "),
    ContactNo: Yup.string().required("Contact number is  required "),
    Mr: Yup.boolean().required("This field is required "),
    Mrs: Yup.boolean().required("This field is required "),
  });
  const changePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is a required field")
      .min(8, "Password must be at least 8 characters"),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Password must match")
      .required("Confirm Password is a Required"),

    // oldPassword: Yup.string()
    //   .required("Old password is a required ")
    //   .min(8, " Old password must be at least 8 characters"),
  });
  const [show, setShow] = useState(false);

  const handleSubmit = async (values, resetForm) => {
    try {
      // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/customer/changePassword`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Handle the API response here (e.g., show success message)
      if (response) {
        resetForm();
        notify("Password Upadted Successfully..", "✅");
        handleSignout();
        navigate("/");
      }
    } catch (error) {
      // Handle API call errors (e.g., show error message)
      console.error("API Error:", error);
    }
  };
  const handleSubmit1 = async (values1, values2) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/customer/savechange`,
        {
          Fname: values1,
          ContactNo: values2,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Handle the API response here (e.g., show success message)
      if (response) {
        // resetForm();
        notify("Changes Upadted Successfully..", "✅");
        handleSignout();
        navigate("/");
      }
    } catch (error) {
      // Handle API call errors (e.g., show error message)
      console.error("API Error:", error);
    }
  };
  const dispatch = useDispatch();
  const loggenUser = useSelector((state) => state.loggedinUserInfo);
  const [loggedOut, setLoggedOut] = useState(false);

  const handleSignout = async () => {
    localStorage.removeItem("token");
    dispatch(logoutUserAction(null));
    setLoggedOut(true);
  };

  useEffect(() => {
    if (loggedOut && loggenUser === null) {
      navigate("/");
      setLoggedOut(false); // Reset loggedOut state after navigation
    }
  }, [loggedOut, loggenUser, navigate]);

  return (
    <React.Fragment>
      <Toaster></Toaster>
      <>
        <HaderTwo />
        <SliderTwo Title="My Account" SubTitle="My Account" />
        <BottomHeader />
        <Container>
          <Row className="mainRowMyAcoount">
            <Col lg="4">
              <SidebarMyprofile />
            </Col>
            <Col lg="8">
              <Card className="formCard">
                <h6>
                  Hello, {loggedinUserInfo ? loggedinUserInfo.FName : null} -
                  Please Update Your Account Information Below.
                </h6>
                <Row>
                  <Col lg="6">
                    <Formik
                      validationSchema={schema}
                      initialValues={
                        loggedinUserInfo
                          ? {
                              Mr: loggedinUserInfo.Mr || "",
                              Mrs: loggedinUserInfo.Mrs || "",
                              FName: loggedinUserInfo.FName || "",
                              ContactNo: loggedinUserInfo.ContactNo || "",
                            }
                          : {
                              Mr: "",
                              Mrs: "",
                              FName: "",
                              ContactNo: "",
                            }
                      }
                      onSubmit={(values, { resetForm }) => {
                        // alert(JSON.stringify(values));
                        // handleSubmit1(values);
                        resetForm();
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      }) => (
                        <div className="login" style={{ marginTop: "23px" }}>
                          <div className="form">
                            {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                            <div className="user-item">
                              <form noValidate onSubmit={handleSubmit}>
                                {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}

                                <div className="radioDiv"></div>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="FName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.FName}
                                    placeholder="First Name"
                                    className="form-control"
                                    id="FName"
                                  />

                                  <p className="error">
                                    {errors.FName &&
                                      touched.FName &&
                                      errors.FName}
                                  </p>
                                </div>
                                {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="ContactNo"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.ContactNo}
                                    placeholder="Contact No"
                                    className="form-control"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.ContactNo &&
                                      touched.ContactNo &&
                                      errors.ContactNo}
                                  </p>
                                </div>
                                {/* Click on submit button to submit the form */}
                                <button
                                  type="submit"
                                  className="btn common-btn"
                                  onClick={() =>
                                    handleSubmit1(
                                      values.FName,
                                      values.ContactNo
                                    )
                                  }
                                >
                                  Save Change
                                  <img src={Shape1} alt="Shape" />
                                  <img src={Shape2} alt="Shape" />
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      )}
                    </Formik>
                  </Col>
                  <Col lg="6">
                    <Formik
                      validationSchema={schema}
                      initialValues={
                        loggedinUserInfo
                          ? {
                              email: loggedinUserInfo.Email || "",
                              password: loggedinUserInfo.Password || "",
                            }
                          : {
                              email: "",
                              password: "",
                            }
                      }
                      onSubmit={(values) => {
                        // Alert the input values of the form that we filled
                        alert(JSON.stringify(values));
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      }) => (
                        <div className="login">
                          <div className="form">
                            {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                            <div className="emailDiv">
                              <form noValidate onSubmit={handleSubmit}>
                                {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                                <div className="form-group">
                                  <input
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    placeholder="Enter email id / username"
                                    className="form-control"
                                    id="email"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.email &&
                                      touched.email &&
                                      errors.email}
                                  </p>
                                </div>
                                {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                                <div className="form-group">
                                  <input
                                    type="password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    placeholder="Enter password"
                                    className="form-control"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.password &&
                                      touched.password &&
                                      errors.password}
                                  </p>
                                </div>
                                {/* Click on submit button to submit the form */}
                              </form>
                            </div>
                          </div>
                        </div>
                      )}
                    </Formik>
                    <button
                      onClick={() => setShow(!show)}
                      className="btn common-btn"
                    >
                      UpdatePassword
                      <img src={Shape1} alt="Shape" />
                      <img src={Shape2} alt="Shape" />
                    </button>
                  </Col>
                </Row>
                {show ? (
                  <Formik
                    validationSchema={changePasswordSchema}
                    initialValues={{
                      password: "",
                      oldPassword: "",
                      confirmPassword: "",
                    }}
                    onSubmit={(values, { resetForm }) => {
                      handleSubmit(values, resetForm);

                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      resetForm,
                    }) => (
                      <div className="changPassowrdDiv">
                        <div className="form">
                          {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                          <div className="user-item">
                            <form noValidate onSubmit={handleSubmit}>
                              <h6>Change Password</h6>
                              {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                              <Row>
                                <Col lg="6">
                                  <div className="form-group">
                                    <input
                                      type="password"
                                      name="oldPassword"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.oldPassword}
                                      placeholder="Enter Older Password"
                                      className="form-control"
                                      id="oldPassword"
                                    />
                                    {/* If validation is not passed show errors */}
                                    <p className="error">
                                      {errors.oldPassword &&
                                        touched.oldPassword &&
                                        errors.oldPassword}
                                    </p>
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="form-group">
                                    <input
                                      type="password"
                                      name="password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                                      placeholder="Enter new password"
                                      className="form-control"
                                    />
                                    {/* If validation is not passed show errors */}
                                    <p className="error">
                                      {errors.password &&
                                        touched.password &&
                                        errors.password}
                                    </p>
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="form-group">
                                    <input
                                      type="password"
                                      name="confirmPassword"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.confirmPassword}
                                      placeholder="Confirm password"
                                      className="form-control"
                                    />
                                    {/* If validation is not passed show errors */}
                                    <p className="error">
                                      {errors.confirmPassword &&
                                        touched.confirmPassword &&
                                        errors.confirmPassword}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                              <button type="submit" className="btn common-btn">
                                Submit Now
                                <img src={Shape1} alt="Shape" />
                                <img src={Shape2} alt="Shape" />
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                  </Formik>
                ) : null}
              </Card>
            </Col>
          </Row>
        </Container>
        <Subscribe />
        <Footer />
        <ScrollButton />
      </>
    </React.Fragment>
  );
};

export default MyProfile;
