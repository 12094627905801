import axios from "axios";
import React, { useState } from "react";
import { Col, Container, Row, Toast } from "react-bootstrap";
import { notify } from "../helpers/notify";

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (email) => {
    // Regular expression for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setIsValidEmail(false);
      return; // Prevent API call if email is invalid
    }

    axios
      .post(
        process.env.REACT_APP_BASE_URL + "/newsletterMaster/add-newsletter",
        { email }
      )
      .then((response) => {
        setEmail("");
        setIsValidEmail(true);
        notify("Subscribed Successfully", "😀");
      })
      .catch((error) => {
        console.error("Error subscribing:", error);
      });
  };

  return (
    <React.Fragment>
      <Container>
        <div className="modal-body m-3">
          <Toast></Toast>
          <Row className="subscribeRow align-items-center">
            <Col lg="6" md="6">
              <div className="subscribe-content">
                <h2 style={{ color: "#fff" }}>Subscribe Our Newsletter</h2>
                <p style={{ color: "#fff" }}>
                  Join 226.000+ subscribers and get a new discount coupon on
                  every Month.
                </p>
              </div>
            </Col>
            <Col lg="6" md="6">
              <form
                className="newsletter"
                data-toggle="validator"
                onSubmit={handleSubmit}
              >
                <input
                  type="email"
                  className={`form-control ${isValidEmail ? "" : "is-invalid"}`}
                  placeholder="Enter Your Email"
                  name="email"
                  required
                  autoComplete="off"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setIsValidEmail(true); // Reset validation on input change
                  }}
                />
                {!isValidEmail && (
                  <div className="invalid-feedback">
                    Please enter a valid email
                  </div>
                )}
                <button
                  className="btn common-btn"
                  style={{ display: "flex", justifyContent: "center" }}
                  type="submit"
                >
                  Subscribe
                </button>
              </form>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Subscribe;
