import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Table from "react-bootstrap/Table";
import { Puff } from "react-loader-spinner";
import BottomHeader from "../Component/BottomHeader";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import SidebarMyprofile from "../Component/sidebarMyprofile";
import "../css/myProfile.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { getCustomerOrders } from "../helpers/backen_helper";
import { useSelector } from "react-redux";
import { formatDateToDDMMYYYY } from "../helpers/DateFormat";
import Pagination from "react-bootstrap/Pagination";
import { storeCart } from "../state/action";
let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item
      style={{ color: "black" }}
      key={number}
      active={number === active}
    >
      {number}
    </Pagination.Item>
  );
}

const OrderHistory = () => {
  const [currency1, setCurrency1] = useState(null);
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.selectedCurrency);

  // Assuming you want to set currency1 when it changes
  useEffect(() => {
    if (currency && currency.symbol) {
      setCurrency1(currency.symbol);
    }
  }, [currency]);

  const loggedinUserInfo = useSelector((state) => state.loggedinUserInfo);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(orders.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  let active = 2;
  let items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => {
          setCurrentPage(number);
        }}
      >
        {number}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);

      getCustomerOrders().then((response) => {
        setOrders(response.data);
      });
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="My Order-History" SubTitle="Order History" />
          <BottomHeader />
          <Container>
            <Row className="mainRowMyAcoount">
              <Col lg="4">
                <SidebarMyprofile />
              </Col>
              <Col lg="8">
                <div className="account-card">
                  <div className="account-title">
                    <p>
                      Hello,
                      <strong>
                        {loggedinUserInfo ? loggedinUserInfo.FName : null}
                      </strong>
                      -Your Order Information Below
                    </p>
                  </div>
                  <div className="table-responsive table-scroll">
                    <table className="table-list">
                      <thead>
                        <tr>
                          <th>Order</th>
                          <th>Order Date</th>
                          <th>Order Id</th>
                          <th>Status</th>
                          <th>Delivered Date</th>
                          <th>Total</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.length > 0
                          ? orders
                              .slice(startIndex, endIndex)
                              .map((item, index) => (
                                <tr key={index}>
                                  <td>{orders.indexOf(item) + 1}</td>
                                  <td>
                                    {formatDateToDDMMYYYY(item.OrderDate)}
                                  </td>
                                  <td>{item.OrderNo}</td>
                                  <td>{item.OrderStatus}</td>
                                  <td>{item.DeliveryDate}</td>
                                  <td>{currency1}{item.SubTotal}</td>
                                  <td>
                                    <Link
                                      to={`/orderDetail/${item._id}`}
                                      className="btn btn-warning"
                                    >
                                      view
                                    </Link>
                                  </td>
                                </tr>
                              ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                  <div></div>
                  {orders.length > 5 && (
                    <div className="mt-3">
                      <Pagination>{items}</Pagination>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default OrderHistory;
