
import { Link } from 'react-router-dom';
import './ProductCardComponent.css';
import { useEffect } from 'react';
const ProductCardComponent = ({ image, name, id }) => {
	const encodedProductGroupName = decodeURIComponent(name);

	return (
		<Link to={`/product-list/${id}?Name=${encodedProductGroupName}`} class="product-group-card">
			<div >
				<img src={image} alt="image url" />
				<div class="overlay">
					<h6 class="link-a">

						{name}
					</h6>
				</div>
			</div>
		</Link>
	);
};

export default ProductCardComponent;
