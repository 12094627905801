import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { BiLogoPinterestAlt, BiPhoneCall } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Puff } from "react-loader-spinner";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import BottomHeader from "../Component/BottomHeader";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import Shape1 from "../assets/img/shape1.png";
import Shape2 from "../assets/img/shape2.png";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is  required ")
      .email("Invalid email format"),
    phone_number: Yup.string()
      .required("Phone number is  required ")
      .min(10, "Phone number must be at least 10 characters")
      .max(12, "Phone number must be at least 10 characters"),
    name: Yup.string().required("Name is required "),
    msg_subject: Yup.string().required("Subject is required "),
    message: Yup.string().required("Message is required "),
  });
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="Contact us" SubTitle="Contact us" />
          <BottomHeader />

          <div className="contact-area pt-100 pb-70">
            <div className="container">
              <div className="section-title">
                <h2>Get In Touch</h2>
              </div>
              <Row>
                <Col sm="6" lg="8">
                  <Formik
                    validationSchema={schema}
                    initialValues={{
                      email: "",
                      password: "",
                      name: "",
                      phone_number: "",
                      msg_subject: "",
                      message: "",
                    }}
                    onSubmit={(values) => {
                      // Alert the input values of the form that we filled
                      alert(JSON.stringify(values));
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <form id="contactForm" noValidate onSubmit={handleSubmit}>
                        <Row>
                          <Col lg="6">
                            <div className="form-group">
                              <input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                type="text"
                                name="name"
                                id="name"
                                className="form-control"
                                placeholder="Name"
                                required
                                data-error="Please enter your name"
                              />
                            </div>
                            <p className="error">
                              {errors.name && touched.name && errors.name}
                            </p>
                          </Col>
                          <Col lg="6">
                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                id="email"
                                className="form-control"
                                placeholder="Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                required
                              />
                              <p className="error">
                                {errors.email && touched.email && errors.email}
                              </p>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="phone_number"
                                id="phone_number"
                                placeholder="Phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone_number}
                                required
                                className="form-control"
                              />
                            </div>
                            <p className="error">
                              {errors.phone_number &&
                                touched.phone_number &&
                                errors.phone_number}
                            </p>
                          </Col>
                          <Col lg="6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="msg_subject"
                                id="msg_subject"
                                className="form-control"
                                placeholder="Subject"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.msg_subject}
                                required
                                data-error="Please enter your subject"
                              />
                            </div>
                            <p className="error">
                              {errors.msg_subject &&
                                touched.msg_subject &&
                                errors.msg_subject}
                            </p>
                          </Col>
                          <Col lg="12">
                            <div className="form-group">
                              <textarea
                                name="message"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.message}
                                id="message"
                                cols={30}
                                rows={8}
                                placeholder="Write message"
                                required
                                data-error="Write your message"
                                defaultValue={""}
                              />
                            </div>
                            <p className="error">
                              {errors.message &&
                                touched.message &&
                                errors.message}
                            </p>
                          </Col>
                         
                          <Col lg="12">
                            <div className="text-center">
                              <button type="submit" className="btn common-btn">
                                Send Message
                                <img src={Shape1} alt="Shape" />
                                <img src={Shape2} alt="Shape" />
                              </button>
                              <div
                                id="msgSubmit"
                                className="h3 text-center hidden"
                              />
                              <div className="clearfix" />
                            </div>
                          </Col>
                        </Row>
                      </form>
                    )}
                  </Formik>
                </Col>
                <Col sm="6" lg="4">
                  <div className="contact-info">
                    <h3>Contact Information:</h3>
                    <ul className="info">
                      <li>
                        <i className="flaticon-pin">
                          <CiLocationOn />
                        </i>
                        <Link to="#">
                          Address : Simmons Rana Racing, 105 Gokuldham Arcade,
                          Sarkhej- Sanand Highway, Sanathal, Ahmedabad- 382210,
                          Gujarat, India.
                        </Link>
                      </li>
                      <li>
                        <i className="flaticon-phone-call">
                          <BiPhoneCall />
                        </i>
                        <Link to="tel:(+91) 98253 22522">
                          (+91) 98253 22522
                        </Link>
                      </li>
                      <li>
                        <i className="flaticon-email">
                          <AiOutlineMail />
                        </i>
                        <Link to="mailto:sales@simmonsrana.com" target="_blank">
                          <span
                            className="__cf_email__"
                            data-cfemail="f199949d9d9eb194929e81df929e9c"
                          >
                            sales@simmonsrana.com
                          </span>
                        </Link>
                      </li>
                    </ul>
                    <div className="social-links">
                      <ul>
                        <li>
                          <Link
                            to="https://www.facebook.com/SimmonsRana"
                            target="_blank"
                            className="iconLinkFacbook"
                          >
                            <i className="bx bxl-facebook">
                              {" "}
                              <FaFacebookF />
                            </i>
                          </Link>
                        </li>
                        <li>
                          <Link className="shareIconTwiter" to="#">
                            <i className="bx bxl-twitter">
                              <FaTwitter />
                            </i>
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="https://in.pinterest.com/simmonsranaracing/"
                            target="_blank"
                            className="shareIconPinterest"
                          >
                            <i className="bx bxl-linkedin">
                              <BiLogoPinterestAlt />{" "}
                            </i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://www.instagram.com/simmonsranaracing/"
                            target="_blank"
                            className="sharIconInstagram"
                          >
                            <i className="bx bxl-linkedin">
                              <FaInstagram />
                            </i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://www.youtube.com/@simmonsranaracing5226"
                            target="_blank"
                            className="shareIconYoutube"
                          >
                            <i className="bx bxl-youtube">
                              <FaYoutube />{" "}
                            </i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="map-area pb-100">
            <div className="container">
              <iframe
                id="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.985116405572!2d72.45432301442878!3d22.987574784970796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9a443eb723cd%3A0x4e59384c028434b4!2sSimmons+Rana+Racing!5e0!3m2!1sen!2sin!4v1483600953682"
                allowfullscreen
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
          </div>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default ContactUs;
