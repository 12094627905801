import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import BottomHeader from "../Component/BottomHeader";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import FootImg from "../assets/img/footSizeImg.jpg";
import SizeChartImg from "./../assets/img/SizeCHartImg.jpg";

const SizeChart = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="Size Chart" SubTitle="Size Chart" />
          <BottomHeader />
          <Container className="sizeChartContainer">
            <h2>Select Your Foot Size:</h2>
            <p>
              When doing a foot tracing, hold a normal size pen or pencil
              straight up and down and trace around both feet.
            </p>
            <Row>
              <Col lg="6" md="6" sm="12">
                <div style={{ padding: "20px 0px" }}>
                  <img src={SizeChartImg} />
                </div>
              </Col>
              <Col lg="6" md="6" sm="12">
                <div className="footSIzeDiv">
                  <img src={FootImg} />
                </div>
                <div>
                  <iframe
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen=""
                    frameborder="0"
                    height="350"
                    src="https://www.youtube.com/embed/rqo7wt8QEuE"
                    width="100%"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default SizeChart;
