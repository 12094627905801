import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { BiPhoneCall, BiSolidUser } from "react-icons/bi";
import { CiLocationOn, CiMail } from "react-icons/ci";
import { FaGlobeAfrica } from "react-icons/fa";
import { Puff } from "react-loader-spinner";
import BottomHeader from "../Component/BottomHeader";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";

const GlobalDealer = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="Global Dealers" SubTitle="Global Dealers" />
          <BottomHeader />

          <Container>
            <Card style={{
  padding: '24px',
  margin: '3px',
}}>
            <Row className="sizeChartContainer ">
              <h3 className="globalTitle">India</h3>
              <Col lg="6">
                <h6>Simmons Rana Racing</h6>

                <p>
                  <CiLocationOn />
                  Address : 105 Gokuldham Arcade, Sarkhej- Sanand Highway,
                  Sanathal, Ahmedabad- 382210, Gujarat, India
                </p>
                <p>
                  {" "}
                  <BiSolidUser />
                  Contact Person : Rahul Rana
                </p>
                <p>
                  <a href="#" style={{ color: "#545454" }}>
                    {" "}
                    <CiMail />
                    sales@simmonsrana.com
                  </a>
                </p>
                <p>
                  {" "}
                  <BiPhoneCall />
                  +91-9825322522
                </p>
              </Col>
              <Col lg="6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.985116405572!2d72.45432301442878!3d22.987574784970796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9a443eb723cd%3A0x4e59384c028434b4!2sSimmons+Rana+Racing!5e0!3m2!1sen!2sin!4v1475229676935"
                  width="100%"
                  height="225"
                  frameborder="0"
                  allowfullscreen=""
                ></iframe>
              </Col>
            </Row>
            <Row className="sizeChartContainer ">
              <Col lg="6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.48229818854!2d80.22779521425333!3d13.06859119079232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266887abcfebf%3A0x516474a016253f0e!2sSathyam+Homes%2C+Pillaiyar+Koil+St%2C+Apparao+Garden%2C+Aminjikarai%2C+Chennai%2C+Tamil+Nadu+600029!5e0!3m2!1sen!2sin!4v1475229991950"
                  width="100%"
                  height="225"
                  frameborder="0"
                  allowfullscreen=""
                ></iframe>
              </Col>
              <Col lg="6">
                <h6>Sathya Speed Skating Academy ( SSSA Skating )</h6>

                <p>
                  <CiLocationOn />
                  Address : No:63/, Satyam Homes, Gandhi Street, Paddi Kuppam
                  Main Road Chennai -600 101. Tamil Nadu
                </p>
                <p>
                  {" "}
                  <BiSolidUser />
                  Contact Person : Sathya Moorthy
                </p>

                <p>
                  {" "}
                  <BiPhoneCall />
                  +91-9962443338
                </p>
              </Col>
            </Row>
            <Row className="sizeChartContainer ">
              <Col lg="6">
                <h6>Synergy Sports</h6>

                <p>
                  <CiLocationOn />
                  Address : BUILDING NUMBER 57-58-64, MARUTHI PLAZA, SHOP NO :
                  D3, 2ND FLOOR, DVG ROAD, BASAVANGUDI, BANGLORE- 560004.
                </p>
                <p>
                  {" "}
                  <BiSolidUser />
                  Contact Person : Vikram Bj
                </p>
                <p>
                  <a href="#" style={{ color: "#545454" }}>
                    {" "}
                    <CiMail />
                    sportzsynergy@gmail.com
                  </a>
                </p>
                <p>
                  {" "}
                  <BiPhoneCall />
                  +91-9916389828
                </p>
              </Col>
              <Col lg="6">
                {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.985116405572!2d72.45432301442878!3d22.987574784970796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9a443eb723cd%3A0x4e59384c028434b4!2sSimmons+Rana+Racing!5e0!3m2!1sen!2sin!4v1475229676935"
              width="100%"
              height="225"
              frameborder="0"
              allowfullscreen=""
            ></iframe> */}
              </Col>
            </Row>
            <Row className="sizeChartContainer ">
              <Col lg="6">
                {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.48229818854!2d80.22779521425333!3d13.06859119079232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266887abcfebf%3A0x516474a016253f0e!2sSathyam+Homes%2C+Pillaiyar+Koil+St%2C+Apparao+Garden%2C+Aminjikarai%2C+Chennai%2C+Tamil+Nadu+600029!5e0!3m2!1sen!2sin!4v1475229991950"
              width="100%"
              height="225"
              frameborder="0"
              allowfullscreen=""
            ></iframe> */}
              </Col>
              <Col lg="6">
                <h6>CHANDRAVARSHA TRADERS PVT. LTD.- MAHARASHTRA REGION</h6>

                <p>
                  <CiLocationOn />
                  Address : 5, JEEVAN CHAAYA SOCIETY, FARMLAND, RAMDASPETH.
                  NAGPUR- 440010 MAHARASHTRA
                </p>
                <p>
                  {" "}
                  <BiSolidUser />
                  Contact Person : PIYUSH AKRE AND SHIVAM MEHTA
                </p>
                <p>
                  <a href="#" style={{ color: "#545454" }}>
                    {" "}
                    <CiMail />
                    shivamcv10@outlook.com & piyushakre@gmail.com
                  </a>
                </p>
                <p>
                  {" "}
                  <BiPhoneCall />
                  +91 9923536865 , +91 8805558055
                </p>
              </Col>
            </Row>
            <Row className="sizeChartContainer ">
              <h3 className="globalTitle">United States</h3>
              <Col lg="6">
                <h6>Simmons Rana Racing</h6>

                <p>
                  <CiLocationOn />
                  Address : 1713 SW 51ST ST Cape Coral, Florida, 33914
                </p>
                <p>
                  {" "}
                  <BiSolidUser />
                  Contact Person : Jennifer Simmons
                </p>
                <p>
                  <a href="#" style={{ color: "#545454" }}>
                    {" "}
                    <CiMail />
                    jsimmonsracing@comcast.net
                  </a>
                </p>
                <p>
                  {" "}
                  <BiPhoneCall />
                  1-800-376-9907
                </p>

                <p>
                  {" "}
                  <FaGlobeAfrica />
                  http://simmons-racing.com/
                </p>
              </Col>
              <Col lg="6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3568.8534345184266!2d-82.01192468496261!3d26.55697598328213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88db386f11868f51%3A0x64d140cec311ae8b!2s1713+SW+51st+St%2C+Cape+Coral%2C+FL+33914%2C+USA!5e0!3m2!1sen!2sin!4v1475747065595"
                  width="100%"
                  height="225"
                  frameborder="0"
                  allowfullscreen=""
                ></iframe>
              </Col>
            </Row>
            <Row className="sizeChartContainer ">
              <h3 className="globalTitle">Korea</h3>
              <Col lg="6">
                <h6>Shinbon Trading Co. Ltd.</h6>

                <p>
                  <CiLocationOn />
                  Address : 303, 3rd Floor, Dongwoo Bldg, 322-2, Yangjae-Dong,
                  Seocho-Ku, Seoul, Korea Pincode : 06779
                </p>
                <p>
                  {" "}
                  <BiSolidUser />
                  Contact Person : Moogon Kim
                </p>
                <p>
                  <a href="#" style={{ color: "#545454" }}>
                    {" "}
                    <CiMail />
                    shinbon90@gmail.com
                  </a>
                </p>
                <p>
                  {" "}
                  <BiPhoneCall />
                  +8225712567
                </p>

                <p>
                  {" "}
                  <FaGlobeAfrica />
                  http://shinbon.com/index.html
                </p>
              </Col>
              <Col lg="6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25333.32059818357!2d127.01856560293578!3d37.468629058228316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca12c5293bb67%3A0x41b3e4a19ceb71f4!2sYangjae-dong%2C+Seocho-gu%2C+Seoul%2C+South+Korea!5e0!3m2!1sen!2sin!4v1475747177396"
                  width="100%"
                  height="225"
                  frameborder="0"
                  allowfullscreen=""
                ></iframe>
              </Col>
            </Row>
            <Row className="sizeChartContainer ">
              <h3 className="globalTitle">Poland</h3>
              <Col lg="6">
                <h6>Zico Racing</h6>

                <p>
                  <CiLocationOn />
                  Address : ul. Glogowska 38 60-736 Poznan, Poland
                </p>
                <p>
                  {" "}
                  <BiSolidUser />
                  Contact Person : Jakub Zico Ciesielski
                </p>
                <p>
                  <a href="#" style={{ color: "#545454" }}>
                    {" "}
                    <CiMail />
                    zico@poznan.home.pl
                  </a>
                </p>
                <p>
                  {" "}
                  <BiPhoneCall />
                  +48-603952290
                </p>

                <p>
                  {" "}
                  <FaGlobeAfrica />
                  http://www.zicoracing.com
                </p>
              </Col>
              <Col lg="6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2434.4201046190174!2d16.90147531580297!3d52.39906197979153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47045b2d2a6d8881%3A0x88f1c934f456ad61!2sZico+Racing+Jakub+Ciesielski!5e0!3m2!1sen!2sin!4v1475746805075"
                  width="100%"
                  height="225"
                  frameborder="0"
                  allowfullscreen=""
                ></iframe>
              </Col>
            </Row>
            <Row className="sizeChartContainer ">
              <h3 className="globalTitle">Spain</h3>
              <Col lg="6">
                <h6>Elite Sports</h6>

                <p>
                  <CiLocationOn />
                  Address : Calle la Rioja, 6, 31008 Pamplona, Navarra Spain
                </p>
                <p>
                  {" "}
                  <BiSolidUser />
                  Contact Person : Garikoitz Lerga
                </p>
                <p>
                  <a href="#" style={{ color: "#545454" }}>
                    {" "}
                    <CiMail />
                    info@elite-sport.es
                  </a>
                </p>
                <p>
                  {" "}
                  <BiPhoneCall />
                  +34948272053
                </p>

                <p>
                  {" "}
                  <FaGlobeAfrica />
                  http://elite-sport.es
                </p>
              </Col>
              <Col lg="6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2927.002255824337!2d-1.661773384958416!3d42.80943837915926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd509261198bba6d%3A0x5d3d30a1c3eb2e1!2sCalle+de+la+Rioja%2C+6%2C+31008+Pamplona%2C+Navarra%2C+Spain!5e0!3m2!1sen!2sin!4v1475230124189"
                  width="100%"
                  height="225"
                  frameborder="0"
                  allowfullscreen=""
                ></iframe>
              </Col>
            </Row>
            <Row className="sizeChartContainer ">
              <Col lg="6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3038.076209352596!2d-3.6766867850496605!3d40.40716247936616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42261946f871a7%3A0xeba21e2a3270bac3!2sIn-Gravity!5e0!3m2!1sen!2sin!4v1505822863245"
                  width="100%"
                  height="225"
                  frameborder="0"
                  allowfullscreen=""
                ></iframe>
              </Col>
              <Col lg="6">
                <h6>In-Gravity</h6>

                <p>
                  <CiLocationOn />
                  Address : IN-GRAVITY HITECH SYSTEM, S.L. Pza. Mariano de Cavia
                  2, 28007 Madrid
                </p>
                <p>
                  {" "}
                  <BiSolidUser />
                  Contact Person : Susana Gonzales
                </p>
                <p>
                  <a href="#" style={{ color: "#545454" }}>
                    {" "}
                    <CiMail />
                    compras@in-gravity.com
                  </a>
                </p>
                <p>
                  {" "}
                  <BiPhoneCall />
                  915524553
                </p>
                <p>
                  {" "}
                  <FaGlobeAfrica />
                  www.in-gravity.com
                </p>
              </Col>
            </Row>
            <Row className="sizeChartContainer ">
              <h3 className="globalTitle">Netherlands</h3>
              <Col lg="6">
                <h6>Stouwdam Sport BV</h6>

                <p>
                  <CiLocationOn />
                  Address : Rondweg 60a 8091 XK Wezep The Netherlands
                </p>
                <p>
                  {" "}
                  <BiSolidUser />
                  Contact Person : Henk Schra
                </p>
                <p>
                  <a href="#" style={{ color: "#545454" }}>
                    {" "}
                    <CiMail />
                    sales@stouwdamsport.nl
                  </a>
                </p>
                <p>
                  {" "}
                  <BiPhoneCall />
                  +31 38-3762626
                </p>

                <p>
                  {" "}
                  <FaGlobeAfrica />
                  http://www.stouwdam.nl/
                </p>
              </Col>
              <Col lg="6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2430.415257029603!2d5.996670777985202!3d52.4716166548099!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c7d98bfff011ab%3A0x7256228f3126f3e4!2sStouwdam.nl+-+Schaatsen+Skeelers+Racefietsen+%26+Mountainbikes!5e0!3m2!1sen!2sin!4v1489659463250"
                  width="100%"
                  height="225"
                  frameborder="0"
                  allowfullscreen=""
                ></iframe>
              </Col>
            </Row>
            <Row className="sizeChartContainer ">
              <h3 className="globalTitle">Germany</h3>
              <Col lg="6">
                <h6>Patines.Co</h6>

                <p>
                  <CiLocationOn />
                  Address : Avenida Calle 8 SUR, #41-28 Cuidad Montes,
                  Bogota-111631, Colombia
                </p>
                <p>
                  {" "}
                  <BiSolidUser />
                  Contact Person : Lyda Ávila
                </p>

                <p>
                  {" "}
                  <BiPhoneCall />
                  +57-314-2128254
                </p>

                <p>
                  {" "}
                  <FaGlobeAfrica />
                  http://www.patines.co
                </p>
              </Col>
              <Col lg="6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.9266022941815!2d-74.11961308584284!3d4.607161943745831!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9936059ffbcf%3A0xbdbfb551c757b3ce!2sCl.+8+Sur+%2341-28%2C+Bogot%C3%A1%2C+Colombia!5e0!3m2!1sen!2sin!4v1519725834845"
                  width="100%"
                  height="225"
                  frameborder="0"
                  allowfullscreen=""
                ></iframe>
              </Col>
            </Row>
            <Row className="sizeChartContainer ">
              <Col lg="6">
                {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.48229818854!2d80.22779521425333!3d13.06859119079232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266887abcfebf%3A0x516474a016253f0e!2sSathyam+Homes%2C+Pillaiyar+Koil+St%2C+Apparao+Garden%2C+Aminjikarai%2C+Chennai%2C+Tamil+Nadu+600029!5e0!3m2!1sen!2sin!4v1475229991950"
              width="100%"
              height="225"
              frameborder="0"
              allowfullscreen=""
            ></iframe> */}
              </Col>
              <Col lg="6">
                <h6>RODAR TIENDA DEPORTIVA</h6>

                <p>
                  <CiLocationOn />
                  Address : CALLE 11 NO. 50-39, CASA 10. FUENTES DE CAMINO REAL
                  III. CALI, CODIGO POSTAL 760036 COLOMBIA.
                </p>
                <p>
                  {" "}
                  <BiSolidUser />
                  Contact Person : FREDDY GUERRA
                </p>
                <p>
                  <a href="#" style={{ color: "#545454" }}>
                    {" "}
                    <CiMail />
                    rodartiendadeportiva@hotmail.com
                  </a>
                </p>
                <p>
                  {" "}
                  <BiPhoneCall />
                  +57 318 3881650
                </p>
              </Col>
            </Row>
            <Row className="sizeChartContainer ">
              <h3 className="globalTitle">Italy</h3>
              <Col lg="6">
                <h6>BIMAR Technical Skates</h6>

                <p>
                  <CiLocationOn />
                  Address : Via Delle Betulle, 115 00171 - Roma
                </p>
                <p>
                  {" "}
                  <BiSolidUser />
                  Contact Person : Marco Bianchini
                </p>
                <p>
                  <a href="#" style={{ color: "#545454" }}>
                    {" "}
                    <CiMail />
                    info@bimarskates.com
                  </a>
                </p>
                <p>
                  {" "}
                  <BiPhoneCall />
                  0039 3381738587
                </p>

                <p>
                  {" "}
                  <FaGlobeAfrica />
                  https://www.bimarskates.com/
                </p>
              </Col>
              <Col lg="6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.2206203727005!2d12.561990215441428!3d41.88811217922139!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f624535720b91%3A0x796c8149b0d0ffb9!2sBIMAR%20Technical%20Skates!5e0!3m2!1sen!2sin!4v1609158726481!5m2!1sen!2sin"
                  width="100%"
                  height="225"
                  frameborder="0"
                  allowfullscreen=""
                ></iframe>
              </Col>
            </Row>
            {/* <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Russia</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Afghanistan</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Albania</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Algeria</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Andorra</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Angola</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Antigua And Barbuda</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Argentina</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Armenia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Australia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Austria</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Azerbaijan</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Bahamas</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Bahrain</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Bangladesh</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Barbados</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Belarus</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Belgium</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Belize</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Benin</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Bhutan</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Bosnia And Herzegovina</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Botswana</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Brazil</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Brunei</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Bulgaria</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Burkina Faso</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Burundi</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Côte D'Ivoire</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Cabo Verde</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Cambodia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Cameroon</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Canada</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Central African Republic</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Chad</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Chile</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Colombia</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Comoros</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Congo</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Costa Rica</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Croatia</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Cuba</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Cyprus</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Czechia</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Democratic Republic Of The Congo</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Denmark</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Djibouti</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Dominica</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Dominican Republic</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Ecuador</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Egypt</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">El Salvador</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Equatorial Guinea</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Eritrea</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Estonia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Ethiopia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Falkland Islands</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Faroe Islands</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Fiji</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Finland</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">France</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">French Guiana</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">French Polynesia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Gabon</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Gambia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Georgia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Ghana</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Gibraltar</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Greece</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Greenland</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Grenada</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Guadeloupe</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Guam</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Guatemala</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Guinea</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Guinea-Bissau</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Guyana</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Haiti</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Holy See</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Honduras</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Hong Kong</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Hungary</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Iceland</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Indonesia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Iran</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Iraq</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Ireland</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Israel</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Jamaica</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Japan</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Jordan</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Kazakhstan</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Kenya</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Kiribati</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Kuwait</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Kyrgyzstan</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Laos</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Latvia</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Lebanon</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Lesotho</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Liberia</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Libya</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Liechtenstein</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Lithuania</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Luxembourg</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Macao</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Macedonia</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Madagascar</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Malawi</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Malaysia</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Maldives</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Maldives</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Malta</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Marshall Islands</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Martinique</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Mauritania</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Mauritius</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Mayolte</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Mexico</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Micronesia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Moldova</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Monaco</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Mongolia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Montserrat</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Morocco</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Mozambique</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Myanmar</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Namibia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Nauru</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Nepal</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Netherlands Antilles</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Nevis</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">New Caledonia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">New Zealand</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Nicaragua</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Niger</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Nigeria</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Niue</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">North Korea</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">North Mariana Islands</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Norway</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Oman</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Pakistan</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Palau</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Panama</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Papua New Guinea</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Paraguay</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Peru</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Philippines</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Poland</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Portugal</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Puerto Rico</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Qatar</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Reunion</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Romania</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Rwanda</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Saipan</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">San Marino</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Sao Tome And Principe</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Saudi Arabia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Senegal</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Serbia And Montenegro</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Seychelles</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Sierra Leone</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Singapore</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Slovakia</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Slovenia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Solomon Islands</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Somalia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">South Africa</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Sri Lanka</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">St. Helena</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">St. Kitts/Nevis</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">
                St. Pierre &(Et) Miquelon (France)
              </h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Sudan</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Suriname</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Swaziland</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Sweden</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Switzerland</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Syrian Arab Republic (Syria)</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Tahiti</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Taiwan</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Tajikistan</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Tanzania</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Thailand</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Togo</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Tokelau</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Tonga</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Trinidad And Tobago</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Tunisia</h3>
            </div>{" "}
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Turkey</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Turkmenistan</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Tuvalu</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Uganda</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Ukraine</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">United Arab Emirates</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">United Kingdom</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Uruguay</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Uzbekistan</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Vanuatu</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Vatican City</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Venezuela</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Viet Nam</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Virgin Islands</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Wallis And Futuna</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Western Samoa</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Yemen</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Yemen Arab Republic</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Yugoslavia</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Zaire</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Zambia</h3>
            </div>
            <div className="sizeChartContainerTitle ">
              <h3 className="globalTitle">Zimbabwe</h3>
            </div> */}
            </Card>
          </Container>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default GlobalDealer;
