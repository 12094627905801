import React, { useEffect, useState } from "react";
// import Product2 from "./../assets/img/product3.jpeg";
// import Product3 from "./../assets/img/product4.jpeg";
// import Product4 from "./../assets/img/product5.jpeg";
import { useSelector } from "react-redux";
import { getBestSelling } from "../helpers/backen_helper";

import ProductCard from "./ProductCard";

const BestSeling = () => {
  const currency = useSelector((state) => state.selectedCurrency);
  const [bestSelling, setBestSelling] = useState([]);

  const fetchData = async () => {
    const data = await getBestSelling();
    setBestSelling(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="container pb-100 pt-100 ">
        <div className="row">
          <div className="section-title">
            <h2>Best Selling</h2>
          </div>

          {bestSelling?.map((n,index) => (
            <div className="col-sm-6 col-lg-3" key={index}>
              <ProductCard
                 imageSource={n.Image}
                 productId={n._id}
                 productTitle={n.ProductName}
                 price={n.MRP}
                 ProductColorId={n.ProductColorId}
                 ProductGroupSizeId={n.ProductGroupSizeId}
               ></ProductCard>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default BestSeling;
