import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";

const PrivacyPolicy = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="Privacy Policy" SubTitle="Privacy Policy" />
          <div className="rules-area ptb-100">
            <div className="container">
              <Card className="temAndCondition">
                <div className="rules-item">
                  <div className="inner">
                    <h3> Privacy Policy</h3>
                    <p>
                      Your privacy and trust are very important to us. We
                      recognize that you may be concerned about our collection,
                      use, and disclosure of the personally identifiable
                      information that we collect when you use the Website and
                      the services offered on the Website. This Privacy Policy
                      describes the information that we collect from you, how we
                      collect this information, and what we do with it after we
                      collect it. By using the Website, you are accepting the
                      practices described in this Privacy Policy. If you have
                      any questions or comments regarding this Privacy Policy,
                      please contact us at: support@simmonsrana.com.
                    </p>
                  </div>
                  <div className="inner">
                    <p>
                      We may make changes to the Privacy Policy from time to
                      time. We will notify you of any material changes by
                      sending you an email, posting a notice on the home page of
                      the Website. If you receive notification of a change in
                      our Privacy Policy, you must review the new Privacy Policy
                      carefully to make sure you understand our practices and
                      procedures. You may not be able to receive notices from us
                      if your cookies are not set to accept and/or are disabled.
                    </p>
                  </div>
                  <div className="inner">
                    <p>
                      We collect Personal Information that you provide to us,
                      such as your name, mailing address, phone number, email
                      address, credit card number, and financial information.
                    </p>
                  </div>
                  <div className="inner">
                    <p>
                      We also collect non-personal information from you, such as
                      your browser type, the URL of the previous website you
                      visited, your ISP, operating system, and your Internet
                      protocol (IP) Address (“Non-Personal Information”).
                      Non-Personal Information cannot be easily used to
                      personally identify you.
                    </p>
                  </div>
                  <div className="inner">
                    <p>
                      We collect Personal Information from you when you provide
                      it to us. For example, if you purchase a product, we may
                      collect your name, mailing address, telephone number,
                      credit card number, and email address. If you sign up to
                      receive a newsletter, we will collect your email address.
                    </p>
                  </div>
                  <div className="inner">
                    <p>
                      If you communicate with us regarding the Website or our
                      service, we will collect any information that you provide
                      to us in any such communication.
                    </p>
                    <p>
                      Like the operators of most websites, we use analytic and
                      reporting technologies to record Non-Personal Information
                      such as Internet domain and host names, Internet protocol
                      (IP) addresses, browser software, operating system types,
                      clickstream patterns, and the dates and times that the
                      Website are accessed. We also contract with several online
                      partners to help manage, monitor and optimize our Website
                      and to help us measure the effectiveness of our
                      advertising, communications and how visitors use the
                      Website. To do this, we may use web beacons and cookies.
                    </p>
                    <p>
                      We use Personal Information primarily for our own internal
                      purposes, such as providing, maintaining, evaluating, and
                      improving our Services and the Website, fulfilling
                      requests for information, producing and shipping the
                      products that you order, and providing customer support.
                      Similarly, if you sign up to receive a Simmons Rana Racing
                      newsletter, we will use the email address you provide to
                      send you the newsletters.
                    </p>
                    <p>
                      We use Non-Personal Information to track the use of the
                      Website and for other internal purposes, such as
                      providing, maintaining, evaluating, and improving the
                      Website.
                    </p>
                    <p>
                      We may disclose your Personal Information to any
                      successor-in-interest of ours, such as a company that
                      acquires us. In the event Simmons Rana Racing goes through
                      a business transition, such as a merger or acquisition by
                      or with another company, or sale of all or a portion of
                      our assets, your Personal Information will likely be among
                      the assets transferred. You will be notified via email or
                      by a notice on our Website of any such change in ownership
                      or control of your Personal Information.
                    </p>
                    <p>
                      We may disclose your Personal Information to prevent an
                      emergency, to prevent harm to others, to respond to legal
                      requirements, to protect or enforce our rights and
                      policies, to protect or enforce the rights of a third
                      party, or as required or permitted by law (including,
                      without limitation, to comply with a subpoena or court
                      order).
                    </p>
                    <p>
                      We may contract with various third parties who help us
                      provide, maintain and improve the Website. For example, we
                      use a third party to process payments made to us, and may
                      subcontract out production, fulfillment, analytics,
                      reporting or other operations. We also contract with
                      several online partners to help manage, monitor and
                      optimize our Website and to help us measure the
                      effectiveness of our advertising, communications and how
                      visitors use the Website. We will use commercially
                      reasonable efforts to prevent such third parties from
                      disclosing your Personal Information, except for the
                      purpose of providing the services in question. We cannot
                      guarantee that such third parties will not disclose your
                      Personal Information. Simmons Rana Racing does not share,
                      rent or trade your Personal Information with third parties
                      for their promotional purposes.
                    </p>
                    <p>
                      If you purchase a product from the Shop, Simmons Rana
                      Racing may provide your name, and the city and state
                      information provided by you.
                    </p>
                    <p>
                      We will disclose Non-Personal Information, in aggregate
                      form, to potential strategic partners, advertisers,
                      investors, customers, and others. You may not opt-out of
                      the sharing of this information. However, it cannot be
                      easily used to identify you personally.
                    </p>
                    <p>
                      We may use third party service providers to serve ads on
                      our behalf across the Internet and sometimes on the
                      Website. They may collect anonymous information about your
                      visits to our Website, and your interaction with our
                      products and services. They may also use information about
                      your visits to this and other websites to target
                      advertisements for goods and services. This anonymous
                      information is collected through the use of a web beacon,
                      which is industry standard technology used by most major
                      websites.
                    </p>
                    <p>
                      A cookie is a small file placed on the hard drive of your
                      computer. Most websites use cookies. We use cookies to
                      track your use of the Website and provide you with a more
                      personalized user experience.
                    </p>
                    <p>
                      A web beacon is an often-transparent graphic image,
                      usually no larger than a 1×1 pixel that is placed on a web
                      page or in an e-mail that is used to monitor the behavior
                      of the user visiting the Website or receiving the e-mail.
                    </p>
                    <p>
                      Cookies and web beacons used by Simmons Rana Racing and
                      our online partners are not linked to Personal
                      Information. This Privacy Policy covers the use of cookies
                      and web beacons by Simmons Rana Racing only.
                    </p>
                    <p>
                      Whenever we obtain your Personal Information, we use
                      commercially reasonable efforts to protect it from
                      unauthorized access or disclosure. However, we are not
                      insurers of the security of your Personal Information.
                      Accordingly, we assume no liability for any disclosure of
                      data due to errors in transmission, unauthorized third
                      party access or other acts of third parties, or acts or
                      omissions beyond our reasonable control.
                    </p>
                    <p>
                      The content that you store, post, or transmit on or
                      through the Website, such as message board postings,
                      storefront pages, and images, may be accessed by other
                      users, including people that you do not know. We are not
                      responsible for the actions of others. Therefore, you
                      should use care in communicating with other users and only
                      disclose your Personal Information to other users that you
                      know to be trustworthy. You should not assume that your
                      content will be kept private.
                    </p>
                    <p>
                      You can obtain a copy of and request that we correct
                      errors in your Personal Information by emailing us at
                      support@simmonsrana.com. For your protection, you will be
                      required to provide proof of your identity to obtain a
                      copy of your Personal Information. If your Personal
                      Information changes or if you no longer want to use our
                      services, you may correct, update or deactivate your
                      Personal Information and/or your account through the
                      account management screen. If you would like to deactivate
                      or terminate your account, you can also contact:
                      support@simmonsrana.com
                    </p>
                    <p>
                      If you choose to use our referral service to tell a friend
                      about the Website, we will ask you for your friend’s name
                      and email address. We will automatically send your friend
                      a one-time email inviting him or her to visit the Website.
                      We store this information for the sole purpose of sending
                      this one-time email and tracking the success of our
                      referral program.
                    </p>
                    <p>
                      The security of your Personal Information is important to
                      us. When you enter sensitive information such as a credit
                      card number and/or social security number on our
                      registration or order forms, we encrypt that information
                      using secure socket layer technology (SSL).
                    </p>
                    <p>
                      We follow generally accepted industry standards to protect
                      the Personal Information submitted to us, both during
                      transmission and once we receive it. However, no method of
                      transmission over the Internet, or method of electronic
                      storage, is 100% secure. Therefore, while we strive to use
                      commercially acceptable means to protect your Personal
                      Information, we cannot guarantee its absolute security.
                    </p>
                    <p>
                      Given that the Internet is a global environment, using the
                      Internet to collect and process Personal Information
                      necessarily involves the transmission of data on an
                      international basis. By providing Simmons Rana Racing with
                      your Personal Information you acknowledge and agree that
                      we may store your Personal Information and transfer it to
                      and from our servers located in the India and throughout
                      the world. We endeavor to protect all Personal Information
                      collected through the Website in a safe, confidential and
                      secure manner.
                    </p>
                    <p>
                      You may be able to access third party websites directly
                      from the Website. This Privacy Policy does not apply when
                      you access third party websites. We cannot control how
                      third parties may use Personal Information you disclose to
                      them, so you should carefully review the privacy policy of
                      any third-party website you visit before using it or
                      disclosing your Personal Information to its provider.
                    </p>
                    <p>
                      The Simmons Rana Racing Store are intended for users ages
                      13 and older only. Accordingly, we will not knowingly
                      collect or use any Personal Information from children that
                      we know to be under the age of 13. In addition, we will
                      delete any information in our database that we know
                      originates from a child under the age of 13
                    </p>
                    <p>
                      Prospective users between the ages of 13 and 17 can only
                      use the Simmons Rana Racing website under their parents or
                      legal guardian’s supervision. If you are between the ages
                      of 13 and 17, you, your parent, or your legal guardian may
                      request that we deactivate any of your Personal
                      Information in our database and/or opt-out from receiving
                      communications from us. If you wish to do so, please
                      contact us at support@simmonsrana.com
                    </p>
                    <p>
                      If you no longer wish to receive our newsletters and
                      promotional communications, you may opt-out of receiving
                      them by following the instructions included in each
                      newsletter or communication or by emailing us at
                      support@simmonsrana.com. If you need assistance you may
                      contact us at support@simmonsrana.com.
                    </p>
                    <p>
                      If we disclose your Personal Information to a third party
                      in a manner that is not set forth in this Privacy Policy,
                      you will be notified so that you can make an informed
                      choice about sharing your Personal Information with that
                      third party.
                    </p>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default PrivacyPolicy;
