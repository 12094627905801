import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";

const TermsAndCondition = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="Terms & conditions" SubTitle="Terms & conditions" />
          <div className="rules-area ptb-100">
            <div className="container">
              <Card style={{ padding: "10px" }}>
                <div className="rules-item">
                  <div className="inner">
                    <h3> Terms & conditions</h3>
                    <p>
                      THIS IS A LEGAL AGREEMENT BETWEEN YOU AND SIMMONS RANA
                      RACING STATING THE TERMS THAT GOVERN YOUR USE OF THE
                      ONLINE SHOPPING SERVICE. THIS AGREEMENT – TOGETHER WITH
                      ALL UPDATES, ADDITIONAL TERMS, SOFTWARE LICENSES, AND ALL
                      OF SIMMONS RANA RACING RULES AND POLICIES – COLLECTIVELY
                      CONSTITUTE THE “AGREEMENT” BETWEEN YOU AND SIMMONS RANA
                      RACING. ​IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE
                      THE SERVICE. YOU MUST ACCEPT AND ABIDE BY THESE TERMS AS
                      PRESENTED TO YOU: CHANGES, ADDITIONS, OR DELETIONS ARE NOT
                      ACCEPTABLE, AND VICTORY MAY REFUSE ACCESS TO THE ONLINE
                      SHOPPING SERVICE FOR NONCOMPLIANCE WITH ANY PART OF THIS
                      AGREEMENT.
                    </p>
                  </div>
                  <ol>
                    <li>
                      {" "}
                      <div className="inner">
                        <p>
                          Definition of the Simmons Rana Racing: Simmons Rana
                          Racing is the provider of the online shopping
                          experience that permits you to purchase roller skate
                          parts and merchandise – such as skate boots, frames,
                          wheels bearings apparel and accessories under certain
                          terms and conditions as set forth in this Agreement.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Age requirements for use of the Website. This Website
                          is available for individuals aged 13 years or older.
                          If you are 13 or older but under the age of 18, you
                          should review these terms and conditions with your
                          parent or guardian to make sure that you and your
                          parent or guardian understand these terms and
                          conditions.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Objectionable Material. You understand that by using
                          the Website, you may encounter content that may be
                          deemed offensive, indecent, or objectionable, which
                          content may or may not be identified as having
                          explicit language. Nevertheless, you agree to use the
                          Website at your sole risk and that Simmons Rana Racing
                          shall have no liability to you for content that may be
                          found to be offensive, indecent, or objectionable.
                        </p>
                      </div>
                    </li>
                    <li>
                      {" "}
                      <div className="inner">
                        <p>
                          System Requirements. Use of the Website requires a
                          compatible device, Internet access (fees may apply),
                          and certain software (fees may apply), and may require
                          obtaining updates or upgrades from time to time.
                          Because use of the Website involves hardware,
                          software, and Internet access, your ability to use the
                          Website may be affected by the performance of these
                          factors. High speed Internet access is strongly
                          recommended. You acknowledge and agree that such
                          system requirements, which may be changed from time to
                          time, are your responsibility. The Website is not part
                          of any other product or offering, and no purchase or
                          obtaining of any other product shall be construed to
                          represent or guarantee you access to the Website.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Policies and Rules. Your use of the Website and
                          purchases made through it are subject to Simmons Rana
                          Racing Terms of Sale, which can be readily viewed on
                          the Website, and any end-user agreements or other
                          terms and conditions required for use of the Website,
                          all of which are hereby made a part of this Agreement.
                          If you have not already read Simmons Rana Racing Terms
                          of Sale, you should do so now.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          6.Simmons Rana Racing Privacy Policy. Except as
                          otherwise expressly provided for in this Agreement,
                          the Website is subject to Simmons Rana Racing Privacy
                          Policy, which is expressly made a part of this
                          Agreement. If you have not already read Simmons Rana
                          Racing Privacy Policy, you should do so now.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Your Information. You agree to provide accurate,
                          current, and complete information required to register
                          with the Website and at other points as may be
                          required in the course of using the Website. You
                          further agree to maintain and update your Registration
                          Data as required to keep it accurate, current, and
                          complete. Simmons Rana Racing may terminate your
                          rights to any or all of the Website if any information
                          you provide is false, inaccurate or incomplete. You
                          agree that Simmons Rana Racing may store and use the
                          Registration Data you provide (not including credit
                          card and debit card account information) for use in
                          maintaining your accounts and billing fees to your
                          credit card and debit card account.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          User Account and Security. Account and Password. As a
                          registered user of the Website, you may receive or
                          establish an account (“Account”). You are solely
                          responsible for maintaining the confidentiality and
                          security of your Account. You should not reveal your
                          Account information to anyone else or use anyone
                          else’s Account. You are entirely responsible for all
                          activities that occur on or through your Account, and
                          you agree to immediately notify Simmons Rana Racing of
                          any unauthorized use of your Account or any other
                          breach of security. Simmons Rana Racing shall not be
                          responsible for any losses arising out of the
                          unauthorized use of your Account.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Usage Rules
                          <br /> Your use of the Products is conditioned upon
                          your prior acceptance of the terms of this Agreement.
                          You agree that you will not attempt to, or encourage
                          or assist any other person to, circumvent or modify
                          any security technology or software that is part of
                          the Service or used to administer the Usage Rules.
                          Simmons Rana Racing reserves the right to post or
                          publish any materials, and to delete, remove or edit
                          any material, at any time in its sole discretion
                          without notice or liability.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Agreement to Pay. <br /> Payment for Products. You
                          agree to pay for all Products you purchase through the
                          Service, and that Simmons Rana Racing may charge your
                          credit card and debit card for any Products purchased,
                          and for any additional amounts (including any taxes
                          and late fees, as applicable) as may be accrued by or
                          in connection with your Account. YOU ARE RESPONSIBLE
                          FOR THE TIMELY PAYMENT OF ALL FEES AND FOR PROVIDING
                          SIMMONS RANA RACING WITH A VALID DETAIL FOR PAYMENT OF
                          ALL FEES. All fees will be billed to the credit card
                          and debit card you designate during the checkout
                          process.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Right to Change Prices and Availability of Products.
                          Prices and availability of any Products are subject to
                          change at any time.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Electronic Signatures and Contracts. Your use of the
                          Service includes the ability to enter into agreements
                          and/or to make purchases electronically. YOU
                          ACKNOWLEDGE THAT YOUR ELECTRONIC SUBMISSIONS
                          CONSTITUTE YOUR AGREEMENT AND INTENT TO BE BOUND BY
                          AND TO PAY FOR SUCH AGREEMENTS AND PURCHASES. YOUR
                          AGREEMENT AND INTENT TO BE BOUND BY ELECTRONIC
                          SUBMISSIONS APPLIES TO ALL RECORDS RELATING TO ALL
                          TRANSACTIONS YOU ENTER INTO ON THIS SITE, INCLUDING
                          NOTICES OF CANCELLATION, POLICIES, CONTRACTS, AND
                          APPLICATIONS.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          In order to access and retain your electronic records,
                          you may be required to have certain hardware and
                          software, which are your sole responsibility.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Delivery of Products. On occasion, technical problems
                          may delay or prevent delivery of your Product. Your
                          exclusive and sole remedy with respect to Product that
                          is not delivered within a reasonable period will be
                          either replacement of such Product, or refund of the
                          purchase price paid for such Product, as determined by
                          Simmons Rana Racing.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Intellectual Property. Acknowledgement of Ownership.
                          You agree that the Service, including but not limited
                          to graphics, audio clips, and editorial content,
                          contains proprietary information and material that is
                          owned by Simmons Rana Racing and/or its licensors, and
                          is protected by applicable intellectual property and
                          other laws, including but not limited to copyright,
                          and that you will not use such proprietary information
                          or materials in any way whatsoever except for use of
                          the Website in compliance with the terms of this
                          Agreement. No portion of the Website may be reproduced
                          in any form or by any means. You agree not to modify,
                          rent, lease, loan, sell, distribute, or create
                          derivative works based on the Website, in any manner,
                          and you shall not exploit the Website in any
                          unauthorized way whatsoever, including but not limited
                          to, by trespass or burdening network capacity.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Removal of Simmons Rana Racing Content or Other
                          Materials. Notwithstanding any other provision of this
                          Agreement, Simmons Rana Racing and its licensors
                          reserve the right to change, suspend, remove, or
                          disable access to any Products, content, or other
                          materials comprising a part of the Website at any time
                          without notice. In no event, will Simmons Rana Racing
                          be liable for the removal of or disabling of access to
                          any such Products, content or materials under this
                          Agreement. Simmons Rana Racing may also impose limits
                          on the use of or access to certain features or
                          portions of the Website, in any case and without
                          notice or liability.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Copyrights. All copyrights in and to the Website are
                          owned by Simmons Rana Racing and/or its licensors. THE
                          USE OF THE SOFTWARE OR ANY PART OF THE SERVICE, EXCEPT
                          FOR USE OF THE SERVICE AS PERMITTED IN THESE TERMS OF
                          SERVICE, IS STRICTLY PROHIBITED AND INFRINGES ON THE
                          INTELLECTUAL PROPERTY RIGHTS OF OTHERS AND MAY SUBJECT
                          YOU TO CIVIL AND CRIMINAL PENALTIES, INCLUDING
                          POSSIBLE MONETARY DAMAGES, FOR COPYRIGHT INFRINGEMENT.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Trademarks. Simmons Rana Racing, the Simmons Rana
                          Racing logo and other Simmons Rana Racing trademarks,
                          service marks, graphics, and logos used in connection
                          with the Service are trademarks or registered
                          trademarks of Simmons Rana Racing. in the U.S. and/or
                          other countries. Other trademarks, service marks,
                          graphics, and logos used in connection with the
                          Service may be the trademarks of their respective
                          owners. You are granted no right or license with
                          respect to any of the aforesaid trademarks and any use
                          of such trademarks.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          0Termination. Termination by Simmons Rana Racing. If
                          you fail, or Simmons Rana Racing suspects that you
                          have failed, to comply with any of the provisions of
                          this Agreement, including but not limited to failure
                          to make payment of fees due, failure to provide
                          Simmons Rana Racing with a valid credit card and debit
                          card or with accurate and complete Registration Data,
                          failure to safeguard your Account information,
                          violation of the Usage Rules or any license to the
                          software, or infringement or other violation of third
                          parties’ rights, Simmons Rana Racing, at its sole
                          discretion, without notice to you may: (i) terminate
                          this Agreement and/or your Account, and you will
                          remain liable for all amounts due under your Account
                          up to and including the date of termination; and/or
                          (ii) terminate the license to the software; and/or
                          (iii) preclude access to the Service (or any part
                          thereof).Termination of the Service. Simmons Rana
                          Racing reserves the right to modify, suspend, or
                          discontinue the Service (or any part or content
                          thereof) at any time with or without notice to you,
                          and Simmons Rana Racing will not be liable to you or
                          to any third party should it exercise such rights.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          General Compliance with Laws. The Service is
                          controlled and operated by Simmons Rana Racing from
                          its office in the India. You agree to comply with all
                          local, state and national laws, statutes, ordinances,
                          and regulations that apply to your use of the Service.
                          <br /> Enforcement of These Terms. Simmons Rana Racing
                          reserves the right to takes steps Simmons Rana Racing
                          believes are reasonably necessary or appropriate to
                          enforce and/or verify compliance with any part of this
                          Agreement (including but not limited to Simmons Rana
                          Racing right to cooperate with any legal process
                          relating to your use of the Service and/or Products,
                          and/or a third-party claim that your use of the
                          Service and/or Products is unlawful and/or infringes
                          such third party’s rights). You agree that Simmons
                          Rana Racing has the right, without liability to you,
                          to disclose any Registration Data and/or Account
                          information to law enforcement authorities, government
                          officials, and/or a third party, as Simmons Rana
                          Racing believes is reasonably necessary or appropriate
                          to enforce and/or verify compliance with any part of
                          this Agreement (including but not limited to Simmons
                          Rana Racing right to cooperate with any legal process
                          relating to your use of the Service and/or Products,
                          and/or a third party claim that your use of the
                          Service and/or Products is unlawful and/or infringes
                          such third party’s rights).
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          No Responsibility for Third-Party Materials or Web
                          sites. Certain content, Products, and services
                          available via the Service may include materials from
                          third parties. In addition, Simmons Rana Racing may
                          provide links to certain third party Web sites. You
                          acknowledge and agree that Simmons Rana Racing is not
                          responsible for examining or evaluating the content or
                          accuracy of any such third-party material or Web
                          sites. Simmons Rana Racing does not warrant or endorse
                          and does not assume and will not have any liability or
                          responsibility for any third-party materials or Web
                          sites, or for any other materials, products, or
                          services of third parties. Links to other Web sites
                          are provided solely as a convenience to you. You agree
                          that you will not use any third-party materials in a
                          manner that would infringe or violate the rights of
                          any other party, and that Simmons Rana Racing is not
                          in any way responsible for any such use by you.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Disclaimer of Warranties; Liability Limitations.
                          <br /> SIMMONS RANA RACING DOES NOT GUARANTEE,
                          REPRESENT, OR WARRANT THAT YOUR USE OF THE WEBSITE
                          WILL BE UNINTERRUPTED OR ERROR-FREE, AND YOU AGREE
                          THAT FROM TIME TO TIME VICTORY MAY REMOVE THE WEBSITE
                          FOR INDEFINITE PERIODS OF TIME, OR CANCEL THE WEBSITE
                          AT ANY TIME, WITHOUT NOTICE TO YOU.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          YOU EXPRESSLY AGREE THAT YOUR USE OF, OR INABILITY TO
                          USE, THE WEBSITE IS AT YOUR SOLE RISK. THE WEBSITE AND
                          ALL PRODUCTS AND SERVICES DELIVERED TO YOU THROUGH THE
                          WEBSITE ARE (EXCEPT AS EXPRESSLY STATED BY Pit Bull
                          USA) PROVIDED “AS IS” AND “AS AVAILABLE” FOR YOUR USE,
                          WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
                          IMPLIED, INCLUDING ALL IMPLIED WARRANTIES OF
                          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                          TITLE, AND NONINFRINGEMENT. BECAUSE SOME JURISDICTIONS
                          DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, THE
                          ABOVE EXCLUSION OF IMPLIED WARRANTIES MAY NOT APPLY TO
                          YOU.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          IN NO CASE SHALL SIMMONS RANA RACING, ITS DIRECTORS,
                          OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, CONTRACTORS,
                          OR LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT,
                          INCIDENTAL, PUNITIVE, SPECIAL, OR CONSEQUENTIAL
                          DAMAGES ARISING FROM YOUR USE OF ANY OF THE SERVICES
                          OR FOR ANY OTHER CLAIM RELATED IN ANY WAY TO YOUR USE
                          OF THE SERVICES, INCLUDING, BUT NOT LIMITED TO, ANY
                          ERRORS OR OMISSIONS IN ANY CONTENT, OR ANY LOSS OR
                          DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
                          ANY CONTENT (OR PRODUCT) POSTED, TRANSMITTED, OR
                          OTHERWISE MADE AVAILABLE VIA THE WEBSITE, EVEN IF
                          ADVISED OF THEIR POSSIBILITY. BECAUSE SOME STATES OR
                          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE
                          LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
                          INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS,
                          SIMMONS RANA RACING LIABILITY SHALL BE LIMITED TO THE
                          EXTENT PERMITTED BY LAW.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          SIMMONS RANA RACING SHALL USE REASONABLE EFFORTS TO
                          PROTECT INFORMATION SUBMITTED BY YOU IN CONNECTION
                          WITH THE SERVICES, BUT YOU ACKNOWLEDGE AND AGREE THAT
                          YOUR SUBMISSION OF SUCH INFORMATION IS AT YOUR SOLE
                          RISK, AND Pit Bull USA HEREBY DISCLAIMS ANY AND ALL
                          LIABILITY TO YOU FOR ANY LOSS OR LIABILITY RELATING TO
                          SUCH INFORMATION IN ANY WAY. SIMMONS RANA RACING DOES
                          NOT REPRESENT OR GUARANTEE THAT THE WEBSITE WILL BE
                          FREE FROM LOSS, CORRUPTION, ATTACK, VIRUSES,
                          INTERFERENCE, HACKING, OR OTHER SECURITY INTRUSION,
                          AND VICTORY DISCLAIMS ANY LIABILITY RELATING THERETO.
                          YOU SHALL BE RESPONSIBLE FOR BACKING UP YOUR OWN
                          SYSTEM, INCLUDING ANY PRODUCTS PURCHASED FROM THE
                          ONLINE SHOPPING SERVICE THAT ARE STORED IN YOUR
                          SYSTEM.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          WAIVER & INDEMNITY. BY USING THE WEBSITE, YOU AGREE TO
                          INDEMNIFY AND HOLD SIMMONS RANA RACING, ITS DIRECTORS,
                          OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, CONTRACTORS,
                          AND LICENSORS HARMLESS WITH RESPECT TO ANY CLAIMS
                          ARISING OUT OF YOUR BREACH OF THIS AGREEMENT, YOUR USE
                          OF THE WEBSITE, OR ANY ACTION TAKEN BY SIMMONS RANA
                          RACING AS PART OF ITS INVESTIGATION OF A SUSPECTED
                          VIOLATION OF THIS AGREEMENT OR AS A RESULT OF ITS
                          FINDING OR DECISION THAT A VIOLATION OF THIS AGREEMENT
                          HAS OCCURRED. THIS MEANS THAT YOU CANNOT SUE OR
                          RECOVER ANY DAMAGES FROM SIMMONS RANA RACING , ITS
                          DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS,
                          CONTRACTORS, AND LICENSORS AS A RESULT OF ITS DECISION
                          TO REMOVE OR REFUSE TO PROCESS ANY INFORMATION OR
                          CONTENT, TO WARN YOU, TO SUSPEND OR TERMINATE YOUR
                          ACCESS TO THE SERVICE, OR TO TAKE ANY OTHER ACTION
                          DURING THE INVESTIGATION OF A SUSPECTED VIOLATION OR
                          AS A RESULT OF SIMMONS RANA RACING CONCLUSION THAT A
                          VIOLATION OF THIS AGREEMENT HAS OCCURRED. THIS WAIVER
                          AND INDEMNITY PROVISION APPLIES TO ALL VIOLATIONS
                          DESCRIBED IN OR CONTEMPLATED BY THIS AGREEMENT.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Changes. Simmons Rana Racing reserves the right, at
                          any time and from time to time, to update, revise,
                          supplement, and otherwise modify this Agreement and to
                          impose new or additional rules, policies, terms, or
                          conditions on your use of the Service. Such updates,
                          revisions, supplements, modifications, and additional
                          rules, policies, terms, and conditions (collectively
                          referred to in this Agreement as “Additional Terms”)
                          will be effective immediately and incorporated into
                          this Agreement. Your continued use of the Website
                          following will be deemed to constitute your acceptance
                          of any and all such Additional Terms. All Additional
                          Terms are hereby incorporated into this Agreement by
                          this reference.System Requirements. Use of the Website
                          requires a compatible device, Internet access (fees
                          may apply), and certain software (fees may apply), and
                          may require obtaining updates or upgrades from time to
                          time. Because use of the Website involves hardware,
                          software, and Internet access, your ability to use the
                          Website may be affected by the performance of these
                          factors. High speed Internet access is strongly
                          recommended. You acknowledge and agree that such
                          system requirements, which may be changed from time to
                          time, are your responsibility. The Website is not part
                          of any other product or offering, and no purchase or
                          obtaining of any other product shall be construed to
                          represent or guarantee you access to the Website.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Notices. Simmons Rana Racing may send you notice with
                          respect to the Website by sending an email message to
                          the email address listed in your Simmons Rana Racing
                          Account contact information, by sending a letter via
                          postal mail to the contact address listed in your
                          Simmons Rana Racing Account contact information, or by
                          a posting on the Website. Notices shall become
                          effective immediately.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Governing Law. The laws of India applicable at
                          Ahmedabad, excluding its conflicts of law rules,
                          govern these Terms and your use of the Service. Your
                          use of the Service may also be subject to other local,
                          state, national, or international laws. You expressly
                          agree that exclusive jurisdiction for any claim or
                          dispute with Simmons Rana Racing or relating in any
                          way to your use of the Service resides in the courts
                          of India applicable at Ahmedabad.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="inner">
                        <p>
                          Miscellaneous. These Terms of Service constitute the
                          entire agreement between you and Simmons Rana Racing
                          and govern your use of the Service, superseding any
                          prior agreements between you and Simmons Rana Racing.
                          You also may be subject to additional terms and
                          conditions that may apply when you use affiliate
                          services, third-party content, or third-party
                          software. If any part of these Terms of Service is
                          held invalid or unenforceable, that portion shall be
                          construed in a manner consistent with applicable law
                          to reflect, as nearly as possible, the original
                          intentions of the parties, and the remaining portions
                          shall remain in full force and effect. Simmons Rana
                          Racing failure to enforce any right or provisions in
                          these Terms of Service will not constitute a waiver of
                          such provision, or any other provision of these Terms
                          of Service. If any provision of this Agreement is
                          found by a court of competent jurisdiction to be
                          invalid, the other provisions will remain in full
                          force and effect. Simmons Rana Racing will not be
                          responsible for failures to fulfill any obligations
                          due to causes beyond its control.
                        </p>
                      </div>
                    </li>
                  </ol>
                </div>
              </Card>
            </div>
          </div>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default TermsAndCondition;
