import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import BottomHeader from "../Component/BottomHeader";
import Delivery from "../Component/Delivery";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import TrustableBrand from "../Component/TrustableBrand";
import AboutIconone from "../assets/img/about-icon1.png";
import AboutIconTwo from "../assets/img/about-icon2.png";
import AboutIconThree from "../assets/img/about-icon3.png";
import AboutIconFoure from "../assets/img/about-icon4.png";
import AboutImgTwo from "./../assets/img/Aboutus3.jpg";
import AboutImg from "./../assets/img/AboutusImg.jpg";
import AboutImgOne from "./../assets/img/aboutUs5.jpg";

const AboutUS = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="About us" SubTitle="About us" />
          <BottomHeader />
          <section className="about-area pt-100 pb-70">
            <div className="container">
              <Card
                style={{
                  padding: "24px",
                  margin: "3px",
                }}
              >
                <Row className=" ">
                  <div className="top">
                    <h2>About Simmons Rana Partnership:</h2>
                    <p>
                      1993- The Introduction- The relation between David Simmons
                      and Rahul Rana dates back to the year 1993. Rahul Rana, by
                      then was already a multiple times National Champion in
                      India and was training with the Muse Brothers Tony, Dante
                      and Mark in Des Moines, Iowa.David Simmons was building up
                      his own company Simmons Racing, which he had launched in
                      1991. Initially, when Inline speed skating took over from
                      conventional Quad skates Rahul faced a lot of issues with
                      his boots. He tried almost every boot maker but none of
                      them met his standards. That is when Dante Muse introduced
                      David Simmons to Rahul Rana. David worked patiently on
                      Rahul's boot till all the various problems were solved and
                      Rahul was comfortable in the boots. Post this Rahul went
                      on to win multiple National Championships on Simmons
                      boots. Since then, Rahul has skated on Simmons Racing
                      boots and nothing else.
                    </p>
                    <p>
                      2013- In 2013 World Championships at Oostende in Belgium,
                      almost 20 years since Rahul had met David for the first
                      time, the Circle of life brought them together. Rahul
                      talked about the need to have a stock boot which was of
                      the finest quality and very close to the custom boots
                      manufactured by David.
                    </p>
                  </div>
                  <Col lg="6">
                    <div className="about-content">
                      <div className="top">
                        <p>
                          The passion that both these individuals shared for
                          this subject was eminent while they conversed almost
                          every day for the next 12 days in Oostende. Both had a
                          single goal in mind, to make the highest quality of
                          Inline speed skating boot the world had ever seen. For
                          the next one whole year both kept pondering about the
                          idea and how to go about manufacturing the boots. Once
                          David was absolutely confident that he and Rahul
                          shared similar work ethics, he decided to impart the
                          technological knowledge he had gathered over the last
                          23 years of manufacturing the finest racing boots this
                          planet had seen. This was a big step for David because
                          for the first time he would be sharing all his
                          Innovations and designs, which he had developed to
                          make these boots, with someone other than Jennifer
                          Simmons, his life partner. He had worked on his own to
                          create something for 23 years and all of a sudden he
                          had to share all this knowledge with Rahul. But it was
                          time to move on and the couple took a big leap in
                          trusting Rahul Rana and that is how SIMMONS RANA WAS
                          BORN.
                        </p>
                        <p>
                          Since its inception both of them have worked
                          tirelessly as team, for more than a year, to produce
                          boots in India with the same technology and precision
                          that David has been using for building his custom
                          boots. Rahul trained under the expert eye of David
                          Simmons, for almost a year before rolling out the
                          first pair of SIMMONS RANA RACING boots from the state
                          of the art manufacturing unit set up in India.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="about-img">
                      <img src={AboutImg} alt="About" />
                      <img src={AboutImgOne} alt="About" />
                      <img src={AboutImgTwo} alt="About" />
                    </div>
                  </Col>
                  {/* <div className="middle">
                    <ul style={{ display: "flex", listStyle: "none" }}>
                      <li>
                        <img src={AboutIconone} alt="Icon" />
                        <h3>25+ Years Experience</h3>
                      </li>
                      <li>
                        <img src={AboutIconTwo} alt="Icon" />
                        <h3>Trusted Brand In City</h3>
                      </li>
                      <li>
                        <img src={AboutIconThree} alt="Icon" />
                        <h3>32+ Award Winner</h3>
                      </li>
                      <li>
                        <img src={AboutIconFoure} alt="Icon" />
                        <h3>Best Quality Brand</h3>
                      </li>
                    </ul>
                  </div> */}
                </Row>
              </Card>
            </div>
          </section>
          <TrustableBrand />
          <Delivery />
          {/* <ClientTestimal /> */}
          <Subscribe />
          <Footer />

          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default AboutUS;