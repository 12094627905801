import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Puff } from "react-loader-spinner";
import * as Yup from "yup";
import BottomHeader from "../Component/BottomHeader";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";

const DealerApllication = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  const schema = Yup.object().shape({
    title1: Yup.string().required("This field is required"),
    title2: Yup.string().required("This field is required"),
    title3: Yup.string().required("This field is required"),
    title4: Yup.string().required("This field is required"),
    title5: Yup.string().required("This field is required"),
    title6: Yup.string().required("This field is required"),
    title7: Yup.string().required("This field is required"),
    title8: Yup.string().required("This field is required"),
    title9: Yup.string().required("This field is required"),
    title10: Yup.string().required("This field is required"),
    title11: Yup.string().required("This field is required"),
    title12: Yup.string().required("This field is required"),
    title13: Yup.string().required("This field is required"),
    title14: Yup.string().required("This field is required"),
    title15: Yup.string().required("This field is required"),
    title16: Yup.string().required("This field is required"),
    title17: Yup.string().required("This field is required"),
    title18: Yup.string().required("This field is required"),
    title19: Yup.string().required("This field is required"),
    title20: Yup.string().required("This field is required"),
    title21: Yup.string().required("This field is required"),
    title22: Yup.string().required("This field is required"),
    title23: Yup.string().required("This field is required"),
    title24: Yup.string().required("This field is required"),
    title25: Yup.string().required("This field is required"),
    title26: Yup.string().required("This field is required"),
    title27: Yup.string().required("This field is required"),
    title28: Yup.string().required("This field is required"),
    title29: Yup.string().required("This field is required"),
    title30: Yup.string().required("This field is required"),
    title31: Yup.string().required("This field is required"),
    title32: Yup.string().required("This field is required"),
    title33: Yup.string().required("This field is required"),
    title34: Yup.string().required("This field is required"),
    title35: Yup.string().required("This field is required"),
    title36: Yup.string().required("This field is required"),
    title37: Yup.string().required("This field is required"),
    title38: Yup.string().required("This field is required"),
    title39: Yup.string().required("This field is required"),
    title40: Yup.string().required("This field is required"),
    title41: Yup.string().required("This field is required"),
    title42: Yup.string().required("This field is required"),
    title43: Yup.string().required("This field is required"),
    title44: Yup.string().required("This field is required"),
    title45: Yup.string().required("This field is required"),
  });
  const PersonalDetailsSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is  required ")
      .email("Invalid email format"),
    name: Yup.string().required("Name is  required"),
    address: Yup.string().required("Address is  required"),
    city: Yup.string().required("City is  required"),
    state: Yup.string().required("State and Province is  required"),
    country: Yup.string().required("Country is  required"),
    pinCode: Yup.string().required("Zip code is  required"),
    phoneNo: Yup.string()
      .required("Phone number  is  required ")
      .min(10, "Phone number must be at least 10 characters")
      .max(12, "Phone number must be at least 12 characters"),
  });
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="Dealer Application" SubTitle="Dealer Application" />
          <BottomHeader />
          <Container className="dealerContainer">
            <Row>
              <Col lg="12">
                <h2 className="aplicationTitle">Dealer Application</h2>
                <Formik
                  validationSchema={schema}
                  initialValues={{
                    title1: "",
                    title2: "",
                    title3: "",
                    title4: "",
                    title5: "",
                    title6: "",
                    title7: "",
                    title8: "",
                    title9: "",
                    title10: "",
                    title11: "",
                    title12: "",
                    title13: "",
                    title14: "",
                    title15: "",
                    title16: "",
                    title17: "",
                    title18: "",
                    title19: "",
                    title20: "",
                    title21: "",
                    title22: "",
                    title23: "",
                    title24: "",
                    title25: "",
                    title26: "",
                    title27: "",
                    title28: "",
                    title29: "",
                    title30: "",
                    title31: "",
                    title32: "",
                    title33: "",
                    title34: "",
                    title35: "",
                    title36: "",
                    title37: "",
                    title38: "",
                    title39: "",
                    title40: "",
                    title41: "",
                    title42: "",
                    title43: "",
                    title44: "",
                    title45: "",
                  }}
                  onSubmit={(values) => {
                    // Alert the input values of the form that we filled
                    alert(JSON.stringify(values));
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <div className="login">
                      <div className="form">
                        {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                        <form noValidate onSubmit={handleSubmit}>
                          <Row>
                            <Col lg="12">
                              <h6>
                                1. Country, Territory or Area you are interested
                                in selling Simmons Rana Products
                              </h6>
                              <input
                                type="text"
                                name="title1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title1}
                                className="form-control inp_text"
                                id="title1"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title1 &&
                                  touched.title1 &&
                                  errors.title1}
                              </p>
                            </Col>
                            <Col lg="12">
                              <h6>
                                2. Current Business Activity (What do you do?)
                              </h6>
                              <input
                                type="text"
                                name="title2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title2}
                                className="form-control inp_text"
                                id="email"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title2 &&
                                  touched.title2 &&
                                  errors.title2}
                              </p>
                            </Col>
                            <Col lg="12">
                              <h6>
                                3. Products / Companies of Speed Skating
                                manufacturers you are currently dealing with
                              </h6>
                              <input
                                type="text"
                                name="title3"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title3}
                                className="form-control inp_text"
                                id="title3"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title3 &&
                                  touched.title3 &&
                                  errors.title3}
                              </p>
                            </Col>
                            <Col lg="12">
                              <h6>
                                4. Speed skating equipment sales market for
                                Country/Territory you are proposing for (In US
                                Dollars)
                              </h6>
                              <input
                                type="email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title4}
                                className="form-control inp_text"
                                id="title4"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title4 &&
                                  touched.title4 &&
                                  errors.title4}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <h6>
                              5. List of major competitors with names of
                              Companies/ Stores/ Organizations
                            </h6>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title5"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title5}
                                className="form-control inp_text"
                                placeholder="1"
                                id="title5"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title5 &&
                                  touched.title5 &&
                                  errors.title5}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title6"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                className="form-control inp_text"
                                placeholder="2"
                                id="title6"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title6 &&
                                  touched.title6 &&
                                  errors.title6}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title7"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title7}
                                className="form-control inp_text"
                                placeholder="3"
                                id="title7"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title7 &&
                                  touched.title7 &&
                                  errors.title7}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title8"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title8}
                                className="form-control inp_text"
                                placeholder="4"
                                id="title8"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title8 &&
                                  touched.title8 &&
                                  errors.title8}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title9"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title9}
                                className="form-control inp_text"
                                placeholder="5"
                                id="title9"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title9 &&
                                  touched.title9 &&
                                  errors.title9}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title9"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title9}
                                className="form-control inp_text"
                                placeholder="6"
                                id="title9"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title9 &&
                                  touched.title9 &&
                                  errors.title9}
                              </p>
                            </Col>
                          </Row>
                          <Table striped bordered hove responsive>
                            <h6>6. SWOT</h6>
                            <tbody>
                              <tr>
                                <td>
                                  <h6>Strength</h6>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title10"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title10}
                                    className="form-control inp_text"
                                    placeholder="1"
                                    id="title10"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title10 &&
                                      touched.title10 &&
                                      errors.title10}
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title11"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title11}
                                    className="form-control inp_text"
                                    placeholder="2"
                                    id="title11"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title11 &&
                                      touched.title11 &&
                                      errors.title11}
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title12"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title11}
                                    className="form-control inp_text"
                                    placeholder="3"
                                    id="title11"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title12 &&
                                      touched.title12 &&
                                      errors.title12}
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title13"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title12}
                                    className="form-control inp_text"
                                    placeholder="4"
                                    id="title12"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title12 &&
                                      touched.title12 &&
                                      errors.title12}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Weakness</h6>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title13"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title13}
                                    className="form-control inp_text"
                                    placeholder="1"
                                    id="title13"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title13 &&
                                      touched.title13 &&
                                      errors.title13}
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title14"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title14}
                                    className="form-control inp_text"
                                    placeholder="2"
                                    id="title14"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title14 &&
                                      touched.title14 &&
                                      errors.title14}
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title15"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title15}
                                    className="form-control inp_text"
                                    placeholder="3"
                                    id="title15"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title15 &&
                                      touched.title15 &&
                                      errors.title15}
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title16"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title16}
                                    className="form-control inp_text"
                                    placeholder="3"
                                    id="title16"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title16 &&
                                      touched.title16 &&
                                      errors.title16}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Opportunities</h6>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title17"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title17}
                                    className="form-control inp_text"
                                    placeholder="1"
                                    id="title17"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title17 &&
                                      touched.title17 &&
                                      errors.title17}
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title18"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title18}
                                    className="form-control inp_text"
                                    placeholder="2"
                                    id="title18"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title18 &&
                                      touched.title18 &&
                                      errors.title18}
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title19"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title19}
                                    className="form-control inp_text"
                                    placeholder="3"
                                    id="title19"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title19 &&
                                      touched.title19 &&
                                      errors.title19}
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title20"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title20}
                                    className="form-control inp_text"
                                    placeholder="4"
                                    id="title20"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title20 &&
                                      touched.title20 &&
                                      errors.title20}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h6>Threats</h6>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title21"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title21}
                                    className="form-control inp_text"
                                    placeholder="1"
                                    id="title21"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title21 &&
                                      touched.title21 &&
                                      errors.title21}
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title22"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title22}
                                    className="form-control inp_text"
                                    placeholder="2"
                                    id="title22"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title22 &&
                                      touched.title22 &&
                                      errors.title22}
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title23"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    className="form-control inp_text"
                                    placeholder="3"
                                    id="title23"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title23 &&
                                      touched.title23 &&
                                      errors.title23}
                                  </p>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="title24"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title24}
                                    className="form-control inp_text"
                                    placeholder="4"
                                    id="title24"
                                  />
                                  {/* If validation is not passed show errors */}
                                  <p className="error">
                                    {errors.title24 &&
                                      touched.title24 &&
                                      errors.title24}
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <h6>7. Your Major Selling Points.</h6>
                          <Table striped bordered hover responsive>
                            <tbody>
                              <tr>
                                <td>
                                  <h6>Rinks</h6>
                                </td>
                                <td>
                                  <input type="checkBox" />
                                </td>
                                <td>
                                  {" "}
                                  <h6>Distributors</h6>
                                </td>
                                <td>
                                  <input type="checkBox" />
                                </td>
                                <td>
                                  <h6>Coaches</h6>
                                </td>
                                <td>
                                  <input type="checkBox" />
                                </td>
                                <td>
                                  <h6>Shops</h6>
                                </td>
                                <td>
                                  <input type="checkBox" />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <Row>
                            <Col lg="12">
                              <h6>
                                8. Your company history, strategy, direction or
                                anything else to help us to understand you or
                                your Company.
                              </h6>
                              <input
                                type="text"
                                name="title25"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title25}
                                className="form-control inp_text"
                                id="title25"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title25 &&
                                  touched.title25 &&
                                  errors.title25}
                              </p>
                            </Col>
                            <h6>9. Last 3 years Sales history, Gross Sales</h6>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title26"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title26}
                                className="form-control inp_text"
                                placeholder="1"
                                id="title26"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title26 &&
                                  touched.title26 &&
                                  errors.title26}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title27"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title27}
                                className="form-control inp_text"
                                placeholder="2"
                                id="title27"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title27 &&
                                  touched.title27 &&
                                  errors.title27}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title28"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title28}
                                className="form-control inp_text"
                                placeholder="3"
                                id="title28"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title28 &&
                                  touched.title28 &&
                                  errors.title28}
                              </p>
                            </Col>
                            <h6>10. Target Markets/ Specific Customers</h6>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title29"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title29}
                                className="form-control inp_text"
                                placeholder="1"
                                id="title29"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title29 &&
                                  touched.title29 &&
                                  errors.title29}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title30"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title30}
                                className="form-control inp_text"
                                placeholder="2"
                                id="title30"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title30 &&
                                  touched.title30 &&
                                  errors.title30}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title31"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title31}
                                className="form-control inp_text"
                                placeholder="3"
                                id="title31"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title31 &&
                                  touched.title31 &&
                                  errors.title31}
                              </p>
                            </Col>
                            <Col lg="12">
                              <h6>
                                11. Sales Plan- How much Simmons Rana Products
                                you will sell per year (In Dollars)
                              </h6>
                              <input
                                type="text"
                                name="title32"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title32}
                                className="form-control inp_text"
                                id="title32"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title32 &&
                                  touched.title32 &&
                                  errors.title32}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <h6>
                              12. Yearly Calendar events according to month in
                              your proposed country, territory or region.
                            </h6>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title33"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title33}
                                className="form-control inp_text"
                                placeholder="1"
                                id="title33"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title33 &&
                                  touched.title33 &&
                                  errors.title33}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title34"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title34}
                                className="form-control inp_text"
                                placeholder="2"
                                id="title34"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title34 &&
                                  touched.title34 &&
                                  errors.title34}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title35"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title35}
                                className="form-control inp_text"
                                placeholder="3"
                                id="title35"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title35 &&
                                  touched.title35 &&
                                  errors.title35}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title36"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title36}
                                className="form-control inp_text"
                                placeholder="4"
                                id="title36"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title36 &&
                                  touched.title36 &&
                                  errors.title36}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title37"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title37}
                                className="form-control inp_text"
                                placeholder="5"
                                id="title37"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title37 &&
                                  touched.title37 &&
                                  errors.title37}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title38"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title38}
                                className="form-control inp_text"
                                placeholder="6"
                                id="title38"
                              />

                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title38 &&
                                  touched.title38 &&
                                  errors.title38}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title39"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title39}
                                className="form-control inp_text"
                                placeholder="7"
                                id="title39"
                              />

                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title39 &&
                                  touched.title39 &&
                                  errors.title39}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title40"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                className="form-control inp_text"
                                placeholder="8"
                                id="title40"
                              />

                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title40 &&
                                  touched.title40 &&
                                  errors.title40}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title41"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title41}
                                className="form-control inp_text"
                                placeholder="9"
                                id="title41"
                              />

                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title41 &&
                                  touched.title41 &&
                                  errors.title41}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title42"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title42}
                                className="form-control inp_text"
                                placeholder="10"
                                id="title42"
                              />

                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title42 &&
                                  touched.title42 &&
                                  errors.title42}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title43"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title43}
                                className="form-control inp_text"
                                placeholder="11"
                                id="title43"
                              />

                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title43 &&
                                  touched.title43 &&
                                  errors.title43}
                              </p>
                            </Col>
                            <Col lg="4">
                              <input
                                type="text"
                                name="title44"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title44}
                                className="form-control inp_text"
                                placeholder="12"
                                id="title44"
                              />

                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title44 &&
                                  touched.title44 &&
                                  errors.title44}
                              </p>
                            </Col>
                            <Col lg="12">
                              <h6>
                                13. Warehousing and distribution capabilities.
                              </h6>
                              <div>
                                <span>Yes</span> <input type="checkBox" />{" "}
                                <span>No</span> <input type="checkBox" />
                              </div>
                            </Col>
                            <Col lg="12">
                              <h6>If yes give some details</h6>
                              <input
                                type="text"
                                name="title45"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title45}
                                className="form-control inp_text"
                                id="title45"
                              />

                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.title45 &&
                                  touched.title45 &&
                                  errors.title45}
                              </p>
                            </Col>
                          </Row>
                          {/* Click on submit button to submit the form */}
                          <button className="common-btn two" type="submit">
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                  )}
                </Formik>
              </Col>
              <Col lg="12">
                <h2 className="aplicationTitleOne">Personal Details</h2>

                <Formik
                  validationSchema={PersonalDetailsSchema}
                  initialValues={{
                    email: "",
                    name: "",
                    address: "",
                    city: "",
                    state: "",
                    country: "",
                    pinCode: "",
                    phoneNo: "",
                  }}
                  onSubmit={(values) => {
                    // Alert the input values of the form that we filled
                    alert(JSON.stringify(values));
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <div className="login">
                      <div className="form">
                        {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                        <form noValidate onSubmit={handleSubmit}>
                          <Row>
                            <Col lg="6">
                              <input
                                type="text"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                placeholder="Name"
                                className="form-control inp_text"
                                id="name"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.name && touched.name && errors.name}
                              </p>
                            </Col>
                            <Col lg="6">
                              <input
                                type="email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                placeholder="Enter email id / username"
                                className="form-control inp_text"
                                id="email"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.email && touched.email && errors.email}
                              </p>
                            </Col>
                            <Col lg="6">
                              {" "}
                              <input
                                type="text"
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address}
                                placeholder="Address"
                                className="form-control inp_text"
                                id="address"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.address &&
                                  touched.address &&
                                  errors.address}
                              </p>
                            </Col>
                            <Col lg="6">
                              {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                              <input
                                type="text"
                                name="city"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.city}
                                placeholder="City"
                                className="form-control inp_text"
                                id="city"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.city && touched.city && errors.city}
                              </p>
                            </Col>
                            <Col lg="6">
                              {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                              <input
                                type="text"
                                name="state"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.state}
                                placeholder="State Province"
                                className="form-control inp_text"
                                id="state"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.state && touched.state && errors.state}
                              </p>
                            </Col>
                            <Col lg="6">
                              {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                              <input
                                type="text"
                                name="country"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.country}
                                placeholder="Country"
                                className="form-control inp_text"
                                id="country"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.country &&
                                  touched.country &&
                                  errors.country}
                              </p>
                            </Col>{" "}
                            <Col lg="6">
                              {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                              <input
                                type="text"
                                name="pinCod"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.pinCode}
                                placeholder="Zip code"
                                className="form-control inp_text"
                                id="pinCod"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.pinCode &&
                                  touched.pinCode &&
                                  errors.pinCode}
                              </p>
                            </Col>
                            <Col lg="6">
                              {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                              <input
                                type="text"
                                name="phoneNo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phoneNo}
                                placeholder="Phone"
                                className="form-control inp_text"
                                id="city"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.phoneNo &&
                                  touched.phoneNo &&
                                  errors.phoneNo}
                              </p>
                            </Col>
                          </Row>
                          {/* Click on submit button to submit the form */}
                          <button className="common-btn two" type="submit">
                            Send Now
                          </button>
                        </form>
                      </div>
                    </div>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default DealerApllication;
