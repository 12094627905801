import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BiDollar } from "react-icons/bi";

import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

function ConformOrderModal({handleCheakout}) {

  const cartData = useSelector((state) => state.cart);
  const currency = useSelector((state)=>state.selectedCurrency)

  const totalOfCartItems = useSelector((state) => state.cartTotal)

  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState("none");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const options = [
    { value: "none", label: "Choise Title" },
    {
      value: "Primary",
      label: "Primary",
    },
    {
      value: "Secondary",
      label: "Secondary",
    },
  ];
  const handleTypeSelect = (e) => {
    setSelectedOption(e.value);
  };


  return (
    <>
      <button
        data-bs-toggle="modal"
        data-bs-target="#contact-add"
        onClick={handleShow}
        className="checkOutBtn"
      >
        continue
      </button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Confirm Your Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Row style={{ border: "1px solid gray", background: "#7b7a7a5c" }}>
              <Col className="tbaleBorder" lg={1}>
                <div style={{ padding: "7px" }}>
                  <h6 className="subTitle">Sr.No</h6>
                </div>
              </Col>
              <Col className="tbaleBorder" lg={4}>
                <div style={{ padding: "7px" }}>
                  <h6 className="subTitle">Description</h6>
                </div>
              </Col>
              <Col className="tbaleBorder" lg={3}>
                <div style={{ padding: "7px" }}>
                  <h6 className="subTitle">Product Price</h6>
                </div>
              </Col>
              <Col className="tbaleBorder" lg={2}>
                <div style={{ padding: "7px" }}>
                  <h6 className="subTitle">Quantity</h6>
                </div>
              </Col>
              <Col lg={2}>
                <div style={{ padding: "7px" }}>
                  <h6 className="subTitle">Sub Total</h6>
                </div>
              </Col>
            </Row>
            {cartData
                  ? cartData.map((item, index) => (
            <Row className="tableRow" key={index}>
              <Col className="tbaleBorder" lg={1}>
                1
              </Col>
              <Col className="tbaleBorder" lg={4}>
                
                <h6>
                {item.ProductName}
                </h6>
              </Col>
              <Col className="tbaleBorder" lg={3}>
                <div>
                {currency.symbol}
                                  {(item.MRP* currency.value).toFixed(2)}
                </div>
              </Col>
              <Col className="tbaleBorder" lg={2}>
                <div>
                {item.Qty ? item.Qty : 0}
                </div>
              </Col>
              <Col lg={2}>
                <div>
                {`${currency.symbol}${item.Qty*(item.MRP* currency.value).toFixed(2)}`}
                </div>
              </Col>
            </Row>
            ))
            : null} 

            <Row className="tableRow" >
              <Col className="tbaleBorder" lg={10}>
                <div>
                  <h6 style={{ textAlign: "end" }}>Sub-Total:</h6>
                </div>
              </Col>
              <Col lg={2}>
                <div>
                  <div>
                    {`${currency.symbol} ${(totalOfCartItems* currency.value).toFixed(2)}`}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="tableRow">
              <Col className="tbaleBorder" lg={10}>
                <div>
                  <h6 style={{ textAlign: "end" }}>
                    Weight Based Shipping (+) (Weight: 1.600) - Rs 110.00 ( 2 to
                    3 working days delivery ):
                  </h6>
                </div>
              </Col>
              <Col lg={2}>
                <div>
                  <div>
                    <BiDollar /> 110.00
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="tableRow">
              <Col className="tbaleBorder" lg={10}>
                <div>
                  <h6 style={{ textAlign: "end" }}>Discount Value (-) :</h6>
                </div>
              </Col>
              <Col lg={2}>
                <div>
                  <div>
                    <BiDollar /> 00.00
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="tableRow">
              <Col className="tbaleBorder" lg={10}>
                <div>
                  <h6 style={{ textAlign: "end" }}>Gift Voucher (-) :</h6>
                </div>
              </Col>
              <Col lg={2}>
                <div>
                  <div>
                    <BiDollar /> 00.00
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="tableRow">
              <Col className="tbaleBorder" lg={10}>
                <div>
                  <h6 style={{ textAlign: "end" }}>Total:</h6>
                </div>
              </Col>
              <Col lg={2}>
                <div>
                  <div>
                    <BiDollar /> 610.00
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Modal.Footer>
              <Button className="checkOutBtn" onClick={()=>{handleCheakout()}}>Confirm Order</Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ConformOrderModal;
