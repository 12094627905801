import React, { useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import AddToCart from "./addTocart";

import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../helpers/backen_helper";
import noImage from "./../assets/img/no-image.png";


const ProductCard = ({
  imageSource,
  productTitle,
  price,
  ProductColorId,
  ProductGroupSizeId,
  productId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [navigateTologin, SetNavigateTologin] = useState(false)

  const notify = (message, icon) =>
    toast(message, {
      duration: 2000,
      position: "top-right",
      style: {},
      className: "",
      icon: icon,
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });

    const validSegment = (string) => {
      if (!string || typeof string !== 'string') {
        return 'sr';
      }
  
      const newString = string
        .replace(/[^a-zA-Z0-9\s]/g, '')
        .replace(/\s+/g, '-')
        .toLowerCase();
  
      return newString; // Return the modified string
    };

  const currency = useSelector((state) => state.selectedCurrency);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };
  const handleMouseEnter = (event) => {
    event.target.style.transform = "scale(1.2)";
  };

  const handleMouseLeave = (event) => {
    event.target.style.transform = "scale(1)";
  };

  const handleImageError = (event) => {
    event.target.src = noImage;
  };

  return (
    <>
      <Toaster></Toaster>

      {navigateTologin ? <Navigate to='/login' /> : null}
      <div className="products-item">
        <div className="top">
          {!imageLoaded && (
            <div className="image-preloader">
              <h4 className="loading-text">
                Loading<span className="dot-1">.</span>
                <span className="dot-2">.</span>
                <span className="dot-3">.</span>
              </h4>
            </div>
          )}
          <Link
            to={`/product-details/${productTitle
              ? validSegment(productTitle)
              : null
              }/${productId}/${ProductColorId}/${ProductGroupSizeId}`}
          >
            <img
              src={`${process.env.REACT_APP_BASE_URL}/${imageSource}`}
              alt="Products"
              onLoad={handleImageLoaded}
              style={{}}
              className="imgProduct"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onError={handleImageError}

            />
          </Link>
          <div className="inner">
            <h3>
              <Link to={`/product-details/${productTitle
              ? validSegment(productTitle)
              : null
              }/${productId}/${ProductColorId}/${ProductGroupSizeId}`}> {productTitle}</Link>
            </h3>
            <span>{`${currency.currency} ${currency.symbol} ${(
              price * currency.value
            ).toFixed(2)}`}</span>
          </div>
        </div>

      </div>
    </>
  );
};

export default ProductCard;
