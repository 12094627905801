import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import defaultImage from './../assets/img/BGIMAGE.jpg'
function SliderTwo({ Title, SubTitle }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get('Name');

 
  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    // Fetch data from your API
    axios.get(`${process.env.REACT_APP_BASE_URL}/categorybannerMaster/get-category-banner-master`)
      .then(response => {
        // Assuming response.data is an array of objects containing background image URLs
        const filteredData = response.data.filter(item => item.BannerTitle === name && item.IsActive);
        if (filteredData.length > 0) {
          const imageUrl = filteredData[0].BannerImage;
          setBackgroundImage(imageUrl); // Set the background image URL
        } else {    
          console.warn("No matching data found for", name);
        }
      
      
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
      
  }, [name]); // Fetch data whenever the 'name' parameter changes
  return (
    <div>
      {" "}
      <Button variant="outlined" className="back-btn-1" sx={{position:"absolute",top:"100px",zIndex:"999999",left:"10px",'@media (min-width:769px)': {display: 'none'}, color:"white", border:"1px solid white"}}><Link to={-1}><KeyboardBackspaceIcon sx={{fontSize:"30px",'&:hover':{color:"#1976d2"}}}/></Link></Button>
      <div>
        <div className="page-title-area"  style={{backgroundImage: `url("${backgroundImage ? process.env.REACT_APP_BASE_URL + '/' + backgroundImage : `${defaultImage}`}")`}}


>
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="title-content">
                  <h2>{Title}</h2>
                 
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>{SubTitle}</span>
                    </li>
                  </ul>
                  <br />
                  <Button variant="outlined" className="back-btn-2" sx={{'@media (max-width:768px)': {display: 'none'}, color:"white", border:"1px solid white"}}><Link to={-1}><KeyboardBackspaceIcon sx={{fontSize:"30px",'&:hover':{color:"#1976d2"}}}/></Link></Button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="title-img">
            <img src={AboutImg} alt="About" />
            <img src={Shape16} alt="Shape" />
            <img src={Shape17} alt="Shape" />
            <img src={Shape18} alt="Shape" />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default SliderTwo;
