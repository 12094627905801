import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Puff } from "react-loader-spinner";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import Shape1 from "./../assets/img/shape1.png";
import Shape2 from "./../assets/img/shape2.png";
import axios from "axios";




const NewPassword = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setresponse] = useState(null);
  const navigate = useNavigate();

  

  const {data} = useParams(); 

  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  const schema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      ,
    confPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  return (
    <div>
      {isLoading ? (
        <div className="loader-container">
          <Puff color="#000" height={50} width={50} timeout={0} />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="Forget Password" SubTitle="Forget Password" />
          <div className="user-area ptb-100">
            <div className="container">
              <Formik
                validationSchema={schema}
                initialValues={{
                  password: "",
                  confPassword: "",
                }}
                onSubmit={(values) => {
               
                alert(values.password,values.confPassword)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <div className="login">
                    <div className="form">
                      <div className="user-item">
                        <form noValidate onSubmit={handleSubmit}>
                          <h2>New Password</h2>

                          {response?.message && (
                            <div className="alert alert-success" role="alert">
                              {response.message}
                            </div>
                          )}
                          {response?.error && (
                            <div className="alert alert-danger" role="alert">
                              {response.error}
                            </div>
                          )}

                          <div className="form-group">

                            <div>
                            <label htmlFor="password">Password</label>
                            <input
                              type="password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              placeholder="Enter your password"
                              className="form-control"
                              id="password"
                            />
                            <p className="error">
                              {errors.password &&
                                touched.password &&
                                errors.password}
                            </p>

                            </div>
                            <div className="mt-3">
                            <label htmlFor="confPassword">
                              Confirm Password
                            </label>
                            <input
                              type="password"
                              name="confPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confPassword}
                              placeholder="Confirm your password"
                              className="form-control"
                              id="confPassword"
                            />
                            <p className="error">
                              {errors.confPassword &&
                                touched.confPassword &&
                                errors.confPassword}
                            </p>
                            </div>
                            
                          </div>

                          <button type="submit" className="btn common-btn">
                            Submit Now
                            <img src={Shape1} alt="Shape" />
                            <img src={Shape2} alt="Shape" />
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </div>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </div>
  );
};

export default NewPassword;
