import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import BottomHeader from "../Component/BottomHeader";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import FootImg from "../assets/img/footimg.png";
import FootImg2 from "../assets/img/footimg2.png";
const SemiCustome = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title=" Semi Custom" SubTitle=" Semi Custom" />
          <BottomHeader />
          <Container>
            <Row className="rowSemiCustome">
              <Col lg="6" md="6">
                <div>
                  <img style={{ width: "100%" }} src={FootImg} />
                </div>
              </Col>
              <Col lg="6" md="6">
                <div>
                  <h1>Semi Custom:</h1>
                  <p>
                    Semi- custom stock boot are handmade to order from a common
                    foot shape (last) of your size. We recommend you send a foot
                    tracing by mail and we will match a last to your foot
                    tracing. The last is what we build your boot from.{" "}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="rowSemiCustome">
              <Col lg="6" md="6">
                <h1>Foot Tracing:</h1>
                <p>
                  When doing a foot tracing, hold a normal size pen or pencil
                  straight up and down and trace around both feet For youth
                  boots we can allow for growing room at your request See the
                  video for more
                </p>
              </Col>
              <Col lg="6" md="6">
                {" "}
                <div>
                  <iframe
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen=""
                    className="w-100 mt-3"
                    frameborder="0"
                    height="315"
                    src="https://www.youtube.com/embed/rqo7wt8QEuE"
                    width="100%"
                  ></iframe>
                </div>
              </Col>
            </Row>
            <Row className="rowSemiCustome">
              <Col lg="6" md="6">
                <div>
                  <img style={{ width: "100%" }} src={FootImg2} />
                </div>
              </Col>
              <Col lg="6" md="6">
                <div>
                  <h1>Semi Custom:</h1>
                  <p>
                    Semi- custom stock boot are handmade to order from a common
                    foot shape (last) of your size. We recommend you send a foot
                    tracing by mail and we will match a last to your foot
                    tracing. The last is what we build your boot from.{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default SemiCustome;
