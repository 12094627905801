import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { AiFillDelete } from "react-icons/ai";
import { Puff } from "react-loader-spinner";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import AddToCart from "../Component/addTocart";
import Product from "../assets/img/product.png";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import * as FaIcons from "react-icons/fa";

import {
  addToCart,
  getWishlist,
  removeFromWishlist,
} from "../helpers/backen_helper";
import { Link, useNavigate } from "react-router-dom";
const WishList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const wishlistData = useSelector((state) => state.wishlist);
  const currency = useSelector((state) => state.selectedCurrency);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notify = (message, icon) =>
    toast(message, {
      duration: 2000,
      position: "top-right",
      style: {},
      className: "",
      icon: icon,
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  useEffect(() => {
    setIsLoading(true);

    getWishlist(dispatch);

    setIsLoading(false);
  }, []);

  const handleAddToCart = async (productId, productParameterId) => {
    if (localStorage.getItem("token")) {
      addToCart(productId, productParameterId, dispatch);
      notify("product added to your cart", "✅");
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <>
      <Toaster />
      <div>
        {isLoading ? (
          // Loader component while loading
          <div className="loader-container">
            <Puff
              color="#000"
              height={50}
              width={50}
              timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
            />
          </div>
        ) : (
          <div>
            <HaderTwo />
            <SliderTwo Title="My Wishlist" SubTitle="My Wishlist" />

            <Container>
              <div className="account-card mt-5">
                <div className="account-title"></div>
                <div className="account-content">
                  <div className="table-scroll">
                    <table className="table-list">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Product</th>
                          <th>Price</th>
                          <th>Stock Status</th>
                          <th>Add To Cart</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wishlistData.length > 0 ? (
                          wishlistData.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <img
                                  className="wishListImg"
                                  src={`${process.env.REACT_APP_BASE_URL}/${
                                    item.ProductImages[0]
                                      ? item.ProductImages[0].Image
                                      : null
                                  }`}
                                  alt="img"
                                />
                              </td>
                              <td>{item.ProductName}</td>
                              <td>{`${currency.currency} ${currency.symbol} ${(
                                item.MRP * currency.value
                              ).toFixed(2)}`}</td>
                              <td>In Stock</td>
                              <td>
                                <div className="bottom">
                                  <Link className="cart-text">
                                    <FaIcons.FaShoppingCart
                                      onClick={() => {
                                        handleAddToCart(
                                          item.Product
                                            ? item.Product._id
                                            : null,
                                          item.ProductParameters
                                            ? item.ProductParameters._id
                                            : null
                                        );
                                      }}
                                      style={{ fontSize: "30px" }}
                                    />
                                  </Link>
                                </div>
                              </td>

                              <td
                                onClick={async () => {
                                  const success = await removeFromWishlist(
                                    item._id
                                  );
                                  if (success) {
                                    getWishlist(dispatch);
                                    notify("Item removed from Wishlist.", "🗑️");
                                  }
                                }}
                              >
                                <AiFillDelete style={{ fontSize: "30px" }} />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="empty-wishlist">
                              <div className="empty-wishlist-content">
                                <h3>Your wishlist is empty</h3>
                                <p>
                                  Find something you like and add it to your
                                  wishlist!
                                </p>
                                <Link to="/" style={{ textDecoration: "none" }}>
                                  <button
                                    style={{
                                      border: "none",
                                      color: "white",
                                      cursor: "pointer",
                                      borderRadius: "12px",
                                      padding: "10px 18px",
                                      backgroundColor: "#9abc66",
                                      textShadow:
                                        "0 1px rgba(128, 128, 128, 0.75)",
                                      marginTop: "20px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Continue Shopping
                                  </button>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Container>

            <Subscribe />
            <Footer />
            <ScrollButton />
          </div>
        )}
      </div>
    </>
  );
};

export default WishList;
