import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";

const ReturnPolicy = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <div>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="Return Policy" SubTitle="Return Policy" />
          <div className="return-policy-area ptb-100">
            <div className="container">
              <Card className="temAndCondition">
                <div className="return-policy-item">
                  <h2>When You Can Return Or Exchange A Product</h2>
                  <div className="inner">
                    <h3>1. TERMS OF SALE:</h3>
                    <p>
                      We try to keep all the images on our website up to date,
                      if you have any questions about specific parts please
                      email to sales@simmonsrana.com.
                    </p>
                  </div>
                  <div className="inner">
                    <h3>2. RETURN POLICY:</h3>
                    <p>
                      All sales are final. We allow for exchanges on new and
                      unused items. If you need to make an exchange, please
                      include a copy of your packing slip with a note explaining
                      which item(s) you are exchanging and which item(s) you
                      would like to be sent back to you. Upon receiving your
                      item(s), we will process your exchange. After the
                      exchange, we will send out the new items and we will
                      charge you again for the shipping costs. We do not allow
                      returns for any items. Simmons Rana Racing Pvt. Ltd. will
                      not reimburse you for the cost of the method you choose to
                      ship your exchange back to us. You will receive your
                      exchanged order three to five business days after the
                      exchange has been processed.
                    </p>
                    <p>
                      We will provide you by e-mail with the return address !!
                    </p>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </div>
  );
};

export default ReturnPolicy;
