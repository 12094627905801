import React from "react";
import { BiSolidCategory } from "react-icons/bi";
import { FaChevronDown, FaChevronUp, FaHandshake } from "react-icons/fa";
import { MdHome, MdOutlineMessage } from "react-icons/md";
import { TiContacts } from "react-icons/ti";
import { VscFileMedia } from "react-icons/vsc";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    icon: <MdHome />,
  },
  {
    title: "All Categories",
    path: "#",
    icon: <BiSolidCategory />,
    iconClosed: <FaChevronDown />,
    iconOpened: <FaChevronUp />,

    subNav: [
      {
        title: "Dash Complete Skate",
        path: "/product-list/10002",
      },
      {
        title: "Rush 3.0 Complete Skate",
        path: "/product-list/2",
      },
      {
        title: "Fly Complete Skate",
        path: "/product-list/9",
      },
      {
        title: "Boots",
        path: "/product-list/3",
      },
      {
        title: "Frames",
        path: "/product-list/4",
      },
      {
        title: "Wheeels",
        path: "/product-list/5",
      },
      {
        title: "Bearings",
        path: "/product-list/6",
      },
      {
        title: "Accessories ",
        path: "/product-list/8",
      },
      {
        title: "Helmet ",
        path: "/product-list/10",
      },
    ],
  },

  {
    title: "About Us",
    path: "#",
    icon: <MdOutlineMessage />,
    iconClosed: <FaChevronDown />,
    iconOpened: <FaChevronUp />,

    subNav: [
      {
        title: "Our Story",
        path: "/about-us",
      },
      {
        title: "Innovation",
        path: "/innovation",
      },
    ],
  },
  {
    title: "Media",
    path: "#",
    icon: <VscFileMedia />,
    iconClosed: <FaChevronDown />,
    iconOpened: <FaChevronUp />,

    subNav: [
      {
        title: "Photo Gallery",
        path: "/gallery",

        cName: "sub-nav",
      },
      {
        title: "Video Gallery",
        path: "/video-gallery",

        cName: "sub-nav",
      },
      {
        title: "News & Events",
        path: "/news-and-event",
      },
    ],
  },

  {
    title: "Supports",
    path: "#",
    icon: <FaHandshake />,
    iconClosed: <FaChevronDown />,
    iconOpened: <FaChevronUp />,

    subNav: [
      {
        title: "FAQ",
        path: "/faq",
      },
      {
        title: "Size chart",
        path: "/size-chart",
      },
      {
        title: "Semi Custom",
        path: "/semi-custom",
      },
      {
        title: "Full Custom",
        path: "/full-custome",
      },
      {
        title: "Restoring & Repairing",
        path: "/restoring-reparing",
      },
      {
        title: "Dealer Application",
        path: "/dealer-application",
      },
    ],
  },
  {
    title: "Contact",
    path: "#",
    icon: <TiContacts />,
    iconClosed: <FaChevronDown />,
    iconOpened: <FaChevronUp />,

    subNav: [
      {
        title: "Contact Us",
        path: "/contact-us",
      },
      {
        title: "Global Dealers",
        path: "/global-dealer",
      },
    ],
  },
];
