import React from "react";
import { BiLogoPinterestAlt } from "react-icons/bi";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { HiOutlineLocationMarker, HiOutlineMail } from "react-icons/hi";
import { Link } from "react-router-dom";
import Logo from "./../assets/img/footer-logo.png";
import Payment1 from "./../assets/img/payment.png";
import Shap8 from "./../assets/img/shape8.png";
import Shap9 from "./../assets/img/shape9.png";

import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const Footer = () => {
  const loggedinUserInfo = useSelector((state) => state.loggedinUserInfo);

  return (
    <React.Fragment>
      <div>
        <footer className="footer-area two pt-100 pb-70">
          <div className="footer-shape">
            <img src={Shap9} alt="Shape" />
            <img src={Shap8} alt="Shape" />
          </div>
          <div className="container">
            <div className="row ">
              <div className="col-sm-6 col-lg-4">
                <div className="footer-item">
                  <div className="footer-logo">
                    <Link className="logo" to="/">
                      <img src={Logo} alt="Logo" />
                    </Link>
                    <ul>
                      <li>
                        <i className="flaticon-pin">
                          <HiOutlineLocationMarker />
                        </i>
                        <span>
                          Simmons Rana Racing, 105 Gokuldham Arcade, Sarkhej-
                          Sanand Highway, Sanathal, Ahmedabad- 382210, Gujarat,
                          India.
                        </span>
                        {/* <a to="#">345-659 Birmingham Street, England</a> */}
                      </li>
                      <li>
                        <i className="flaticon-phone-call">
                          <FiPhoneCall />
                        </i>
                        <Link to="tel:(+91) 98253 22522">
                          (+91) 98253 22522
                        </Link>
                        {/* <a to="tel:+5465486325">+546-548-6325</a> */}
                      </li>
                      <li>
                        <i className="flaticon-email">
                          <HiOutlineMail />
                        </i>
                        <Link to="mailto:sales@simmonsrana.com" target="_blank">
                          <span
                            className="__cf_email__"
                            data-cfemail="f199949d9d9eb194929e81df929e9c"
                          >
                            sales@simmonsrana.com
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="footer-item tabletFlex">
                  <div className="footer-services ">
                    <h3>Customer Services</h3>
                    <ul>
                      <li>
                        <Link to="/return-policy">Return Policy</Link>
                      </li>
                      <li>
                        <Link to="/faq">FAQ</Link>
                      </li>

                      <li>
                        <Link to="/termAndConditions">Terms & conditions</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="footer-item">
                  <div className="footer-links">
                    <h3>Important Links</h3>
                    <div className="row">
                      <div className="col-6 col-sm-8 col-lg-8">
                        <ul>
                          <li>
                            <Link to="/about-us">About Us</Link>
                          </li>
                          <li>
                            <Link
                              to={loggedinUserInfo ? "/my-wishList" : "/login"}
                            >
                              Wishlist
                            </Link>
                          </li>
                          <li>
                            <Link to="/innovation">Our Innovation</Link>
                          </li>
                          <li>
                            <Link to={loggedinUserInfo ? "/" : "/login"}>
                              Login
                            </Link>
                          </li>
                          <li>
                            <Link to={loggedinUserInfo ? "/" : "/register"}>
                              Register
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-2">
                <div className="footer-item tabletFlex">
                  <div className="footer-services">
                    <h3>Categories</h3>
                    <ul>
                      <li>
                        <Link to="/product-list/3">Boots</Link>
                      </li>
                      <li>
                        <Link to="/product-list/4">Frames</Link>
                      </li>
                      <li>
                        <Link to="/product-list/5">Wheels</Link>
                      </li>
                      <li>
                        <Link to="/product-list/6">Bearings</Link>
                      </li>
                      <li>
                        <Link to="/product-list/8">Accessories</Link>
                      </li>
                      {/* <li>
                          <Link to="#">Apparels </Link>
                        </li> */}
                      <li>
                        <Link to="/product-list/10">Helmet </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-sm-6 col-lg-6">
                <div className="payment-cards">
                  <ul>
                    <li>
                      <img src={Payment1} alt="Payment" />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-lg-6">
                <div className="social-links">
                  <ul>
                    <li>
                      <Link
                        to="https://www.facebook.com/SimmonsRana"
                        target="_blank"
                        className="iconLinkFacbook"
                      >
                        <i className="bx bxl-facebook">
                          {" "}
                          <FaFacebookF />
                        </i>
                      </Link>
                    </li>
                    <li>
                      <Link className="shareIconTwiter" target="_blank" to="https://twitter.com/racingrana">
                        <i className="bx bxl-twitter">
                          <FaTwitter />
                        </i>
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="https://in.pinterest.com/simmonsranaracing/"
                        target="_blank"
                        className="shareIconPinterest"
                      >
                        <i className="bx bxl-linkedin">
                          <BiLogoPinterestAlt />{" "}
                        </i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.instagram.com/simmonsranaracing/"
                        target="_blank"
                        className="sharIconInstagram"
                      >
                        <i className="bx bxl-linkedin">
                          <FaInstagram />
                        </i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.youtube.com/@simmonsranaracing5226"
                        target="_blank"
                        className="shareIconYoutube"
                      >
                        <i className="bx bxl-youtube">
                          <FaYoutube />{" "}
                        </i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="copyright-area two">
        <div className="container">
          <div className="copyright-item">
            <Row>
              <Col lg="6">
                <p>
                  Copyright © 2023&nbsp;
                  <Link to="https://www.simmonsrana.com/" target="_blank">
                    Simmons Rana&nbsp; Racing
                  </Link>
                  All Right Reserved.
                </p>
              </Col>
              <Col lg="6">
                <p>
                  Design & Developed By :- &nbsp;
                  <Link to="https://www.barodaweb.com/" target="_blank">
                    Barodaweb&nbsp; : The E-Catalogue Designer
                  </Link>
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Footer;
