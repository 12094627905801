import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Puff } from "react-loader-spinner";
import { Link } from "react-router-dom";
import '../css/myProfile.css'

const OrderInvoice = () => {
  const location = useLocation();
  const [invoiceHtml, setInvoiceHtml] = useState('');
  const {id} = useParams()
  useEffect(() => {
    // Function to fetch invoice HTML from URL params
    const fetchInvoice = async () => {
      try {
        const OrderResponse = await axios.get("/orderMaster/getOrderInvoice/"+id);
        setInvoiceHtml(OrderResponse.data)
      } catch (error) {
        console.error("Error fetching invoice:", error);
        return { error: error.message };
      }
    };

    fetchInvoice();
  }, []);

  const handlePrint = () => {
    window.print(); // Function to trigger browser print dialog
  };

  return (
    <div>
      {invoiceHtml ? (
        <>
          <div dangerouslySetInnerHTML={{ __html: invoiceHtml }} />
          <div className="hstack gap-2 justify-content-end d-print-none m-5 ">
              <Link to="#" onClick={handlePrint} className="btn btn-success">
                <i className="ri-printer-line align-bottom me-1"></i> Print
              </Link>
            </div>
        </>
      ) : (
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      )}
    </div>
  );
};

export default OrderInvoice;
