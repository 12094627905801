import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Puff } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import Shape1 from "./../assets/img/shape1.png";
import Shape2 from "./../assets/img/shape2.png";
import axios from "axios";

const ChangPassword = () => {
  const [isLoading, setIsLoading] = useState(true);
  const[response,setresponse] = useState(null)  
  const navigate = useNavigate()

  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address") // Check if it's a valid email format
      .required("Email is required"), // Ensure it's not empty
  });
  return (
    <div>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="Forgot Password
" SubTitle="Forgot Password
" />
          <div className="user-area ptb-100">
            <div className="container">
              <Formik
                validationSchema={schema}
                initialValues={{
                  email: "",
                }}
                onSubmit={(values) => {
                  axios
                    .post(
                      process.env.REACT_APP_BASE_URL +
                        "/customer/forgetPassword",
                      {
                        email: values.email,
                      }
                    ) // Replace with your API endpoint
                    .then((response) => {
                      setresponse(response)
                      navigate("/congo/emailsentforforgetpassword")

                    })
                    .catch((error) => {
                      console.error("Error adding Address: " + error);
                      setresponse({error:"Error adding Address plese Check your email"})
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <div className="login">
                    <div className="form">
                      {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                      <div className="user-item">
                        <form noValidate onSubmit={handleSubmit}>
                          <h2>Forgot Password
</h2>

                          {response?.message && (
                      <div class="alert alert-success" role="alert">
                        {response.message}
                      </div>)}
                      {response?.error && (
                      <div class="alert alert-danger" role="alert">
                        {response.error}
                      </div>)}
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              placeholder="Enter your email"
                              className="form-control"
                              id="email"
                            />
                            {/* If validation is not passed show errors */}
                            <p className="error">
                              {errors.email && touched.email && errors.email}
                            </p>
                          </div>

                          <button type="submit" className="btn common-btn">
                            Submit Now
                            <img src={Shape1} alt="Shape" />
                            <img src={Shape2} alt="Shape" />
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </div>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </div>
  );
};

export default ChangPassword;
