import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Background3 from "./../assets/img/download.png";
import Background1 from "./../assets/img/sale-bg1.png";
import Background2 from "./../assets/img/sale-bg2.png";

const Offer = () => {
  const [sales, setSales] = useState([]);

  useEffect(() => {
    // Make an API request to fetch sales/offers data
    axios
      .get(process.env.REACT_APP_BASE_URL + "/offerMaster/get-offer-master")
      .then((response) => {
        setSales(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="sale-area">
        <div className="container-fluid ">
          <div className="row justify-content-center">
            {sales &&
              sales.map((sale, index) => (
                <div key={index} className={`col-sm-6 col-lg-4 `}>
                  <div
                    className="sale-item sale-bg-two"
                    style={{
                      backgroundImage:
                        index % 3 === 0
                          ? `url(${Background1})`
                          : index % 3 === 1
                          ? `url(${Background2})`
                          : `url(${Background3})`,
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_BASE_URL + "/" + sale.OfferImage
                      }
                      alt="Sale"
                    />
                    <div className="inner">
                      <h3>
                        <span className="percent">{sale.OfferOn + " % "}</span>

                        {"  " + sale.OfferName + " "}
                        <span> Sale</span>
                      </h3>
                      <p>{sale.PromoCode}</p>
                      <Link to="/view-all-offer">Shop Now</Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Offer;
