import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

const CongratulationsAlert = () => {

  const {msg} = useParams(); 
 
  return (
    <html>
      <head>
        <link href="https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,900&display=swap" rel="stylesheet" />
        <style>
          {`
            body {
              text-align: center;
              padding: 40px 0;
              background: #EBF0F5;
            }
            h1 {
              color: #88B04B;
              font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
              font-weight: 900;
              font-size: 40px;
              margin-bottom: 10px;
            }
            p {
              color: #404F5E;
              font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
              font-size: 20px;
              margin: 0;
            }
            i {
              color: #9ABC66;
              font-size: 100px;
              line-height: 200px;
              margin-left: -15px;
            }
            .card {
              background: white;
              padding: 60px;
              border-radius: 4px;
              box-shadow: 0 2px 3px #C8D0D8;
              display: inline-block;
              margin: 0 auto;
            }
          `}
        </style>
      </head>
      <body>
        <div className="card">
          <div style={{ borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: '0 auto' }}>
          {msg === "orderSuccess" && <i className="checkmark">✓</i>}
            {msg === "emailsentforforgetpassword" && <i className="checkmark">✉</i>}
            
            
            
          </div>
        
          { msg === "orderSuccess" && 
          <>  <h1>Success</h1>
          <p>We received your payment<br />Your order is confirmed and will be delivered soon.</p>  </>        
          }
          { msg === "emailsentforforgetpassword" && 
          <p>
          Password reset link has been sent to your email.
          <br />
          Please check your inbox.
        </p>
               
          }
          <Link to="/" style={{ textDecoration: 'none' }}>
        <button style={{ 
          border: 'none',
          color: 'white',
          cursor: 'pointer',
          borderRadius: '12px',
          padding: '10px 18px',
          backgroundColor: '#9abc66',
          textShadow: '0 1px rgba(128, 128, 128, 0.75)',
          marginTop: '20px',
          fontSize: '16px'
        }}>Continue Shopping</button>
      </Link>
        </div>
      </body>
    </html>
  );
};

export default CongratulationsAlert;

