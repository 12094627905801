import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import BottomHeader from "../Component/BottomHeader";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";

const RestoringAndReparing = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo
            Title=" Restoring  Repairing"
            SubTitle=" Restoring  Repairing"
          />
          <BottomHeader />
          <Container>
            <p className="sizeChartContainer">
              The carbon fiber shells last a long time. If you would like to
              restore the outside of your boot to new again, (laces, leather,
              buckle, etc.) or change colors the price is $300.00 To reline
              inside of your boot the price is $35.00
            </p>
          </Container>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default RestoringAndReparing;
