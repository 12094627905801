import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import { Link } from "react-router-dom";
import BottomHeader from "../Component/BottomHeader";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import GalleryImg from "./../assets/img/GalleryImg.jpeg";
import axios from "axios";

const GalleryDetails = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [images, setImages] = useState([]);

  useEffect(()=>{
    let currentUrl = window.location.href;
    currentUrl = currentUrl.split("/");
    currentUrl = currentUrl[currentUrl.length-1];
    console.log("srsrsrsrs",currentUrl);
    axios.get(`${process.env.REACT_APP_BASE_URL}/galleryMaster/get-gallery-category/${currentUrl}`)
    .then((res)=>setImages(res.data))
    .catch((err)=>console.log(err))
  },[])
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title=" Photo Gallery" SubTitle=" Photo Gallery" />
          <BottomHeader />
          <section className="blog-area ptb-100">
            <Container>
              <Row className=" justify-content-center">
                { images.map((item)=><Col lg="4" sm="6">
                  <div className="blog-item">
                    <div className="top">
                      <Link
                        to={`${process.env.REACT_APP_BASE_URL}/${item.GalleryImage}`}
                        target="_blanck"
                      >
                        <img src={`${process.env.REACT_APP_BASE_URL}/${item.GalleryImage}`} alt="Image" />
                      </Link>
                    </div>
                    <div className="bottom"></div>
                  </div>
                </Col>
      )}
              </Row>
            </Container>
          </section>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default GalleryDetails;
