import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { FaMinus, FaPlus } from "react-icons/fa"; // Import the plus and minus icons
import { FaRegHandPointRight } from "react-icons/fa";
import BottomHeader from "../Component/BottomHeader";
import Footer from "../Component/Footer";
import HeaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { addToCart, getCart } from "../helpers/backen_helper";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GrCheckboxSelected } from "react-icons/gr";

const products = [
  {
    id: 1,
    name: "AC 10",
    price: "$849.00",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/607110313de14ab9902a0f498b0b244f.jpeg",
  },
  {
    id: 2,
    name: "Seven9",
    price: "$849.00",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/92a7cb7fff3a4bb3bdd453267bdb07c4.jpeg",
  },
  {
    id: 1,
    name: "AC 10",
    price: "$849.00",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/607110313de14ab9902a0f498b0b244f.jpeg",
  },
  {
    id: 2,
    name: "Seven9",
    price: "$849.00",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/92a7cb7fff3a4bb3bdd453267bdb07c4.jpeg",
  },
  {
    id: 1,
    name: "AC 10",
    price: "$849.00",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/607110313de14ab9902a0f498b0b244f.jpeg",
  },
  {
    id: 2,
    name: "Seven9",
    price: "$849.00",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/92a7cb7fff3a4bb3bdd453267bdb07c4.jpeg",
  },
  {
    id: 1,
    name: "AC 10",
    price: "$849.00",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/607110313de14ab9902a0f498b0b244f.jpeg",
  },
  {
    id: 2,
    name: "Seven9",
    price: "$849.00",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/92a7cb7fff3a4bb3bdd453267bdb07c4.jpeg",
  },
  {
    id: 1,
    name: "AC 10",
    price: "$849.00",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/607110313de14ab9902a0f498b0b244f.jpeg",
  },
  {
    id: 2,
    name: "Seven9",
    price: "$849.00",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/92a7cb7fff3a4bb3bdd453267bdb07c4.jpeg",
  },
  {
    id: 1,
    name: "AC 10",
    price: "$849.00",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/607110313de14ab9902a0f498b0b244f.jpeg",
  },
  {
    id: 2,
    name: "Seven9",
    price: "$849.00",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/92a7cb7fff3a4bb3bdd453267bdb07c4.jpeg",
  },
  // Add more products here
];
const frameProducts = [
  {
    id: 1,
    name: "Rush Dual Configurable Frames 4x90mm / 3x110mm",
    price: "USD $ 220",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/dc482e927a2840618a6b8ddf67e35924.jpeg",
  },
  {
    id: 2,
    name: "SR 125 Frames",
    price: "USD $ 329",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/741d22d7f14846e2ac4a993a4823376f.jpeg",
  },
  {
    id: 3,
    name: "Rush Dual Configurable Frames 4x90mm / 3x110mm",
    price: "USD $ 220",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/815986d4e32d4117b0a2a8fe19847b41.jpeg",
  },
];
const wheelsProducts = [
  {
    id: 1,
    name: "Black Magic 125BM",
    price: "USD $ 33",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/f0b3fc1344a44cf082f780ea5ea46b45.jpeg",
  },
  {
    id: 2,
    name: "Flash 100mm Wheels",
    price: "USD $ 27",
    imageSrc:
      "	https://simmonsrana.com/admin//Upload/ProductImage/2ca65e04937648fa9ccf5e9648637637.jpeg",
  },
  {
    id: 3,
    name: "Flash 110mm Wheels",
    price: "USD $ 39",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/e7284649c8224ee28f95e286acd8a039.jpeg",
  },
];

const bearingsProducts = [
  {
    id: 1,
    name: "SR Swiss Precision Race Bearings",
    price: "USD $ 115",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/d8671e4e1bc6453b93a55bea8cb18adf.jpeg",
  },
  {
    id: 2,
    name: "Black Cobalt Ceramic",
    price: "USD $ 175",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/46591b8f36054e31be5522bd2e5c20d7.jpeg",
  },
];
const finishDesignProducts = [
  {
    id: 1,
    name: "Dash Boots",
    code: "DSB",
    imageSrc:
      "https://simmonsrana.com/admin//Upload/ProductImage/e4777f9cf8104a3a804300a0b5261ab4.jpeg",
  },
];


function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;


  return (
    <div
      onClick={decoratedOnClick}
      className="position-relative d-flex align-items-center fw-bold"
    >
      {/* {isCurrentEventKey ? (
        <FaMinus className="position-absolute pm-icon" />
      ) : (
        <FaPlus className="position-absolute pm-icon" />
      )}{" "} */}
      {children}
    </div>
  );
}

function Example() {

  const loggedinUserInfo = useSelector((state) => state.loggedinUserInfo);

  const [Products, setProducts] = useState([]);
  const [FrameProducts, setFrameProducts] = useState([]);
  const [WheelsProducts, setWheelsProducts] = useState([]);
  const [BearingProducts, setBearingProducts] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [sizeChart, setSizeChart] = useState([]);
  const [bootSize,setBootSize] = useState({});
  const [bootColorList,setBootColorList] = useState([]);
  const [bootColorToggle,setBootColorToggle] = useState(false);
  const [bootColor,setBootColor] = useState({});
  const [loader, setLoader] = useState(false);
  const [note, setNote] = useState({});
  const [count, setCount] = useState(1);
  const [shows, setShows] = useState(false);

  useEffect(()=>{
    console.log("srsrsrsrsrsrs",WheelsProducts)
  },[WheelsProducts,bootColor])

  //  build yout skate

  const [selectedBoot, setSelectedBoot] = useState(null);
  const [selectedFrame, setSelectedFrame] = useState(null);
  const [selectedWheel, setSelectedWheel] = useState(null);
  const [selectedWheel2, setSelectedWheel2] = useState(null);
  const [selectedBearing, setSelectedBearing] = useState(null);
  const notify = (message, icon) =>

  toast(message, {
    duration: 2000,
    position: "top-right",

    // Styling
    style: {},
    className: "",

    // Custom Icon
    icon: icon,

    // Change colors of success/error/loading icon
    iconTheme: {
      primary: "#000",
      secondary: "#fff",
    },

    // Aria
    ariaProps: {
      role: "status",
      "aria-live": "polite",
    },
  });

  useEffect(()=>{
    getNote();
    try{
      const response =  axios.get(
        `https://server.simmonsrana.com/productMaster/get-Specific-Product/10010/20030/46`
      )
      .then((res)=>{
        setSizeChart(res.data.productSizes)
      })
      .catch((err)=>{
        console.log(err)
      })
    }
    catch(error){
      console.error("Error fetching Menu Master data:", error);
    }
  },[])

  const handleSelectBootSize=(event,product)=>{
    setBootSize(event.target.value)
    let sizeid = event.target.value._id;
    let productId = product.ProductId;
    let colorId = product.productParameters.ProductColorId;
    getBootColor(sizeid,productId,colorId)
  }

  const getBootColor=(sizeid,productId,colorId)=>{
    setLoader(true)
    try{
      const response =  axios.get(
        `https://server.simmonsrana.com/productMaster/get-Specific-Product/${productId}/${colorId}/${sizeid}`
      )
      .then((res)=>{
        setBootColorList(res.data.ProductColorImages);
        setBootColorToggle(true);
        setLoader(false);
      })
      .catch((err)=>{
        console.log(err)
        setLoader(false);
      })
    }
    catch(error){
      console.error("Error fetching Menu Master data:", error);
      setLoader(false);
    }
  }

  const handleSelectBootColor=(event)=>{
    setBootColor(event.target.value)
  }

  const getNote = async()=>{
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/notemaster/get-note-master`
      );
      setNote(response.data[0]);
      console.log("okokokokokok",response.data[0]);
    } catch (error) {
      console.error("Error fetchig data");
    }
  }

  const getFrameList=async()=>{
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/buildSkateMaster/get-skate-BySize`,{selectedBoot}
      );
      setFrameProducts(response[0].Frames);
      setBearingProducts(response[0].Bearings)
    } catch (error) {
      console.error("Error fetchig data");
    }
  }

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/productMaster/get-product-by-groupid/3`
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetchig data");
    }
  };

  // const getFrameProducts = async () => {
  //   try {
  //     const frameresponse = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/productMaster/get-product-by-groupid/4`
  //     );
  //     setFrameProducts(frameresponse.data);
  //   } catch (error) {
  //     console.error("Error fetchig data");
  //   }
  // };
  // const getWheelsProducts = async () => {
  //   try {
  //     const wheelsresponse = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/productMaster/get-product-by-groupid/5`
  //     );
  //     setWheelsProducts(wheelsresponse.data);
  //   } catch (error) {
  //     console.error("Error fetchig data");
  //   }
  // };
  // const getBearingProducts = async () => {
  //   try {
  //     const bearingresponse = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/productMaster/get-product-by-groupid/6`
  //     );
  //     setBearingProducts(bearingresponse.data);
  //   } catch (error) {
  //     console.error("Error fetchig data");
  //   }
  // };

  const [openTab, setOpenTab] = useState("1");

  useEffect(() => {
    setisLoading(true);
    getProducts();
    // getFrameProducts();
    // getWheelsProducts();
    // getBearingProducts();
    setisLoading(false);
  }, []);

  const [finalProduct, setFinalProduct] = useState([]);

  const updateProductList = () => {
    console.log("srsrsrsrsrsrsrrrrrrrrrrrrrrrrrrrrrrrrrrrr",selectedBoot.product)
    const pro = [];
    pro.push(selectedBoot.product);
    pro.push(selectedFrame);
    pro.push(selectedWheel);
    if(selectedWheel2)
    {
    pro.push(selectedWheel2);
    }
    pro.push(selectedBearing);
    setFinalProduct(pro);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addTocartFinalProduct = () => {
    if(finalProduct.length == 4)
    {
      finalProduct.map((i,index) => {
        let pParameter = i.productParameters ? i.productParameters.ProductParametersId : i.ProductParametersId;
        if(index==2)
        {
        addToCart(i.ProductId, pParameter,dispatch,8);
        }
        else
        {
        addToCart(i.ProductId, pParameter, dispatch, 1);
        }
      });
    }
    else
    {
      finalProduct.map((i,index) => {
        let pParameter = i.productParameters ? i.productParameters.ProductParametersId : i.ProductParametersId;
        if(index==2)
        {
        addToCart(i.ProductId, pParameter,dispatch,2);
        }
        else if(index==3)
        {
        addToCart(i.ProductId, pParameter,dispatch,6);
        }
        else
        {
        addToCart(i.ProductId, pParameter,dispatch,1);
        }
      });
    }
    notify("selected product are now in your cart", "✅");
    getCart(dispatch);
    navigate("/my-cart")
  };
    function finishSket(){

    if( loggedinUserInfo ){
      // addTocartFinalProduct();
      console.log("okokokokokoko")
    }{
      navigate("/login")
    }
  }

  function finishSket() {
  console.log(finalProduct);
    if( loggedinUserInfo !== null ){
      addTocartFinalProduct() 
    }else{
      navigate("/login")
    }

  }

  const getUpdatedProduct=async(data)=>{
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/buildSkateMaster/get-product-parameter`,{data}
      );
      return response;
    } catch (error) {
      console.error("Error fetchig data");
    }
  }



  return (
    <>
      <Toaster />
      <SliderTwo Title="Build Your Skate" SubTitle="Build Your Skate" />
      <BottomHeader />
      <Container className="buildContainer">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-12">
                <Accordion defaultActiveKey="0">
                  <Card className="card-m-bottom">
                  {loader && <div style={{height: "100%", width:"100%", backgroundColor:"#0c0c0c1f", position:"absolute", zIndex:"9",display:"flex",justifyContent:"center",alignItems:"center"}}><CircularProgress/></div>}
                    <Card.Header>1.Boot</Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div className="container-fluid">
                          <div className="row dashboard_content acrodingContent sticky-div">
                            <div className="col-md-6 col-lg-6  col-sm-6 acrodingCol ">
                              <div className="save_button primary_btn default_button pull-left"></div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6 acrodingCol">
                              <div className="save_button primary_btn default_button pull-right">
                                {selectedBoot && (
                                  <ContextAwareToggle eventKey="1">
                                    <button
                                      type="button"
                                      className="mt-0"
                                      fdprocessedid="e7h6ak"
                                      onClick={getFrameList}
                                    >
                                      Continue
                                    </button>
                                  </ContextAwareToggle>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row no-gutters shop_wrapper grid_4 mt-4 mb-4">
                            {Products.map((product) => {
                              let bootUrl = null;
                              if(bootColor.Image && bootColor.ProductId==product.ProductId){
                                  bootUrl = bootColor.Image;
                              }
                              else if(product.productImages.Image)
                                {
                                bootUrl = product.productImages.Image;
                                }
                              else if(product.productImages)
                                {
                                  bootUrl = product.productImages;
                                }
                                  else
                                  {
                                    bootUrl = null;
                                  }
                             return <div
                                className="col-xl-20 col-lg-3 col-md-4 col-sm-6 col-6"
                                key={product.id}
                              >
                                <article className="single_product">
                                  <figure className="" style={{border:`${selectedBoot && selectedBoot.product.ProductId === product.ProductId ? '2px solid #e0e20e' : 'none'}`}}> 
                                    <div className="product_thumb">
                                      <Link className="primary_img" to="#">
                                        <img
                                          src={`${process.env.REACT_APP_BASE_URL}/${bootUrl}`}
                                          alt={product.ProductName}
                                        />
                                      </Link>
                                      <div
                                        className="label_product"
                                        id="bootoffertext"
                                      ></div>
                                    </div>
                                    <div className="product_content">
                                      <div className="product_content_inner">
                                        <h4 className="product_name">
                                          <Link to="#">
                                            {product.ProductName}
                                          </Link>
                                        </h4>
                                        <div
                                          className="price_box"
                                          id="bootprice"
                                        >
                                          {/* <span className="current_price fw-bold">
                                          {`USD ${parseFloat(
                          product.MRP.$numberDecimal
                        ).toFixed(2)}`}
                                          </span> */}
                                        </div>
                                        {/* <Form.Label>Select Size</Form.Label>
                                        <Form.Select aria-label="Default select example" onChange={handleSelectBootSize}>
                                          {sizeChart.map((item)=>
                                          <option key={item._id} value={item}>{item.Size}</option>
                                        )}
                                        </Form.Select> */}
                                        <FormControl fullWidth>
                                              <InputLabel id="demo-simple-select-label">Boot Size</InputLabel>
                                              <Select
                                                     labelId="demo-simple-select-label"
                                                     id="demo-simple-select"
                                                     label="Boot Size"
                                                     onChange={(event)=>handleSelectBootSize(event,product)}
                                                     name="bootSize"
                                                     >
                                                      {sizeChart.map((item)=>
                                                        <MenuItem key={item._id} value={item}>{item.Size}</MenuItem>
                                                        )}
                                               </Select>
                                        </FormControl>
                                        <br /><br />
                                        {bootColorToggle && product.ProductId === bootColorList[0].ProductId &&
                                        <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Boot Color</InputLabel>
                                        <Select
                                               labelId="demo-simple-select-label"
                                               id="demo-simple-select"
                                               label="Boot Color"
                                              //  onChange={handleSelectBootColor}
                                              onChange={async(e)=>{
                                                let bootColor = e.target.value;
                                                let productParameters = await getUpdatedProduct({ProductColorId:bootColor.ProductColorId,ProductGroupSizeId:bootSize._id})
                                                productParameters = productParameters[0];
                                                let prod = {...product,productParameters,productImages:bootColor}
                                                setSelectedBoot({product:prod,bootColor,bootSize})
                                                console.log("srsrsrsrsrsrsrsr",product)
                                                console.log("srsrsrsrsrsrsrsr",bootColor)
                                                console.log("srsrsrsrsrsrsrsr",bootSize)
                                                setBootColor(bootColor)
                                              }}
                                               name="bootColor"
                                               >
                                                {bootColorList.map((item)=>
                                                  <MenuItem key={item._id} value={item}>{item.AlterText}</MenuItem>
                                                  )}
                                           </Select>
                                          </FormControl>
                                      }
                                        <div className="checkout_form mt-2">
                                          {/* <input
                                            id="Boot_174"
                                            type="radio"
                                            name="chk_Boot"
                                            data-id="174"
                                            value={product}
                                            onChange={() => {
                                              // setSelectedBoot({product,bootColor,bootSize});
                                              console.log(selectedBoot)
                                            }}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </figure>
                                </article>
                              </div>
})}
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="card-m-bottom">
                    <Card.Header>2.Frame</Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <div className="container-fluid">
                          <div className="row dashboard_content sticky-div">
                            <div className="col-md-6 col-lg-6 col-6">
                              <div className="save_button primary_btn default_button pull-left">
                                <ContextAwareToggle eventKey="0">
                                  <button
                                    type="button"
                                    className="mt-0 disabled-button"
                                    style={{ cursor: "no-drop" }}
                                  >
                                    Prev
                                  </button>
                                </ContextAwareToggle>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-6">
                              <div className="save_button primary_btn default_button pull-right">
                                {selectedFrame && (
                                  <ContextAwareToggle eventKey="2">
                                    <button
                                      type="button"
                                      className="mt-0"
                                      fdprocessedid="e7h6ak"
                                      onClick={()=>setShows(true)}
                                    >
                                      Continue
                                    </button>
                                  </ContextAwareToggle>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row no-gutters shop_wrapper grid_4 mt-4 mb-4">
                            {FrameProducts.map((product) => (
                              <div
                                className="col-xl-20 col-lg-3 col-md-4 col-sm-6 col-6"
                                key={product.ProductId
                                }
                                onClick={
                                  () => {
                                    setSelectedFrame(product);
                                    setWheelsProducts(product.wheelArray)
                              setCount(count+1)
                                    //
                                  }
                                }
                              >
                                <article className="single_product">
                                  <figure className="" style={{border:`${selectedFrame && selectedFrame.ProductId === product.ProductId ? '2px solid #e0e20e' : 'none'}`}}>
                                    <div className="product_thumb">
                                      <Link className="primary_img" to="#">
                                        <img
                                          src={`${process.env.REACT_APP_BASE_URL}/${
                                            product.productImages
                                              ? product.productImages.Image
                                              : null
                                          }`}
                                          alt={product.ProductName}
                                        />
                                      </Link>
                                      <div
                                        className="label_product"
                                        id="bootoffertext"
                                      ></div>
                                    </div>
                                    <div className="product_content">
                                      <div className="product_content_inner">
                                        <h4 className="product_name">
                                          <Link to="#">
                                            {product.ProductName}
                                          </Link>
                                        </h4>
                                        <div
                                          className="price_box"
                                          id="bootprice"
                                        >
                                          <span className="current_price fw-bold">
                                            {product.price}
                                          </span>
                                        </div>

                                        <div className="checkout_form mt-2">
                                          {/* <input
                                            id="Boot_174"
                                            type="radio"
                                            name="chk_Boot"
                                            data-id="174"
                                            value={product}
                                            onChange={() => {
                                              setSelectedFrame(product);
                                              setWheelsProducts(product.wheelArray)
                                              //
                                            }}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </figure>
                                </article>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  {selectedFrame?.customFrame ? 
                  <Card className="card-m-bottom">
                    <Card.Header>3.Wheels</Card.Header>
                    {shows &&<Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <div className="container-fluid">
                          <div className="row dashboard_content sticky-div">
                            <div className="col-md-6 col-lg-6 col-6">
                              <div className="save_button primary_btn default_button pull-left">
                                <ContextAwareToggle eventKey="1">
                                  <button
                                    type="button"
                                    className="mt-0 disabled-button"
                                    style={{ cursor: "no-drop" }}
                                    onClick={()=>setShows(false)}
                                  >
                                    Prev
                                  </button>
                                </ContextAwareToggle>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-6">
                              <div className="save_button primary_btn default_button pull-right">
                                {selectedWheel && selectedWheel2 && (
                                  <ContextAwareToggle eventKey="3">
                                    <button
                                      type="button"
                                      className="mt-0"
                                      fdprocessedid="e7h6ak"
                                    >
                                      Continue
                                    </button>
                                  </ContextAwareToggle>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row no-gutters shop_wrapper grid_4 mt-4 mb-4">
                          <h4>Wheels Set 1</h4>
                            {WheelsProducts.map((product) => {
                              if(product.Size == "100mm"){
                              return<div
                                className="col-xl-20 col-lg-3 col-md-4 col-sm-6 col-6"
                                key={product.ProductId}
                                onClick={()=>{
                                  setSelectedWheel(product);
                                }}
                              >
                                <article className="single_product">
                                  <figure className="" style={{border:`${selectedWheel && selectedWheel.ProductId === product.ProductId && selectedWheel.Size==product.Size ? '2px solid #e0e20e' : 'none'}`}}>
                                    <div className="product_thumb">
                                      <Link className="primary_img" to="#">
                                        <img
                                          src={`${process.env.REACT_APP_BASE_URL}/${
                                            product.productImages
                                              ? product.productImages.Image
                                              : null
                                          }`}
                                          alt={product.ProductName}
                                        />
                                      </Link>
                                      <div
                                        className="label_product"
                                        id="bootoffertext"
                                      ></div>
                                    </div>
                                    <div className="product_content">
                                      <div className="product_content_inner">
                                        <h4 className="product_name">
                                          <Link to="#">
                                            {product.ProductName}
                                          </Link>
                                        </h4>
                                        <h4 className="product_name">
                                          <Link to="#">
                                            {product.Size}
                                          </Link>
                                        </h4>
                                        <div
                                          className="price_box"
                                          id="bootprice"
                                        >
                                          <span className="current_price fw-bold">
                                            {product.price}
                                          </span>
                                        </div>
                                        <div className="checkout_form mt-2">
                                          {/* <input
                                            id="Boot_174"
                                            type="radio"
                                            name="chk_Boot"
                                            data-id="174"
                                            value={product}
                                            onChange={() => {
                                              setSelectedWheel(product);
                                            }}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </figure>
                                </article>
                              </div>}
                             })}
                          </div>
                          <div className="row no-gutters shop_wrapper grid_4 mt-4 mb-4">
                            <h4>Wheels Set 2</h4>
                            {WheelsProducts.map((product) => {
                              if(product.Size == "110mm")
                              {
                              return<div
                                className="col-xl-20 col-lg-3 col-md-4 col-sm-6 col-6"
                                key={product.ProductId}
                                onClick={()=>{
                                  setSelectedWheel2(product);
                                }}
                              >
                                <article className="single_product">
                                  <figure className="" style={{border:`${selectedWheel2 && selectedWheel2.ProductId === product.ProductId && selectedWheel2.Size==product.Size ? '2px solid #e0e20e' : 'none'}`}}>
                                    <div className="product_thumb">
                                      <Link className="primary_img" to="#">
                                        <img
                                          src={`${process.env.REACT_APP_BASE_URL}/${
                                            product.productImages
                                              ? product.productImages.Image
                                              : null
                                          }`}
                                          alt={product.ProductName}
                                        />
                                      </Link>
                                      <div
                                        className="label_product"
                                        id="bootoffertext"
                                      ></div>
                                    </div>
                                    <div className="product_content">
                                      <div className="product_content_inner">
                                        <h4 className="product_name">
                                          <Link to="#">
                                            {product.ProductName}
                                          </Link>
                                        </h4>
                                        <h4 className="product_name">
                                          <Link to="#">
                                            {product.Size}
                                          </Link>
                                        </h4>
                                        <div
                                          className="price_box"
                                          id="bootprice"
                                        >
                                          <span className="current_price fw-bold">
                                            {product.price}
                                          </span>
                                        </div>
                                        <div className="checkout_form mt-2">
                                          {/* <input
                                            id="Boot_175"
                                            type="radio"
                                            name="chk_Boot5"
                                            data-id="175"
                                            value={product}
                                            onChange={() => {
                                              setSelectedWheel2(product);
                                            }}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </figure>
                                </article>
                              </div>}
                             })}
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>}
                  </Card>
                  :
                  <Card className="card-m-bottom">
                    <Card.Header>3.Wheels</Card.Header>
                    {shows && <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <div className="container-fluid">
                          <div className="row dashboard_content sticky-div">
                            <div className="col-md-6 col-lg-6 col-6">
                              <div className="save_button primary_btn default_button pull-left">
                                <ContextAwareToggle eventKey="1">
                                  <button
                                    type="button"
                                    className="mt-0 disabled-button"
                                    style={{ cursor: "no-drop" }}
                                    onClick={()=>setShows(false)}
                                  >
                                    Prev
                                  </button>
                                </ContextAwareToggle>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-6">
                              <div className="save_button primary_btn default_button pull-right">
                                {selectedWheel && (
                                  <ContextAwareToggle eventKey="3">
                                    <button
                                      type="button"
                                      className="mt-0"
                                      fdprocessedid="e7h6ak"
                                    >
                                      Continue
                                    </button>
                                  </ContextAwareToggle>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row no-gutters shop_wrapper grid_4 mt-4 mb-4">
                            {WheelsProducts.map((product) => {
                              console.log("srrsrsrsrsrsrsr",product)
                              return <div
                                className="col-xl-20 col-lg-3 col-md-4 col-sm-6 col-6"
                                key={product.ProductId}
                                onClick={()=>{
                                  setSelectedWheel(product);
                                }}
                              >
                                <article className="single_product">
                                  <figure className="" style={{border:`${selectedWheel && selectedWheel.ProductId === product.ProductId && selectedWheel.Size==product.Size ? '2px solid #e0e20e' : 'none'}`}}>
                                    <div className="product_thumb">
                                      <Link className="primary_img" to="#">
                                        <img
                                          src={`${process.env.REACT_APP_BASE_URL}/${
                                            product.productImages
                                              ? product.productImages.Image
                                              : null
                                          }`}
                                          alt={product.ProductName}
                                        />
                                      </Link>
                                      <div
                                        className="label_product"
                                        id="bootoffertext"
                                      ></div>
                                    </div>
                                    <div className="product_content">
                                      <div className="product_content_inner">
                                        <h4 className="product_name">
                                          <Link to="#">
                                            {product.ProductName}
                                          </Link>
                                        </h4>
                                        <h4 className="product_name">
                                          <Link to="#">
                                            {product.Size}
                                          </Link>
                                        </h4>
                                        <div
                                          className="price_box"
                                          id="bootprice"
                                        >
                                          <span className="current_price fw-bold">
                                            {product.price}
                                          </span>
                                        </div>
                                        <div className="checkout_form mt-2">
                                          {/* <input
                                            id="Boot_174"
                                            type="radio"
                                            name="chk_Boot"
                                            data-id="174"
                                            value={product}
                                            onChange={() => {
                                              setSelectedWheel(product);
                                            }}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </figure>
                                </article>
                              </div>
})}
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>}
                  </Card>
                }
                  <Card className="card-m-bottom">
                    <Card.Header>4.Bearings</Card.Header>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <div className="container-fluid">
                          <div className="row dashboard_content sticky-div">
                            <div className="col-md-6 col-lg-6 col-6">
                              <div className="save_button primary_btn default_button pull-left">
                                <ContextAwareToggle eventKey="2">
                                  <button
                                    type="button"
                                    className="mt-0 disabled-button"
                                    style={{ cursor: "no-drop" }}
                                  >
                                    Prev
                                  </button>
                                </ContextAwareToggle>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-6">
                              <div className="save_button primary_btn default_button pull-right">
                                {selectedBearing && (
                                  <ContextAwareToggle eventKey="4">
                                    <button
                                      type="button"
                                      className="mt-0"
                                      onClick={() => {
                                        updateProductList();
                                      }}
                                      fdprocessedid="e7h6ak"
                                    >
                                      Continue
                                    </button>
                                  </ContextAwareToggle>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row no-gutters shop_wrapper grid_4 mt-4 mb-4">
                            {BearingProducts.map((product) => (
                              <div
                                className="col-xl-20 col-lg-3 col-md-4 col-sm-6 col-6"
                                key={product.id}
                                onClick={()=>{
                                  setSelectedBearing(product);
                                }}
                              >
                                <article className="single_product">
                                  <figure className="" style={{border:`${selectedBearing && selectedBearing.ProductId === product.ProductId ? '2px solid #e0e20e' : 'none'}`}}>
                                    <div className="product_thumb">
                                      <Link className="primary_img" to="#">
                                        <img
                                          src={`${process.env.REACT_APP_BASE_URL}/${
                                            product.productImages
                                              ? product.productImages.Image
                                              : null
                                          }`}
                                          alt={product.ProductName}
                                        />
                                      </Link>
                                      <div
                                        className="label_product"
                                        id="bootoffertext"
                                      ></div>
                                    </div>
                                    <div className="product_content">
                                      <div className="product_content_inner">
                                        <h4 className="product_name">
                                          <Link to="#">
                                            {product.ProductName}
                                          </Link>
                                        </h4>
                                        <div
                                          className="price_box"
                                          id="bootprice"
                                        >
                                          <span className="current_price fw-bold">
                                            {product.price}
                                          </span>
                                        </div>

                                        <div className="checkout_form mt-2">
                                          {/* <input
                                            id="Boot_174"
                                            type="radio"
                                            name="chk_Boot"
                                            data-id="174"
                                            value={product}
                                            onChange={() => {
                                              setSelectedBearing(product);
                                            }}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </figure>
                                </article>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card className="card-m-bottom">
                    <Card.Header>5.Finished Your Design</Card.Header>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>
                        <div className="container-fluid">
                          <div className="row dashboard_content sticky-div">
                            <div className="col-md-6 col-lg-6 col-6">
                              <div className="save_button primary_btn default_button pull-left">
                                <ContextAwareToggle eventKey="3">
                                  <button
                                    type="button"
                                    className="mt-0 disabled-button"
                                    style={{ cursor: "no-drop" }}
                                  >
                                    Prev
                                  </button>
                                </ContextAwareToggle>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-6">
                              <div className="save_button primary_btn default_button pull-right">
                                <button
                                  type="button"
                                  className="mt-0"

                                  onClick={
                                    finishSket
                                  }
                                >


                                  Finish
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="row no-gutters shop_wrapper grid_4 mt-4 mb-4">
                            {finalProduct
                              ? finalProduct.map((product,index) => (
                                  <div className="col-md-6 col-lg-3 col-6" 
                                  key={index}
                                  >
                                    <div className="select-img">
                                      <img
                                        src={`${process.env.REACT_APP_BASE_URL}/${
                                          product.productImages
                                            ? product.productImages.Image
                                            : null
                                        }`}
                                        alt={product.name}
                                      />
                                      <h5>{product.ProductName}</h5>
                                    </div>
                                    {/* <div className="select-text">
                                  <table className="table">
                                    <tbody>
                                      <tr>
                                        {" "}
                                        <th>{product.ProductName}</th>{" "}
                                        <td className="skate-td">Dash Boots</td>{" "}
                                      </tr>{" "}
                                      <tr>
                                        {" "}
                                        <th>Boot Code</th>{" "}
                                        <td className="skate-td">
                                          {product.code}
                                        </td>{" "}
                                      </tr>
                                      <tr>
                                        {" "}
                                        <td colspan="2" className="skate-td">
                                          <a
                                            href="/product-details"
                                            className="quickbtn"
                                            // target="_blank"
                                            title=""
                                            data-original-title="Quick View"
                                          >
                                            Quick View
                                          </a>
                                        </td>{" "}
                                      </tr>{" "}
                                    </tbody>
                                  </table>
                                </div> */}
                                  </div>
                                ))
                              : null}
                          </div>
                        </div>
                        {note.IsActive==true && <div>
                          <h4><FaRegHandPointRight/> NOTE</h4>
                          <p>{note.noteDetail}</p>
                        </div>}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

const Build_Your_Skate = () => {



  return (
    <React.Fragment>
      <HeaderTwo />
      {/* <BottomHeader /> */}
      <Example />
      <Subscribe />
      <Footer />
      <ScrollButton />
    </React.Fragment>
  );
};

export default Build_Your_Skate;
