import { toast } from "react-hot-toast";

export const notify = (message, icon) =>
toast(message, {
  duration: 3000,
  position: "top-right",
  style: {},
  className: "",
  icon: icon,
  iconTheme: {
    primary: "#000",
    secondary: "#fff",
  },
  ariaProps: {
    role: "status",
    "aria-live": "polite",
  },
});