import {
  storeCart,
  storeCartTotla,
  storeNumberOfItemsInCart,
  storeWishlist,
} from "../state/action";
import { APIClient } from "./API_helper";

const api = new APIClient();
const baseUrl = process.env.REACT_APP_BASE_URL;


// ----------------AUTH----------------------------------------------------------------------------------------------------------------

export const loginCustomer = (body) => api.create("/customer/login", body);
export const getLoggedinUserInfo = () => api.get("/customer/getLoggedinUser");
export const changePassword = (oldPassword,newPassword) => api.get("/customer/changepassword",{password:oldPassword,newPassword:newPassword});

export const getProduct = (id) =>
  api.put("/ProductMaster/get-product-by-id/" + id);

// ----------------CART----------------------------------------------------------------------------------------------------------------
export const getCart = async (dispatch) => {
  try {
    const getCartResponse = await api.get("/customer/getCart");
    dispatch(storeCart(getCartResponse?.data || []));

    let totalAllProducts = 0;
    if (Array.isArray(getCartResponse?.data)) {
      getCartResponse.data.forEach((item) => {
        const quantity = item.Qty ;
        const sellCost = item.MRP;
        const totalCost = sellCost * quantity;
        totalAllProducts += totalCost;
      });
    }

    dispatch(storeCartTotla(totalAllProducts));

    return getCartResponse;
  } catch (error) {
    console.error("Error fetching cart items:", error);
    return { error: error.message };
  }
};

export const getCustomerOrders = async (dispatch) => {
  try {
    const OrderResponse = await api.get("/customer/get-custommer-orders");
    return OrderResponse;
  } catch (error) {
    console.error("Error fetching cart items:", error);
    return { error: error.message };
  }
};


export const getWishlist = async (dispatch) => {
  try {
    const getWishlistResponse = await api.get("/customer/getWishlist");
    dispatch(storeWishlist(getWishlistResponse?.data || []));




    return getWishlistResponse;
  } catch (error) {
    console.error("Error fetching cart items:", error);
    return { error: error.message };
  }
};



export const addToCart = async (productId, productParameterId, dispatch,quantity) => {
  try {
    await api.create(`${process.env.REACT_APP_BASE_URL}/customer/addToCart`, {
      ProductId: productId,
      ProductParametersId: productParameterId,
      Qty: quantity||1,
    });
    const getWishlistResponse = await api.get("/customer/getCart");
    dispatch(storeCart(getWishlistResponse?.data || []));
  } catch (error) {}
};


export const addToWishlist = async (productId, productParameterId, dispatch) => {
  try {
    await api.create(`${process.env.REACT_APP_BASE_URL}/customer/addToWishlist`, {
      ProductId: productId,
      ProductParametersId: productParameterId,
      Qty: 1,
    });
    const getWishlist = await api.get("/customer/getWishlist");
    dispatch(storeWishlist(getWishlist?.data || []));
  } catch (error) {}
};


export const updateQuantity = (productId, quantity) =>
  api.put("/customer/updateQuantity/" + productId, quantity);

export const removeFromCart = (id) =>
  api.delete("/customer/removeFromCart/" + id);

export const removeFromWishlist = (id) =>
  api.delete("/customer/removeFromWishlist/" + id);


// ----------------NEW_ARAIVAL----------------------------------------------------------------------------------------------------------------

export const getNewArrival = async () => {
  try {
    const response = await api.get("/productMaster/get-new-arrival");
    return response.data;
  } catch (error) {
    return error.message;
  }
};
// ----------------NEW_ARAIVAL----------------------------------------------------------------------------------------------------------------

export const getBestSelling = async () => {
  try {
    const response = await api.get("/productMaster/get-best-selling");
    return response.data; 
  } catch (error) {
    console.error("Error fetching best-selling products:", error);
    throw error; 
  }
};