import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import * as Yup from "yup";
import BottomHeader from "../Component/BottomHeader";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import SidebarMyprofile from "../Component/sidebarMyprofile";
import Shape1 from "../assets/img/shape1.png";
import Shape2 from "../assets/img/shape2.png";

const TrackMyOrder = () => {
  const schema = Yup.object().shape({
    orderID: Yup.string().required("Order id is  required "),
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <div>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <BottomHeader />
          <SliderTwo Title="Track My Order" SubTitle="Track My Order" />
          <BottomHeader />
          <Container>
            <Row>
              <Col lg="4">
                <SidebarMyprofile />
              </Col>
              <Col lg="8">
                <Card className="formCard">
                  <h6>Track Your Order</h6>
                  <Row>
                    <Col lg="6">
                      <Formik
                        validationSchema={schema}
                        initialValues={{ orderID: "" }}
                        onSubmit={(values) => {
                          // Alert the input values of the form that we filled
                          alert(JSON.stringify(values));
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <div className="login">
                            <div className="form">
                              {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                              <div>
                                <form noValidate onSubmit={handleSubmit}>
                                  {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      name="orderID"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.orderID}
                                      placeholder="Order Id"
                                      className="form-control"
                                      id="orderID"
                                    />
                                    {/* If validation is not passed show errors */}
                                    <p className="error">
                                      {errors.orderID &&
                                        touched.orderID &&
                                        errors.orderID}
                                    </p>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        )}
                      </Formik>
                    </Col>
                    <Col lg="6">
                      <button type="submit" className="btn common-btn">
                        Track Your Order
                        <img src={Shape1} alt="Shape" />
                        <img src={Shape2} alt="Shape" />
                      </button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </div>
  );
};

export default TrackMyOrder;
