import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import AddToCart from "./addTocart";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const ReltedProduct = () => {
  const navig = useNavigate();
  const currency = useSelector((state) => state.selectedCurrency);
  const relatedItems = useSelector((state)=>state.relatedProducts);
  const limitedRelatedItems = relatedItems.length < 4 ? relatedItems : relatedItems.slice(0,4);


  const validSegment = (string) => {
    if (!string || typeof string !== "string") {
      return "sr";
    }

    const newString = string
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase();

    return newString; // Return the modified string
  };


  const handleNavigate=(p)=>{
    navig(`/product-details/${
      p.ProductName
        ? validSegment(p.ProductName)
        : null
    }/${p._id}/${
      p.productParameters
        ? p.productParameters.ProductColorId
        : null
    }/${
      p.productParameters
        ? p.productParameters.ProductGroupSizeId
        : null
    }`)
  }

  return   (
    <React.Fragment>
      <div className="products-area pb-70">
        <div className="container">
          <div className="section-title">
            <h2>Related Products</h2>
          </div>

        
          <Row>
           {
             limitedRelatedItems.map( (item)=> (
              <Col key={item._id} className="col-sm-6 col-lg-3">
              <div className="products-item" onClick={()=>handleNavigate(item)}>
                              <div className="top">
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL}/${
                                      item.productImages
                                        ? item.productImages.Image
                                        : null
                                    }`}
                                    alt="Products"
                                  />
                                <div className="inner">
                                  <h3>{item.ProductName}</h3>
                                  <span>
                                  {`${currency.currency} ${currency.symbol} `}
                                    {item.productParameters
                                      ? (
                                          item.productParameters.MRP *
                                          currency.value
                                        ).toFixed(2)
                                      : null}
                                  </span>
                                </div>
                              </div>
                          </div>
            </Col>
             ) )

           }
            
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReltedProduct;
