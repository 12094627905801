import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { FaLifeRing, FaSquarespace } from "react-icons/fa";
import {
  GiCarWheel,
  GiLoincloth,
  GiRollerSkate,
  GiSkateboard,
} from "react-icons/gi";
import { PiBootFill } from "react-icons/pi";
import { TbHelmet, TbKeyframes, TbSkateboarding } from "react-icons/tb";
import { Puff } from "react-loader-spinner";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import SliderTwo from "../Component/SliderTwo";
import Sape1 from "./../assets/img/shape1.png";
import Sape2 from "./../assets/img/shape2.png";

import BottomHeader from "../Component/BottomHeader";

const AllCategory = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="All Categories" SubTitle="All Categories" />
          <BottomHeader />
          <div className="products-area two ">
            <div className="container">

              <Row>
                <Col lg="2" md="6" sm="12">
                  <div className="carouselDiv">
                    <div className="products-thumb">
                      <Link to="/product-list/10002">
                        <img src={Sape1} alt="Shape" />
                        <img src={Sape2} alt="Shape" />
                        <i className="flaticon-headphones">
                          <GiSkateboard className="categoriesIcon" />
                        </i>
                        <span>Dash Complete Skate</span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg="2" md="6" sm="12">
                  <div className="carouselDiv">
                    <div className="products-thumb">
                      <Link to="/product-list/2">
                        <img src={Sape1} alt="Shape" />
                        <img src={Sape2} alt="Shape" />
                        <i className="flaticon-microphone">
                          <GiRollerSkate className="categoriesIcon" />
                        </i>
                        <span>Rush 3.0 Complete Skate</span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg="2" md="6" sm="12">
                  <div className="carouselDiv">
                    <div className="products-thumb">
                      <Link to="/product-list/9">
                        <img src={Sape1} alt="Shape" />
                        <img src={Sape2} alt="Shape" />
                        <i className="flaticon-laptop">
                          <TbSkateboarding className="categoriesIcon" />
                        </i>
                        <span>Fly Complete Skate</span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg="2" md="6" sm="12">
                  <div className="carouselDiv">
                    <div className="products-thumb">
                      <Link to="/product-list/3">
                        <img src={Sape1} alt="Shape" />
                        <img src={Sape2} alt="Shape" />
                        <i className="flaticon-laptop">
                          <PiBootFill className="categoriesIcon" />
                        </i>
                        <span>Boots</span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg="2" md="6" sm="12">
                  <div className="carouselDiv">
                    <div className="products-thumb">
                      <Link to="/product-list/4">
                        <img src={Sape1} alt="Shape" />
                        <img src={Sape2} alt="Shape" />
                        <i className="flaticon-laptop">
                          <TbKeyframes className="categoriesIcon" />
                        </i>
                        <span>Frames</span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg="2" md="6" sm="12">
                  <div className="carouselDiv">
                    <div className="products-thumb">
                      <Link to="/product-list/5">
                        <img src={Sape1} alt="Shape" />
                        <img src={Sape2} alt="Shape" />
                        <i className="flaticon-laptop">
                          <GiCarWheel className="categoriesIcon" />
                        </i>
                        <span>Wheels</span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg="2" md="6" sm="12">
                  <div className="carouselDiv">
                    <div className="products-thumb">
                      <Link to="/product-list/6">
                        <img src={Sape1} alt="Shape" />
                        <img src={Sape2} alt="Shape" />
                        <i className="flaticon-laptop">
                          <FaLifeRing className="categoriesIcon" />
                        </i>
                        <span>Bearings</span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg="2" md="6" sm="12">
                  <div className="carouselDiv">
                    <div className="products-thumb">
                      <Link to="/product-list/8">
                        <img src={Sape1} alt="Shape" />
                        <img src={Sape2} alt="Shape" />
                        <i className="flaticon-laptop">
                          <FaSquarespace className="categoriesIcon" />
                        </i>
                        <span>Accessories </span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg="2" md="6" sm="12">
                  <div className="carouselDiv">
                    <div className="products-thumb">
                      <Link to="/product-list/7">
                        <img src={Sape1} alt="Shape" />
                        <img src={Sape2} alt="Shape" />
                        <i className="flaticon-laptop">
                          <GiLoincloth className="categoriesIcon" />
                        </i>
                        <span>Apparels </span>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg="2" md="6" sm="12">
                  <div className="carouselDiv">
                    <div className="products-thumb">
                      <Link to="/product-list/10">
                        <img src={Sape1} alt="Shape" />
                        <img src={Sape2} alt="Shape" />
                        <i className="flaticon-laptop">
                          <TbHelmet className="categoriesIcon" />
                        </i>
                        <span>Helmet </span>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

            </div>
          </div>
          <Footer />
        </div>
      )}
    </React.Fragment>
  );
};

export default AllCategory;
