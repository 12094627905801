import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import BottomHeader from "../Component/BottomHeader";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import ProductCardComponent from "../Component/productGroupCard";
import { addToCart } from "../helpers/backen_helper";
import noImage from "./../assets/img/no-image.png";
import { relatedProduct } from "../state/action";

function valuetext(value) {
  return `${value}°C`;
}
const ProductList = () => {
  const currency = useSelector((state) => state.selectedCurrency);
  const notify = (message, icon) =>
    toast(message, {
      duration: 2000,
      position: "top-right",
      style: {},
      className: "",
      icon: icon,
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAddToCart = async (productId, productParameterId) => {
    if (localStorage.getItem("token")) {
      addToCart(productId, productParameterId, dispatch);

      notify("product added to your cart", "✅");
    } else {
      SetNavigateTologin(true);

      // navigate("/login");
    }
  };

  const { id } = useParams();

  // called the API and get the data
  const [productData1, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [productGroupData, setProductGroupData] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedSort, detSelectedSort] = useState("price : high to low");
  const [uniqueIds, setUniqueIds] = useState([]);
  const [uniqueIds2, setUniqueIds2] = useState([]);
  const [brandFilter, SetbrandFilter] = useState([]);
  const [maxMRP, setMaxVal] = useState(0);
  const [navigateTologin, SetNavigateTologin] = useState(false);

  const [shortOptions, SetshortOptions] = useState([
    "price : low to high",
    "price : high to low",
  ]);

  const [sizeFilter, SetsizeFilter] = useState([]);

  const validSegment = (string) => {
    if (!string || typeof string !== "string") {
      return "sr";
    }

    const newString = string
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase();

    return newString; // Return the modified string
  };

  // Function to clear selected brand
  const clearBrandSelection = () => {
    setSelectedBrand(null);
  };

  // Function to clear selected size
  const clearSizeSelection = () => {
    detSelectedSort(null);
  };

  const getFilteredProducts = () => {
    let filteredProducts = productData1;

    return filteredProducts;
  };
  useEffect(() => {
    // window.scrollTo(0, 450);
    moveToElement("bigning");
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/productMaster/get-product-by-groupid/${id}`
        );

        setProductData(response.data);
        dispatch(relatedProduct(response.data));
        const uniqueBrandIds = [
          ...new Set(response.data.map((element) => element.BrandId)),
        ];

        const resData = response.data;
        if (resData.length > 0) {
          const mrpValues = resData
            .map((item) => item.MRP) // Extracting MRP values
            .filter((value) => value !== null && value !== undefined); // Filtering out null and undefined values

          const maxMRP = Math.max(...mrpValues);
          setMaxVal(maxMRP);
        } else {
          setMaxVal(0);
        }
        const uniqueSizes = response.data.reduce((sizes, element) => {
          element.productSizes.forEach((sizeObj) => {
            if (!sizes.includes(sizeObj.Size)) {
              sizes.push(sizeObj.Size);
            }
          });
          return sizes;
        }, []);

        setUniqueIds(uniqueBrandIds);
        setUniqueIds2(uniqueSizes.toString());
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const filteredProducts = getFilteredProducts();

  const getProductGroupData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/productGroupMaster/get-product-group-master`
      );
      setProductGroupData(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getProductGroupData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const brandData = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/brandMaster/get-brand-master-by-pgid/${id}`
        );
        const productGroupsize = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/productGroupSizeMaster/get-product-group-size-master`
        );


        SetbrandFilter(brandData);
        SetsizeFilter(productGroupsize.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData().then(() => {
      setIsLoading(false);
    });
  }, [id]);

  const moveToElement = (id) => {
    const specificElement = document.getElementById(id);

    if (specificElement) {
      specificElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleSortChange = (selectedOrder) => {
    detSelectedSort(selectedOrder);

    let sortedProductsClone = [...productData1];

    if (selectedSort === "price : high to low") {
      let newsortedProductsClone = sortedProductsClone.sort((a, b) => {
        if (a.productParameters && b.productParameters) {
          return a.productParameters.SellCost - b.productParameters.SellCost;
        }
        return 0;
      });
      setProductData(newsortedProductsClone);
    } else if (selectedSort === "price : low to high") {
      let newsortedProductsClone = sortedProductsClone.sort((a, b) => {
        if (a.productParameters && b.productParameters) {
          return b.productParameters.SellCost - a.productParameters.SellCost;
        }
        return 0;
      });
      setProductData(newsortedProductsClone);
    }
  };

  const handleImageError = (event) => {
    event.target.src = noImage;
  };

  const [value, setValue] = React.useState([0, 10000]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <React.Fragment>
      {navigateTologin ? <Navigate to="/login" /> : null}

      <Toaster />
      <HaderTwo />
      <SliderTwo Title="Product List" SubTitle="Product List" />
      <BottomHeader />
      <div className="products-area ptb-100">
        <div className="container">
          <Row>
            <Col lg="4">
              <div className="sorting-menu">
                <Row>
                  {productGroupData &&
                    productGroupData.map((item) => (
                      <Col lg="6" md="4" className="col-6">
                        <ProductCardComponent
                          key={item._id}
                          image={`${process.env.REACT_APP_BASE_URL}/${item.ProductGroupImage}`}
                          name={item.ProductGroupName}
                          id={item._id}
                          
                        />
                      </Col>
                    ))}
                </Row>
              </div>
            </Col>

            <Col lg="8" id="bigning">
              <Row style={{paddingTop:"10px"}}>
                <Col lg="4" md="6" sm="12" className="filterCol">
                  <div className="manDivPriceRangeTitle">
                    <Row>
                      <Col lg="4">
                        <h6 className="priceTitle"> PRICE RANGE</h6>
                      </Col>
                      <Col lg="8">
                        <Box sx={{ width: 150 }}>
                          <Slider
                            max={maxMRP}
                            min={0}
                            getAriaLabel={() => "Temperature range"}
                            value={value}
                            onChange={handleChange}
                            valueLabelFormat={(e) => {
                              return `${e} ${"$"}`;
                            }}
                            valueLabelDisplay="auto"
                          />
                        </Box>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg="4" md="6" sm="12" className="filterCol">
                  <Card className="manDivPriceRangeTitle brandBackground">
                    <Row>
                      <Col lg="4">
                        <h6 className="priceTitle">BRAND</h6>
                        <Link to="#">
                          <h6
                            className="clearTitle"
                            onClick={clearBrandSelection}
                          >
                            CLEAR
                          </h6>
                        </Link>
                      </Col>
                      <Col lg="8">
                        <select
                          className="form-select selctSection"
                          onChange={(e) => {
                            setSelectedBrand(parseInt(e.target.value));
                          }}
                          value={selectedBrand || ""}
                        >
                          <option value="">Select Brand</option>
                          {brandFilter?.map(
                            (data, index) => (
                              // uniqueIds.includes(data.BrandId) && (
                              <option key={index} value={data._id}>
                                {data.BrandName}
                              </option>
                            )
                            // )
                          )}
                        </select>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col lg="4" md="6" sm="12" className="filterCol">
                  <Card className="manDivPriceRangeTitle sizeBackground">
                    <Row>
                      <Col lg="4">
                        <h6 className="priceTitle">SHORT </h6>

                        <Link to="#">
                          <h6
                            className="clearTitle"
                            onClick={clearSizeSelection}
                          >
                            CLEAR
                          </h6>
                        </Link>
                      </Col>
                      <Col lg="8">
                        <select
                          className="form-select selctSection"
                          onChange={(e) => {
                            handleSortChange(e.target.value);
                          }}
                          value={selectedSort || ""}
                        >
                          <option value="">Select One</option>
                          {shortOptions?.map((data, index) => (
                            <option key={index} value={data}>
                              {data}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              {isLoading ? (
                <div className="loader-container">
                  <Puff color="#000" height={50} width={50} timeout={0} />
                </div>
              ) : (
                <Row>
                  {filteredProducts &&
                  Array.isArray(filteredProducts) &&
                  filteredProducts.length > 0 &&
                  filteredProducts.filter((p) => {
                    const matchesBrand =
                      selectedBrand === null || selectedBrand === p.BrandId;
                    const matchesPrice =
                      !p.productParameters ||
                      (p.productParameters.MRP >= value[0] &&
                        p.productParameters.MRP <= value[1]);

                    return matchesBrand && matchesPrice;
                  }).length > 0 ? (
                    filteredProducts
                      .filter((p) => {
                        const matchesBrand =
                          selectedBrand === null || selectedBrand === p.BrandId;
                        const matchesPrice =
                          !p.productParameters ||
                          (p.productParameters.MRP >= value[0] &&
                            p.productParameters.MRP <= value[1]);

                        return matchesBrand && matchesPrice;
                      })
                      .map((p) => (
                        <Col
                          lg="4"
                          sm="6"
                          md="6"
                          className=" mix armchair center-table listTable"
                          key={p.id} // Ensure each item has a unique key
                        >
                          <div className="products-item">
                            <Link
                              to={`/product-details/${
                                p.ProductName
                                  ? validSegment(p.ProductName)
                                  : null
                              }/${p._id}/${
                                p.productParameters
                                  ? p.productParameters.ProductColorId
                                  : null
                              }/${
                                p.productParameters
                                  ? p.productParameters.ProductGroupSizeId
                                  : null
                              }`}
                            >
                              <div className="top">
                                <Link
                                  to={`/product-details/${
                                    p.ProductName
                                      ? validSegment(p.ProductName)
                                      : null
                                  }/${p._id}/${
                                    p.productParameters
                                      ? p.productParameters.ProductColorId
                                      : null
                                  }/${
                                    p.productParameters
                                      ? p.productParameters.ProductGroupSizeId
                                      : null
                                  }`}
                                >
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL}/${
                                      p.productImages
                                        ? p.productImages.Image
                                        : null
                                    }`}
                                    alt="Products"
                                    onError={handleImageError}
                                  />
                                </Link>

                                <div className="inner">
                                  <h3>
                                    <Link
                                      to={`/product-details/${
                                        p.ProductName
                                          ? validSegment(p.ProductName)
                                          : null
                                      }/${p._id}/${
                                        p.productParameters
                                          ? p.productParameters.ProductColorId
                                          : null
                                      }/${
                                        p.productParameters
                                          ? p.productParameters
                                              .ProductGroupSizeId
                                          : null
                                      }`}
                                    >
                                      {p.ProductName}
                                    </Link>
                                  </h3>
                                  <span>
                                    {`${currency.currency} ${currency.symbol} `}
                                    {p.productParameters
                                      ? (
                                          p.productParameters.MRP *
                                          currency.value
                                        ).toFixed(2)
                                      : null}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </Col>
                      ))
                  ) : (
                    <h6 className="text-dark text-center mt-2">
                      There are no records to display.
                    </h6>
                  )}
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <Subscribe />
      <Footer />
      <ScrollButton />
    </React.Fragment>
  );
};

export default ProductList;
