import React from "react";
import { BiSupport } from "react-icons/bi";
import { MdOutlineAssuredWorkload } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import Support from "./../assets/img/support-shape1.png";

const Delivery = () => {
  return (
    <React.Fragment>
      <div className="support-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-6 col-lg-4">
              <div className="support-item two">
                <i className="flaticon-free-delivery">
                  <TbTruckDelivery />{" "}
                </i>
                <h3>Free Next Day Delivery</h3>
                <p>Quality and Speed Delivered to Your Doorstep!</p>
                <img src={Support} alt="Shape" />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="support-item three">
                <i className="flaticon-call-center">
                  <BiSupport />
                </i>
                <h3>24/7 Online Support</h3>
                <p>Quality, Speed, and 24/7 Support at Your Fingertips!</p>
                <img src={Support} alt="Shape" />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="support-item four">
                <i className="flaticon-giftbox">
                  {" "}
                  <MdOutlineAssuredWorkload />{" "}
                </i>
                <h3> Assure Guarantee</h3>
                <p>Quality, Speed, and Assured Guaranteed Satisfaction!</p>
                <img src={Support} alt="Shape" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Delivery;
