const initialState = {
  loggedinUserInfo:null,
  cart: null,
  numberOfItemsInCart:null,
  bannerList: [],
  newsList: [],
  galleryCategoryList:[],
  gallery:[],
  downloads:[],
  downloadForm:[],
  selectedCategory:null,
  selectedCurrency:{ currency: 'USD', symbol: '$', value: 1 },
  cartTotal:0,
  wishlist:[],
  relatedProducts:[]
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "STORE_LOGGEDIN_USER_INFO":
      return { ...state, loggedinUserInfo: action.payload };

    case "STORE_CART":
      return { ...state, cart: action.payload };

      case "STORE_WISHLIST":
        return { ...state, wishlist: action.payload };

      case "STORE_NUMBER_OF_ITEMS_OF_CART":
        return { ...state, numberOfItemsInCart: action.payload };
  

    case "STORE_NEWS":
      return { ...state, newsList: action.payload };

      case "STORE_GALLARY_CATEGORY":
      return { ...state, galleryCategoryList: action.payload };

      case "STORE_GALLARY":
      return { ...state, gallery: action.payload };

      case "SET_CATEGORY":
      return { ...state, selectedCategory: action.payload };

      case "SET_NEWS":
        return { ...state, selectedNews: action.payload };

        case "SET_DOWNLOAD":
          return { ...state, downloads: action.payload };

          case "SET_DOWNLOAD_FORM":
          return { ...state, downloadForm: action.payload };

          case "SET_SELECTED_CURRENCY":
            return { ...state, selectedCurrency: action.payload };

            case "STORE_CART_TOTAL":
            return { ...state, cartTotal: action.payload };

            case "RELATED_PRODUCT":
            return { ...state, relatedProducts: action.payload };
    
  
          case "LOGOUT":
            return { ...state, loggedinUserInfo: action.payload };

    default:
      return state;
  }
};

export default rootReducer;
