import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import AddressForm from "./AddressForm";

function Address({selectedAddress}) {
  const [addressForm,setAddressForm] = useState(false);
  const [addresses, setaddresses] = useState("");
  const [allAddresses, setAlladdresses] = useState("");

  const handleToggleAddressForm=()=>{
    setAddressForm(!addressForm);
  }

  const loadAddresses = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_URL + "/shippingAddress/get-shipping-address"
      ).then((response) => {
        let defaultObject = null;

if (response.data && Array.isArray(response.data)) {
  defaultObject = response.data.find((obj) => obj.IsDefault === true);
}
        setAlladdresses(response.data?response.data:"");
        setaddresses(defaultObject?defaultObject:"");
      })
      .catch((error) => {
        console.error("Error loading addresses: " + error);
      });
  };

  useEffect(() => {
    loadAddresses();
  }, []);

useEffect(()=>{
    selectedAddress(addresses)
},[addresses])

  return (
    <div className="col-lg-12">
      <div className="account-card">
        <div className="account-title">
          <h4>Delivery Address</h4>
          <Link to="" className="btn btn-secondary" onClick={handleToggleAddressForm}>
            Change address
          </Link>
        </div>

        <Row className="mt-30">
          <Col lg={12}>
            <div className="">
              <Row className="head-add row d-flex">
                <div
                  style={{
                    backgroundColor: "#f0f0f0",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  {addresses ? (
                    <>
                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        Address:
                      </label>
                      <h6
                        style={{
                          fontSize: "16px",
                          margin: "5px 0",
                        }}
                      >
                        {addresses.Address}
                      </h6>

                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        State:
                      </label>
                      <h6
                        style={{
                          fontSize: "16px",
                          margin: "5px 0",
                        }}
                      >
                        {addresses.StateName}
                      </h6>

                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        City:
                      </label>
                      <h6
                        style={{
                          fontSize: "16px",
                          margin: "5px 0",
                        }}
                      >
                        {addresses.CityName}
                      </h6>

                      <label
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        Pincode:
                      </label>
                      <h6
                        style={{
                          fontSize: "16px",
                          margin: "5px 0",
                        }}
                      >
                        {addresses.Pincode}
                      </h6>
                    </>
                  ) : (
                    <Link to="" className="btn btn-secondary" onClick={handleToggleAddressForm}>
                      <div className="addcheckoutres">
                      {allAddresses.length === 0
                        ? "Add Address To Checkout"
                        : "Make One Address Default To Checkout"}
                      </div>
                    </Link>
                  )}
                </div>
              </Row>
            </div>
          </Col>
        </Row>
       {addressForm && <AddressForm loadAddresses2={loadAddresses}/>}
      </div>
    </div>
  );
}

export default Address;
