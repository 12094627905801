import axios from "axios"; // Import Axios
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const TrustableBrand = () => {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    // Make the API request using Axios in the useEffect hook
    axios
      .get(process.env.REACT_APP_BASE_URL + "/brandMaster/get-brand-master")
      .then((response) => {
        setBrands(response.data); // Update the brands state with the API data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <React.Fragment>
      <section className="brand-area pt-100">
        <div className="container">
          <div className="section-title">
            <h2>Our Trusted Brand</h2>
          </div>
          <div className="row justify-content-center">
            {brands &&
              brands.map((brand, index) => (
                <div key={index} className="col-sm-4 col-lg-3">
                  <div className="brand-item">
                    <Link to="#">
                      <img
                        src={
                          process.env.REACT_APP_BASE_URL +
                          "/" +
                          brand.BrandImage
                        }
                        width={100}
                        alt="Brand"
                      />
                      {/* <h1 className="dashTitle" style={{ color: "grey" }}>
                      {brand.BrandName}
                    </h1> */}
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default TrustableBrand;
