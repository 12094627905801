import axios from "axios";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import Subscribe from "../Component/Subscribe";
import {
  getCart,
  getLoggedinUserInfo,
  loginCustomer,
} from "../helpers/backen_helper";
import { storeCart, storeLoggedinUserInfo } from "../state/action";
import Shape1 from "./../assets/img/shape1.png";
import Shape2 from "./../assets/img/shape2.png";

const Login = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const loggedinUserInfo = useSelector((state) => state.loggedinUserInfo);

  const handleLogin = async (values) => {
    try {
      const response = await loginCustomer({
        email: values.email,
        password: values.password,
      });

      const authToken = response.token;
      localStorage.setItem("token", authToken);
      axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;

      const cartResponse = await getCart(dispatch);
      dispatch(storeCart(cartResponse.data));
      
      const loggedUser = await getLoggedinUserInfo();
      dispatch(storeLoggedinUserInfo(loggedUser.data));
      
      navigate(-1);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  // Creating schema
  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is a required ")
      .email("Invalid email format")
      .matches(/@.*(\.com|\.net)$/, "Email must end with either '.com' or '.net'"),

    password: Yup.string()
      .required("Password is a required ")
      .min(2, "Password must be at least 2 characters"),
  });
  return (
    <React.Fragment>
      {/* ///Home page slide section */}
      <HaderTwo />
      {/* <SliderTwo Title="Login" SubTitle="Login" /> */}
      <div className="user-area ptb-100">
        <div className="container">
          <Formik
            validationSchema={schema}
            initialValues={{ email: "", password: "" }}
            onSubmit={(values) => {
              // Alert the input values of the form that we filled

              // const responce = dispatch( login(
              //   {
              //     email:values.email,
              //     password:values.password,
              //     navigate
              //   }
              // ) )
              //
              handleLogin(values);

              // const headers = {

              //   'Authorization': responce.token
              // };

              // axios.get('http://localhost:5000/customer/getCart',  {
              //   headers: headers,
              // }).then((response) => {

              //
              // })
              // .catch((error) => {

              //   console.error(error);
              // })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <div className="login">
                <div className="form">
                  {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                  <div className="user-item">
                    <form noValidate onSubmit={handleSubmit}>
                      <h2>Login</h2>
                      {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          placeholder="Enter email id / username"
                          className="form-control"
                          id="email"
                        />
                        {/* If validation is not passed show errors */}
                        <p className="error">
                          {errors.email && touched.email && errors.email}
                        </p>
                      </div>
                      {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                      <div className="form-group">
                        <input
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          placeholder="Enter password"
                          className="form-control"
                        />
                        {/* If validation is not passed show errors */}
                        <p className="error">
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </p>
                      </div>
                      {/* Click on submit button to submit the form */}
                      <div className="resetPasswordDiv">
                        <Link className="resetLink" to="/chang-password">
                          Forget Password?
                        </Link>
                      </div>
                      <button type="submit" className="btn common-btn">
                        Login
                        <img src={Shape1} alt="Shape" />
                        <img src={Shape2} alt="Shape" />
                      </button>
                      <h5>
                        Don't Have an account? <a href="/register">Register</a>
                      </h5>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
      <Subscribe />
      <Footer />
    </React.Fragment>
  );
};

export default Login;
