import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";
import { BrowserRouter, Routes } from "react-router-dom";
import "./App.css";
import "./css/NavBar.css";
import "./css/responsive.css";
import AboutUS from "./page/AboutUS";
import AllOffer from "./page/AllOffer";
import ChangPassword from "./page/ChangPassword";
import Checkout from "./page/Checkout/CheckOut";
import ConformOrderBill from "./page/ConformOrderBill";
import ContactUs from "./page/ContactUs";
import DealerApllication from "./page/DealerApllication";
import FAQ from "./page/FAQ";
import FullCustome from "./page/FullCustome";
import Gallery from "./page/Gallery";
import GalleryDetails from "./page/GalleryDetails";
import GlobalDealer from "./page/GlobalDealer";
import Home from "./page/Home";
import Innovation from "./page/Innovation";
import Login from "./page/Login";
import MyCart from "./page/MyCart";
import MyProfile from "./page/MyProfile";
import NewsDetails from "./page/NewsDetails";
import PrivacyPolicy from "./page/PrivacyPolicy";
import ProductDetails from "./page/ProductDetails";
import ProductList from "./page/ProductList";
import Register from "./page/Register";
import RestoringAndReparing from "./page/RestoringAndReparing";
import ReturnPolicy from "./page/ReturnPolicy";
import SemiCustome from "./page/SemiCustome";
import SizeChart from "./page/SizeChart";
import TermsAndCondition from "./page/TermsAndCondition";
import VideoGallery from "./page/VideoGallery";
import WishList from "./page/WishList";

import Address_Book from "./page/Address_Book";
import Build_Your_Skate from "./page/Build_Your_Skate";

import AllCategory from "./page/AllCategory";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getCart, getLoggedinUserInfo } from "./helpers/backen_helper";
import { storeCart, storeLoggedinUserInfo } from "./state/action";

import OrderHistory from "./page/OrderHistory";
import TrackMyOrder from "./page/TrackMyOrder";
import PrintOrderBill from "./page/printOrderBill";
import CongratulationsAlert from "./page/congratulation";
import NewsAndEvents from "./page/NewsAndEvents";
import MaintenancePage from "./page/maintanance";
import NewPassword from "./page/newPassword";
import Route from './Routes';

function App() {
  const dispatch = useDispatch();

  const token = window.localStorage.getItem("token");

  const [loading, setLoading] = useState(false);

  //
  const fetchLoggedinUserInfo = async () => {
    try {
      setLoading(true);
      const loggedUser = await getLoggedinUserInfo();

      dispatch(storeLoggedinUserInfo(loggedUser.data));
      const caartData = await getCart(dispatch);
      dispatch(storeCart(caartData.data));

      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchLoggedinUserInfo();
    }
  }, []);

  return (
    <React.Fragment>
      {
        // <BrowserRouter>
        //   <Routes>
        //     <Route path="/" element={<MaintenancePage/>} />
        //     <Route path="/home" element={<Home />} />
        //     <Route path="/gallery" element={<Gallery />} />
        //     <Route path="/video-gallery" element={<VideoGallery />} />
        //     <Route path="/galleryDetails" element={<GalleryDetails />} />
        //     <Route path="/login" element={<Login />} />
        //     <Route path="/faq" element={<FAQ />} />
        //     <Route path="/size-chart" element={<SizeChart />} />
        //     <Route path="/register" element={<Register />} />
        //     <Route path="/about-us" element={<AboutUS />} />
        //     <Route path="/return-policy" element={<ReturnPolicy />} />
        //     <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        //     <Route path="/product-list" element={<ProductList />} />
        //     <Route path="/product-list/:id" element={<ProductList />} />
        //     <Route path="/semi-custom" element={<SemiCustome />} />
        //     <Route path="/full-custome" element={<FullCustome />} />
        //     <Route path="/contact-us" element={<ContactUs />} />
        //     <Route path="/termAndConditions" element={<TermsAndCondition />} />
        //     <Route path="/product-details/:productName/:id" element={<ProductDetails />} />
        //     <Route path="/view-all-offer" element={<AllOffer />} />
        //     <Route path="/global-dealer" element={<GlobalDealer />} />
        //     <Route path="/dealer-application" element={<DealerApllication />} />
        //     <Route path="/news-and-event" element={<NewsAndEvents />} />
        //     <Route path="/restoring-reparing" element={<RestoringAndReparing />}
        //     <Route path="/proces-to-checkout" element={<Checkout />} />
        //     <Route path="/innovation" element={<Innovation />} />
        //     <Route path="/my-wishList" element={<WishList />} />
        //     <Route path="/my-cart" element={<MyCart />} />
        //     <Route path="/conform-order-bill" element={<ConformOrderBill />} />
        //     <Route path="/print-order" element={<PrintOrderBill />} />
        //     <Route path="/chang-password" element={<ChangPassword />} />
        //     <Route path="/myProfile" element={<MyProfile />} />
        //     <Route path="/traack-my-order" element={<TrackMyOrder />} />
        //     <Route path="/newsDetails" element={<NewsDetails />} />
        //     <Route path="/build-your-skate" element={<Build_Your_Skate />} />
        //     <Route path="/address-book" element={<Address_Book />} />
        //     <Route path="/order-history" element={<OrderHistory />} />
        //     <Route path="/all-categories" element={<AllCategory />} />
        //     <Route path="/congo/:msg" element={<CongratulationsAlert />} />
        //     <Route path="/newpassword/:data" element={<NewPassword/>} />
        //   </Routes>
        // </BrowserRouter>
        <BrowserRouter>
        <Route></Route>
        </BrowserRouter>
      }
    </React.Fragment>
  );
}

export default App;
