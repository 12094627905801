import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(Link)`
  display: flex;
  color: black;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: transparent;
    font-size:20px;
    cursor: pointer;
    
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
  color: #fff;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    font-size:18px;
    color:#2ec1ac
    
  }
`;

const DropdownLink = styled(Link)`
  background: trasnparent;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  border:1px solid 
  font-size: 18px;

  &:hover {
    /* background: grey; */
    color:#fff;
    cursor: pointer;
  }
`;

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink  to={item.path} onClick={item.subNav && showSubnav}>
        <div>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div className="text-dark">
          {item.subNav && subnav
            ? React.cloneElement(item.iconOpened, { color: "#fff" }) // Set the icon color to black
            : item.subNav
            ? React.cloneElement(item.iconClosed, { color: "#fff" }) // Set the icon color to black
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink to={item.path} key={index}>
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
