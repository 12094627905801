import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import ReactPlayer from "react-player";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import axios from "axios";

const VideoGallery = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [videos,setVideos] = useState([]);
  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async()=>{
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL+`/videogallerymaster/get-active-video-gallery-master`
      );
      setVideos(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error occurred:", error);
      setIsLoading(false);
    }
  }
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="Video Gallery" SubTitle="Video Gallery" />

          <section className="blog-area ptb-100">
            <Container>
              <Row className=" justify-content-center">
                {videos.length>0 && videos.map((item)=>
                <Col sm="6" lg="4" key={item._id}>
                <div className="blog-item">
                  <div className="top">
                    <ReactPlayer
                      //url="https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"
                      // url="https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_480_1_5MG.mp4"
                      url={item.VideoUrl}
                      playsinline
                      playing={false}
                      controls={true}
                      // volume={null}
                      // muted={false}
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </Col>
                )}
                
                
              </Row>
            </Container>
          </section>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default VideoGallery;
