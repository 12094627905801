import React from "react";
import { Navigate } from "react-router-dom";

import AboutUS from "../page/AboutUS";
import AllOffer from "../page/AllOffer";
import ChangPassword from "../page/ChangPassword";
import Checkout from "../page/Checkout/CheckOut";
import ConformOrderBill from "../page/ConformOrderBill";
import ContactUs from "../page/ContactUs";
import DealerApllication from "../page/DealerApllication";
import FAQ from "../page/FAQ";
import FullCustome from "../page/FullCustome";
import Gallery from "../page/Gallery";
import GalleryDetails from "../page/GalleryDetails";
import GlobalDealer from "../page/GlobalDealer";
import Home from "../page/Home";
import Innovation from "../page/Innovation";
import Login from "../page/Login";
import MyCart from "../page/MyCart";
import MyProfile from "../page/MyProfile";
import NewsDetails from "../page/NewsDetails";
import PrivacyPolicy from "../page/PrivacyPolicy";
import ProductDetails from "../page/ProductDetails";
import ProductList from "../page/ProductList";
import Register from "../page/Register";
import RestoringAndReparing from "../page/RestoringAndReparing";
import ReturnPolicy from "../page/ReturnPolicy";
import SemiCustome from "../page/SemiCustome";
import SizeChart from "../page/SizeChart";
import TermsAndCondition from "../page/TermsAndCondition";
import VideoGallery from "../page/VideoGallery";
import WishList from "../page/WishList";

import Address_Book from "../page/Address_Book";
import Build_Your_Skate from "../page/Build_Your_Skate";

import AllCategory from "../page/AllCategory";
import OrderHistory from "../page/OrderHistory";
import TrackMyOrder from "../page/TrackMyOrder";
import PrintOrderBill from "../page/printOrderBill";
import CongratulationsAlert from "../page/congratulation";
import NewsAndEvents from "../page/NewsAndEvents";
import MaintenancePage from "../page/maintanance";
import NewPassword from "../page/newPassword";
import TrustableBrand from "../Component/TrustableBrand";
import OrderInvoice from "../page/OrderDetails";

const authProtectedRoutes = [
  { path: "/my-wishList", component: <WishList /> },
  { path: "/my-cart", component: <MyCart /> },
  { path: "/conform-order-bill", component: <ConformOrderBill /> },
  { path: "/print-order", component: <PrintOrderBill /> },
  { path: "/myProfile", component: <MyProfile /> },
  { path: "/traack-my-order", component: <TrackMyOrder /> },
  { path: "/build-your-skate", component: <Build_Your_Skate /> },
  { path: "/address-book", component: <Address_Book /> },
  { path: "/order-history", component: <OrderHistory /> },
  // {path:"/ozdf",component:<TrustableBrand></TrustableBrand>}
];

const publicRoutes = [
  { path: "/", component: <Home /> },
  { path: "/congo/:msg", component: <CongratulationsAlert /> },

  { path: "/newpassword/:data", component: <NewPassword /> },
  { path: "/all-categories", component: <AllCategory /> },
  { path: "/newsDetails/:_id", component: <NewsDetails /> },
  { path: "/chang-password", component: <ChangPassword /> },
  { path: "/gallery", component: <Gallery /> },
  { path: "/video-gallery", component: <VideoGallery /> },
  { path: "/galleryDetails/:category", component: <GalleryDetails /> },
  { path: "/login", component: <Login /> },
  { path: "/faq", component: <FAQ /> },
  { path: "/size-chart", component: <SizeChart /> },
  { path: "/register", component: <Register /> },
  { path: "/about-us", component: <AboutUS /> },
  { path: "/return-policy", component: <ReturnPolicy /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/product-list", component: <ProductList /> },
  { path: "/product-list/:id", component: <ProductList /> },
  { path: "/semi-custom", component: <SemiCustome /> },
  { path: "/full-custome", component: <FullCustome /> },
  { path: "/contact-us", component: <ContactUs /> },
  { path: "/termAndConditions", component: <TermsAndCondition /> },
  { path: "/product-details/:productName/:ProductId/:ProductColorId/:ProductGroupSizeId", component: <ProductDetails /> },
  { path: "/view-all-offer", component: <AllOffer /> },
  { path: "/global-dealer", component: <GlobalDealer /> },
  { path: "/dealer-application", component: <DealerApllication /> },
  { path: "/news-and-event", component: <NewsAndEvents /> },
  { path: "/restoring-reparing", component: <RestoringAndReparing /> },
  { path: "/proces-to-checkout", component: <Checkout /> },
  { path: "/innovation", component: <Innovation /> },
  { path: "/home", component: <Home /> },
  // { path: "/orderDetail", component: <PrintOrderBill /> },
  { path: "/orderDetail/:id", component: <OrderInvoice /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/" />,
  },
  { path: "*", component: <Navigate to="/" /> },
];

export { authProtectedRoutes, publicRoutes };

// const  product =[{ 
//   color:" ",
//   product:"id",
//   relatedTo: ""
// }]

// product = {
//   ...oldFiled,
//   isMainProduct:boolean
// }

