import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BiSolidCommentDetail } from "react-icons/bi";
import { BsCalendar2WeekFill } from "react-icons/bs";
import { Puff } from "react-loader-spinner";
import { Link } from "react-router-dom";
import BottomHeader from "../Component/BottomHeader";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";
import NewsImg from "../assets/img/offer-1.jpg";
import axios from "axios";
const NewsDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [newsData, setNewsData] = useState([]);
  const [currentId, setCurrentId] = useState(null);
  const [currentIdData, setCurrentIdData] = useState(null);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)

    setIsLoading(true);
    let loc = window.location.href;
    loc = loc.split("/");
    loc = loc[loc.length-1];
    fetchData(loc)
   
    setIsLoading(false);
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  const fetchData = async (_id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL+`/eventMaster/get-event-master/${_id}`
      );
      setNewsData(response.data[0]);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  // useEffect( ()=>{

  // } , [currentId])
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title=" News & Events" SubTitle=" News & Events" />
          <BottomHeader />
          <div className="blog-details-area ptb-100">
            <Container>
              <Row>
                <Col >
                  <div className="details-item">
                    <div className="details-img">
                      <img src={`${process.env.REACT_APP_BASE_URL}/${newsData.EventImage}`} alt="news" />
                      <div dangerouslySetInnerHTML={{ __html: newsData.Description }}/>
                    </div>
                  </div>
                </Col>
                
              </Row>
            </Container>
          </div>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default NewsDetails;
