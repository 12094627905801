import React, { useEffect, useState } from "react";
import { Puff } from "react-loader-spinner";
import BestProduct from "../Component/BestProduct";
import BestSeling from "../Component/BestSeling";
import BottomHeader from "../Component/BottomHeader";
import Categories from "../Component/Categories";
//import /cuCouponCode from "../Component/CopyCode";
import ClientTestimal from "../Component/ClientTestimal";
import DealOfTheDay from "../Component/DealOfTheDay";
import Delivery from "../Component/Delivery";
import Footer from "../Component/Footer";
import NewAraival from "../Component/NewAraival";
import Offer from "../Component/Offer";
import ScrollButton from "../Component/ScrollButton";
import Sidebar from "../Component/Sidebar/Sidebar-components/Sidebar";
import Subscribe from "../Component/Subscribe";
import TrustableBrand from "../Component/TrustableBrand";
import ReactImageMagnify from "react-image-magnify";
import HaderSLider from "../Component/Slider";


const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <Sidebar />
             <HaderSLider />
          <BottomHeader />
          <Offer />
          <Categories />         

          <NewAraival />
          <BestProduct />
          <DealOfTheDay />
          <TrustableBrand />
          <BestSeling />
          {/* <ClientTestimal /> */}
          <Delivery />
          {/* <Blog /> */}
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default Home;
