import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Puff } from "react-loader-spinner";
import { Link } from "react-router-dom";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";

const AllOffer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [offerData, setOfferData] = useState([]);

  const formatDate = (mongoDate) => {
    const date = new Date(mongoDate); // Convert MongoDB date string to JavaScript Date object
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because getMonth() returns 0-indexed (0 for January)
    const year = date.getFullYear();

    // Padding single-digit day or month with leading zero if necessary
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Formatted date in dd-mm-yyyy format
    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  useEffect(() => {
    // Make an API request to fetch offer data
    axios
      .get(process.env.REACT_APP_BASE_URL + "/offerMaster/get-offer-master")
      .then((response) => {
        setOfferData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, []);
  const [couponCode, setCouponCode] = useState("YOUR_COUPON_CODE");
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async (promoCode) => {
    try {
      await navigator.clipboard.writeText(promoCode);
    } catch (error) {
      throw new Error("Failed to copy to clipboard", error);
    }
  };

  const notify = (message, icon) =>
    toast(message, {
      duration: 2000,
      position: "top-center",

      // Styling
      style: {
        backgroundColor: "black",
        color: "white",
      },
      className: "",

      // Custom Icon
      icon: icon,

      // Change colors of success/error/loading icon
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },

      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });

  const handleCopyClick = async (promoCode) => {
    try {
      await copyToClipboard(promoCode);
      setIsCopied(true);
      notify("coupon code copied", "©");
    } catch (error) {
      console.error("Failed to copy coupon code:", error);
    }
  };

  return (
    <React.Fragment>
      <Toaster />
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title=" View All Offers" SubTitle=" View All Offers" />

          <div className="products-area ptb-100">
            <div className="container">
              <div className="section-title">
                <h2> Our Latest Posts</h2>
              </div>
              <div className="row">
                {offerData.map((data, index) => (
                  <div key={index} className="col-sm-6 col-lg-4">
                    <div className="products-item">
                      <div className="top">
                        <img
                          src={
                            process.env.REACT_APP_BASE_URL +
                            "/" +
                            data.OfferImage
                          }
                          alt="Products"
                        />
                        <div className="inner">
                          <h3>
                            <a href="#">{data.OfferName}</a>
                          </h3>
                          <p style={{ marginBottom: "0px" }}>
                            Offer On: {data.OfferOn}
                          </p>
                          <p style={{ marginBottom: "0px"}}>
                            Offer On Start Date: {formatDate(data.OfferStartDate)}
                          </p>
                          <p style={{ marginBottom: "0px" }}>
                            Offer On End Date: {formatDate(data.OfferEndDate)}
                          </p>
                          <p className="descriptionDiv">
                            <h3>
                              <Link className="viewLink" to="/product-details">
                                {data.OfferDescription}
                              </Link>{" "}
                            </h3>
                          
                           
                          </p>
                          <div style={{ margin: "15px 0px" }}>
                            <Link
                              onClick={() => handleCopyClick(data.PromoCode)}
                              disabled={isCopied}
                              className="cuponLink"
                              to="#"
                            >
                              {data.PromoCode}
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/* <div>
                        <button onClick={handleCopyClick} disabled={isCopied}>
                          {isCopied ? "Copied!" : "Copy to Clipboard"}
                        </button>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default AllOffer;
