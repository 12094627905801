import React, { useEffect, useState } from "react";
import { FaQuestion, FaSearch } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import "../Component/Sidebar/NavSidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { storeSelectedCurrency } from "../state/action";
import { current } from "@reduxjs/toolkit";
import { Button } from "react-bootstrap";
import axios from "axios";
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function BottomHeader({productPage}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedinUserInfo = useSelector((state) => state.loggedinUserInfo);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [Currencyrates, setCurrencyrates] = useState({});
  // const [selectedCurrency,setSelectedCurrency] =useState()
  const selectedCurrency = useSelector((state) => state.selectedCurrency);
  const [currency, setCurrency] = useState([]);

  useEffect(() => {
    setCurrency([
      { currency: "USD", symbol: "$", value: Currencyrates?.USD },
      { currency: "INR", symbol: "₹", value: Currencyrates?.INR },
      { currency: "EUR", symbol: "€", value: Currencyrates?.EUR },
    ]);

  }, [Currencyrates]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/orderDetail/get-currency-rates`
        );
        setCurrencyrates(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCurrencyChange = (event) => {
    const selected = currency.find((item) => item.value == event.target.value);
    dispatch(storeSelectedCurrency(selected));
  };
  const handleBYSClick = () => {

    if (loggedinUserInfo !== null) {
      navigate("/build-your-skate");
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="container-fluid">
      <div className="row bottom-part">
        {/* <div className="col-md-5 col-12 d-flex  align-items-center position-relative ">
          <FaSearch color="grey" className="mx-2 search-icon" />
          <input
            type="text"
            className="form-controlTwo  "
            placeholder="Search"
          />
          
        </div> */}
       {!productPage && <div className="col-md-5 col-12 d-flex  align-items-center position-relative ">
        <FormControl sx={{ m: 1, width: '40ch', backgroundColor: 'white' }} variant="outlined" size="small">
          <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type='text'
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <IoIosSearch/>
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        </div>}
        <div className={productPage ? "col-md-12 col-12 text-md-end text-center d-flex justify-content-between align-items-center help-btn-div" : "col-md-7 col-12 col-sm-12 text-md-end text-center d-flex justify-content-between align-items-center help-btn-div"}>
          {/* <div className="mainDivCustomise">
            <div className="inner">
              <Link className="customiseBtn" to="#">
                Customise Shoes
              </Link>
            </div>
          </div> */}
          {/* <Link
            to="/contact-us"
            className="text-decoration-none text-dark d-flex   text-start me-5 align-items-center need-help-div"
          >
            <FaQuestion className="me-1" />
            <span className="need-help-span">Need Help?</span>
          </Link> */}
         <div className="inner">
            <Button
              className="btn btn-light"
              classname="skateresp"
              onClick={() => {
                handleBYSClick();
              }}
            >
              Build Your Skate
            </Button>
          </div>
          <div>
            <select
              className="skateresp"
              id="currency"
              style={{
                padding: "8px",
                fontSize: "16px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
              value={selectedCurrency.value}
              onChange={handleCurrencyChange}
            >
              {currency &&
                currency.map((currencyOption, index) => (
                  <option key={index} value={currencyOption.value}>
                    {currencyOption.currency} {currencyOption.symbol}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomHeader;