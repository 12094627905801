import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-bootstrap/Carousel";
import ContactImg2 from "./../assets/img/bootamslider.jpeg"
import ContactImg3 from "./../assets/img/bootamSlider2.jpeg"
import ContactImg from "./../assets/img/Jhpn-4860040.jpg";
import ProductCardComponent from "./productGroupCard";

const Categories = () => {
  const [productGroupData, setProductGroupData] = useState(null);
  const [index, setIndex] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState('');

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const getProductGroupData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/productGroupMaster/get-product-group-master`
      );
      setProductGroupData(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getProductGroupData();
  }, []);
  useEffect(() => {
    // Fetch data from your API
    axios.get(`${process.env.REACT_APP_BASE_URL}/categorybannerMaster/get-category-banner-master`)
      .then(response => {
        // Assuming response.data is an array of objects containing background image URLs
        const filteredData = response.data.filter(item => item.BannerType === 'slider' && item.IsActive);

        if (filteredData.length > 0) {
        
          setBackgroundImage(filteredData); // Set the background image URL
          console.log(backgroundImage)
        } else {    
          console.warn("No matching data found for");
        }
      
      
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
      
  }, []); 

 
  return (
    <React.Fragment>
      <div className="products-area two ">
        <div className="container">
          <Row>
            <Col lg="6" md="12">
             
              <Carousel interval={2000} activeIndex={index} onSelect={handleSelect}>
              {backgroundImage &&
                  backgroundImage.map((item,index) => (
                <Carousel.Item>
                 <img
                      className="categoryImg"
                      src={`${process.env.REACT_APP_BASE_URL}/${item.BannerImage}`}
                      alt={`Slider ${index + 1}`}
                    />
                    
                </Carousel.Item>
                  ))}
              </Carousel>
            </Col>
            <Col lg="6" md="12">
              <Row>
                {productGroupData &&
                  productGroupData.map((item) => (
                    <Col lg="4" md="4" className="col-6">
                      <ProductCardComponent
                        key={item._id}
                        image={`${process.env.REACT_APP_BASE_URL}/${item.ProductGroupImage}`}
                        name={item.ProductGroupName}
                        id={item._id}
                      />
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Categories;
