import React from "react";
import { Row } from "react-bootstrap";
import Logo from "../assets/img/logo.png";
import "../css/conformOrderBill.css";
import Product from "./../assets/img/product.png";

const PrintOrderBill = () => {
  return (
    <React.Fragment>
      <main className="mainSection" id="shop">
        <div className="container" style={{ border: "1px dotted gray" }}>
          <div className="flat-row-title my-account">
            <h3 className="haderTitle">
              <strong>Order Statement</strong>
            </h3>

            <div className="clearfix"></div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="main-shop box-checkout">
                <div className="wrap-imagebox">
                  <div className="checkout-login">
                    <Row className="billRow">
                      <div className="col-md-5">
                        <img
                          src={Logo}
                          className="img-responsive"
                          //   style="width:50%"
                        />
                      </div>

                      <div className="col-md-7">
                        <div className="invoice-address">
                          <h4 style={{ color: "#fff" }}> Simmons Rana </h4>

                          <p style={{ color: "#fff" }}>
                            <strong>Address :</strong> Vadodara Gujrat
                          </p>

                          <p style={{ color: "#fff" }}>
                            <strong>Customer Care Email :</strong>
                            sales@simmonsrana.com
                          </p>

                          <p style={{ color: "#fff" }}>
                            <strong>Customer Care No. :</strong> (+91) 98253
                            22522
                          </p>
                        </div>
                      </div>
                    </Row>

                    <hr />

                    <div className="row mt-30">
                      <div className="col-md-12">
                        <h2 className="order-ttl"> Order Details </h2>
                      </div>

                      <div className="col-md-6 col-lg-4">
                        <table className="meta">
                          <tbody>
                            <tr>
                              <th>Order No</th>

                              <td> EGM6FG11B6N </td>
                            </tr>

                            <tr>
                              <th>Invoice No</th>

                              <td> EGM6FG11B6N </td>
                            </tr>

                            <tr>
                              <th>Order Date</th>

                              <td> 21-05-2019 10:18:26 AM </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="col-md-6 col-lg-4">
                        <table className="meta">
                          <tbody>
                            <tr>
                              <th>No of Items</th>

                              <td> 2 </td>
                            </tr>

                            <tr>
                              <th>Payment Mode</th>

                              <td> PayUmoney </td>
                            </tr>

                            <tr>
                              <th>Dilevery Date</th>

                              <td> Sat 25-05-2019 </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="col-md-12 col-lg-4">
                        <table className="meta">
                          <tbody>
                            <tr>
                              <th>Discount Value</th>

                              <td> $ 0.00 </td>
                            </tr>

                            <tr>
                              <th>Shipping Charge</th>

                              <td> $ 110.00 </td>
                            </tr>

                            <tr>
                              <th>Total Amount</th>

                              <td> $ 610.00 </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <hr />

                    <div className="row mt-30">
                      <div className="col-sm-6">
                        <h2 className="order-ttl"> Billing Details </h2>

                        <table className="meta-2">
                          <tbody>
                            <tr>
                              <th>Name</th>

                              <td> Rushil Patel </td>
                            </tr>

                            <tr>
                              <th>Address</th>

                              <td>
                                {" "}
                                305 Darpan Complex Near Express Hotel, Alkapuri,
                                Vadodara, Gujarat 390005 Gujarat.{" "}
                              </td>
                            </tr>

                            <tr>
                              <th>Email Id</th>

                              <td> rushil@barodaweb.net </td>
                            </tr>

                            <tr>
                              <th>Mobile No</th>

                              <td> 9824281021 </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="col-sm-6">
                        <h2 className="order-ttl xs-sp-add">
                          {" "}
                          Shipping Address{" "}
                        </h2>

                        <table className="meta-2">
                          <tbody>
                            <tr>
                              <th>Name</th>

                              <td> Rushil Patel </td>
                            </tr>

                            <tr>
                              <th>Address</th>

                              <td>
                                {" "}
                                305 Darpan Complex Near Express Hotel, Alkapuri,
                                Vadodara, Gujarat 390005 Gujarat.{" "}
                              </td>
                            </tr>

                            <tr>
                              <th>Email Id</th>

                              <td> rushil@barodaweb.net </td>
                            </tr>

                            <tr>
                              <th>Mobile No</th>

                              <td> 9824281021 </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <hr />

                    <div className="row mt-30">
                      <div className="col-sm-12">
                        <h2 className="order-ttl text-center">
                          {" "}
                          Order Summary{" "}
                        </h2>

                        <div className="table table-responsive">
                          <table className="meta-1 order-summary">
                            <tbody>
                              <tr>
                                <th> Sr.No </th>
                                <th> Image </th>
                                <th> Iteam Description </th>
                                <th> Unit Price </th>
                                <th className="text-center"> Quantity </th>
                                <th className="text-center"> Item Offer </th>
                                {/* <th>
                                  {" "}
                                  CGST <br /> Rate <br /> (%) <br /> Amount{" "}
                                </th>
                                <th>
                                  {" "}
                                  SGST <br /> Rate <br /> (%) <br /> Amount
                                </th>
                                <th>
                                  {" "}
                                  IGST <br /> Rate <br /> (%) <br /> Amount
                                </th> */}
                                <th className="text-center"> Total Price </th>
                              </tr>

                              <tr>
                                <td className="text-center"> 1 </td>
                                <td className="img-dlt text-center">
                                  {" "}
                                  <img
                                    className="wishListImg"
                                    src={Product}
                                  />{" "}
                                </td>
                                <td>
                                  <div className="description-p">
                                    <p className="product-category">
                                      Product Category
                                    </p>
                                    <p className="product-name">Product Name</p>
                                    <p className="product-code">
                                      (<b>Product Code:</b> JFN1004)
                                    </p>
                                  </div>
                                </td>
                                <td> $ 250.00 </td>
                                <td className="text-center"> 1 </td>
                                <td className="text-center"> - </td>
                                {/* <td> ₹ 6.25 </td>
                                <td> ₹ 6.25 </td>
                                <td> ₹ 00.00 </td> */}
                                <td className="text-right"> $ 250.00 </td>
                              </tr>

                              <tr>
                                <td className="text-center"> 2 </td>
                                <td className="img-dlt text-center">
                                  <img className="wishListImg" src={Product} />
                                </td>
                                <td>
                                  <div className="description-p">
                                    <p className="product-category">
                                      Product Category
                                    </p>
                                    <p className="product-name">Product Name</p>
                                    <p className="product-code">
                                      (<b>Product Code:</b> JFN1005)
                                    </p>
                                  </div>
                                </td>
                                <td> $ 250.00 </td>
                                <td className="text-center"> 1 </td>
                                <td className="text-center"> - </td>
                                {/* <td> ₹ 6.25 </td>
                                <td> ₹ 6.25 </td>
                                <td> ₹ 00.00 </td> */}
                                <td className="text-right"> $ 250.00 </td>
                              </tr>

                              <tr>
                                <td colspan="4" className="text-left">
                                  {" "}
                                  * Includes GST component{" "}
                                </td>

                                <td colspan="2" className="text-right fw-700">
                                  {" "}
                                  Subtotal :{" "}
                                </td>

                                <td className="text-right"> $ 500.00 </td>
                              </tr>

                              <tr>
                                <td colspan="4" className="text-left">
                                  {" "}
                                  ^ Order Item Weight or Price Modified Signs{" "}
                                </td>

                                <td colspan="2" className="text-right fw-700">
                                  {" "}
                                  Weight Based Shipping (+) <br />
                                  (Weight: 1.600) - Rs 110.00 <br /> ( 2 to 3
                                  working days delivery ) :{" "}
                                </td>

                                <td className="text-right"> $ 110.00 </td>
                              </tr>

                              <tr>
                                <td colspan="4" className="text-left">
                                  {" "}
                                </td>

                                <td colspan="2" className="text-right fw-700">
                                  {" "}
                                  Discount Value (-) :{" "}
                                </td>

                                <td className="text-right"> $ 00.00 </td>
                              </tr>

                              <tr>
                                <td colspan="4" className="text-left">
                                  {" "}
                                </td>

                                <td colspan="2" className="text-right fw-700">
                                  {" "}
                                  Gift Voucher (-) :{" "}
                                </td>

                                <td className="text-right"> $ 00.00 </td>
                              </tr>

                              <tr>
                                <td colspan="4" className="text-left">
                                  Total Invoice Value (In Words) : Rs. Six
                                  Hundred And Thirty-Five Ruppes Only{" "}
                                </td>

                                <td colspan="2" className="text-right fw-700">
                                  {" "}
                                  Total :{" "}
                                </td>

                                <td className="text-right"> $ 610.00 </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div className="row mt-30">
                      <div className="col-sm-12">
                        <h2 className="order-ttl"> Offer List </h2>

                        <div className="mlr-20 offer-panel">
                          <p className="fw-700">
                            Welcome To{" "}
                            <span className="main-color">Simmons Rana</span>{" "}
                            Special Offer
                          </p>

                          <p>
                            <span className="fw-700">Description :</span> No
                            Offer
                          </p>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div className="row mt-30">
                      <div className="col-sm-12">
                        <div className="mlr-20 offer-panel">
                          {/* <p className="text-left">
                            {" "}
                            * Includes GST component{" "}
                          </p>

                          <p className="text-center">
                            {" "}
                            Total invoice value (In Figure) : ₹ 635.00{" "}
                          </p>

                          <p className="text-center">
                            {" "}
                            Total invoice value (In Words) : Six Hundred And
                            thirty-five Ruppes{" "}
                          </p> */}
                          <p className="text-center">
                            This is system generated invoice statement, It's not
                            required any Digital signature.
                          </p>

                          {/* <p className="text-right">Signature</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default PrintOrderBill;
