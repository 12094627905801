import React, { useEffect, useState } from "react";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../assets/img/logo.png";
import mobBannerImage from "../../../assets/img/mob-banner.jpg";
import backgroundVideo from "../../../assets/video/haderVideo1.mp4";
import AddToCart from "../../addTocart";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import haderBackground from "../../../assets/img/black-texture.avif";

import HaderSLider from "../../Slider";

const BackgroundVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Cover the entire header container */
  z-index: -1;
  object-fit: cover;
`;
const SidebarWrap = styled.div`
  width: 100%;
  color: black !important; /* Set the text color to black */
`;

const Nav = styled.div`
background-image: url(${haderBackground});
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 84rem;
  font-size: 1.5rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const NavIcon1 = styled(Link)`
  margin-left: 22rem;
  font-size: 1.6rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: black;
`;
const Logo = styled.img`
  width: 250px;
  height: auto;
  position: absolute;
  padding: 0 30px;
`;
const Logo1 = styled.img`
  width: 180px;
  height: auto;
  position: absolute;
  left: 15px;
  top: 28px;
`;
const CartButton = styled(Link)`
  font-size: 1.1rem;
  color: #fff !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  left: -195px;
`;
const JoinButton = styled(Link)`
  font-size: 1rem;
  background: transparent;
  color: #fff !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: -180px; /* Adjust the margin as needed */

  @media (max-width: 450px) {
    margin-left: -239px;
  }
`;

const SidebarNav = styled.nav`
  background: rgb(2 96 193);
  width: 400px;
  background-image: url(${mobBannerImage});
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0px;
  overflow-y: auto;
  right: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 9999999999;
  background-size: cover;
  background-position: center;
`;

const HeaderContainer = styled.div`
  position: relative;
  // width: 100%;
  // height: 100vh;
  overflow: hidden;
`;

const Sidebar = () => {
  const dispatch = useDispatch();
  const [sidebar, setSidebar] = useState(false);
  const loggedinUserInfo = useSelector((state) => state.loggedinUserInfo);
  const token = window.localStorage.getItem("token");
  const showSidebar = () => setSidebar(!sidebar);

  const [showUserMenu, setShowUserMenu] = useState(false);
  const cartData = useSelector((state) => state.cart);

  const toggleUserMenu = () => {
    setShowUserMenu(!showUserMenu);
  };

  // const fetchLoggedinUserInfo = async () => {
  //   try {
  //     const loggedUser = await getLoggedinUserInfo();
  //
  //     dispatch(storeLoggedinUserInfo(loggedUser.data));
  //   } catch (error) {
  //     console.error("Error fetching user data:", error);
  //   }
  // };
  // useEffect(() => {
  //   if (loggedinUserInfo) {
  //     fetchLoggedinUserInfo();
  //   }
  // }, [loggedinUserInfo]);

  const divStyle = {
    backgroundColor: "white", // Set background color to white
    borderRadius: "50%", // For rounded-full effect
    width: "15px", // Set your desired width
    height: "15px",
    // Set your desired height
  };

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timerId = setTimeout(() => {
      // Set showContent to true after the delay
      setShowContent(true);
    }, 5000);

    // Clear the timeout if the component unmounts before the delay is complete
    return () => clearTimeout(timerId);
  }, []);

  return (
    <>
      {/* <BackgroundVideo autoPlay loop muted>
        <source src={backgroundVideo} type="video/mp4" />
      </BackgroundVideo> */}
   
      <IconContext.Provider value={{ color: "#fff" }}>
        <HeaderContainer>
          <Nav>
            <NavIcon to="#" className="toggle-div" id="mini-mobile-toggle-div">
              <FaIcons.FaBars onClick={showSidebar} className="toggle-btn" />
            </NavIcon>
            <Logo
              onClick={() => {
                window.location.href = "/";
              }}
              className="nav-logo"
              src={logo}
              alt="logo"
              style={{ cursor: "pointer" }}
            />

            {token ? (
              <>
                <CartButton className="nav-cart absolute" to="#">
                  <AddToCart/>

                  {showContent && loggedinUserInfo && (
                    <div>
                      {" "}
                      <p className="text-black text-center cart-num">
                        {" "}
                        {cartData?.length}{" "}
                      </p>{" "}
                    </div>
                  )}
                </CartButton>
                <JoinButton to="/myProfile" className="frontpage-resp">
                  {" "}
                  {/* <FaUser style={{ padding: "2px" }} /> */}
                  {loggedinUserInfo ? 
                  <div style={{border:'1px solid', borderRadius:"50%", padding:"5px", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"white", color:"grey", fontWeight:"bolder"}}>
                  {loggedinUserInfo.FName.split("")[0]+loggedinUserInfo.FName.split("")[1]}
                </div>
                  : "login/signup"}
                </JoinButton>
              </>
            ) : (
              <JoinButton to="/login" className="login-sign-btn frontpage-resp">
                {" "}
                <FaUser style={{ padding: "2px" }} />
                Login/Signup
              </JoinButton>
            )}
            <div className="sidebar d-none">
              <div
                className="join-button"
                onMouseEnter={toggleUserMenu}
                onMouseLeave={toggleUserMenu}
              >
                <span>Username</span>
                {showUserMenu && (
                  <div className="user-menu">
                    <Link to="/myProfile">Profile</Link>
                    <Link to="/chang-password">Change Password</Link>
                    <Link to="/my-wishList">My Wishlist</Link>
                    <Link to="#">Logout</Link>
                  </div>
                )}
              </div>
            </div>
          </Nav>

          <SidebarNav sidebar={sidebar} className="nav-main-div">
            <SidebarWrap style={{ color: "#fff" }}>
              <Logo1 className="nav-logo" src={logo} alt="logo" />
              <NavIcon1 to="#" className="close-btn">
                <AiIcons.AiOutlineClose
                  onClick={showSidebar}
                  color="black"
                  style={{ borderRadius: " 12px", color: "#fff" }}
                />
              </NavIcon1>
              {SidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })}
            </SidebarWrap>
          </SidebarNav>
        </HeaderContainer>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
