import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AiFillDelete } from "react-icons/ai";
import { Puff } from "react-loader-spinner";
import { Link } from "react-router-dom";
import HaderTwo from "../Component/HaderTwo";
import SliderTwo from "../Component/SliderTwo";

import { useDispatch, useSelector } from "react-redux";
import {
  getCart,
  removeFromCart,
  updateQuantity,
} from "../helpers/backen_helper";

const MyCart = () => {
  const currency = useSelector((state) => state.selectedCurrency);

  const notify = (message, icon) =>
    toast(message, {
      duration: 2000,
      position: "top-right",

      // Styling
      style: {},
      className: "",

      // Custom Icon
      icon: icon,

      // Change colors of success/error/loading icon
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },

      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isQtyLoading, setIsQtyLoading] = useState(false);
  const [selectedIndex, setIndex] = useState(null);
  const cartData = useSelector((state) => state.cart);

  const totalOfCartItems = useSelector((state) => state.cartTotal);

  const updateQuantityOfProduct = async (productId, quantity) => {
    try {
      setIsQtyLoading(true);
      const caartData = await updateQuantity(productId, quantity);
      getCart(dispatch);
      setIsQtyLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    setIsLoading(true);

    getCart(dispatch);

    setIsLoading(false);
  }, []);

  return (
    <React.Fragment>
      <Toaster />
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="View Cart" SubTitle="View Cart" />
          <Container>
            <div className="account-card">
              <div className="account-title">
                <h4>Your cart</h4>
              </div>
              <div className="account-content">
                <div className="table-scroll">
                  <table className="table-list">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartData
                        ? cartData.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <img
                                  className="wishListImg"
                                  src={`${process.env.REACT_APP_BASE_URL}/${
                                    item.ProductImages[0]
                                      ? item.ProductImages[0].Image
                                      : null
                                  }`}
                                  alt="img"
                                />
                              </td>
                              <td>{item.ProductName}</td>
                              <td>{`${currency.currency} ${
                                currency.symbol
                              } ${parseFloat(
                                (item.MRP * currency.value).toFixed(2)
                              ).toFixed(2)}`}</td>
                              <td>
                                <div>
                                  <p style={{ fontSize: "14px" }}>QTY</p>
                                  <div className="counterMainDiv">
                                    <button
                                      disabled={isQtyLoading || item.Qty < 2}
                                      className="incrementBtn"
                                      onClick={() => {
                                        updateQuantityOfProduct(item._id, {
                                          Qty: item.Qty - 1,
                                        });
                                        setIndex(index);
                                      }}
                                    >
                                      -
                                    </button>{" "}
                                    <span className="counting">
                                      {!isQtyLoading ? (
                                        item.Qty ? (
                                          item.Qty
                                        ) : (
                                          0
                                        )
                                      ) : selectedIndex === index ? (
                                        <FontAwesomeIcon
                                          icon={faCircleNotch}
                                          spin
                                        />
                                      ) : (
                                        item.Qty
                                      )}
                                    </span>{" "}
                                    <button
                                      disabled={isQtyLoading}
                                      className="incrementBtn"
                                      onClick={() => {
                                        updateQuantityOfProduct(item._id, {
                                          Qty: item.Qty + 1,
                                        });
                                        setIndex(index);
                                      }}
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td>{`${currency.currency} ${currency.symbol} ${(
                                item.Qty *
                                (item.MRP * currency.value)
                              ).toFixed(2)}`}</td>
                              <td
                                onClick={async () => {
                                  const success = await removeFromCart(
                                    item._id
                                  );
                                  if (success) {
                                    getCart(dispatch);
                                    notify("item removed from cart.", "🗑️");
                                  }
                                }}
                              >
                                <AiFillDelete
                                  style={{
                                    fontSize: "30px",
                                    color: "#57cdbc",
                                    cursor: "pointer",
                                  }}
                                />
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="shopingDiv">
              <div className="inner">
                <Link className="customiseBtn cutomiseButton1" to="/">
                  Continue Shopping
                </Link>
              </div>
              {cartData ? (
                <div className="mainDivContinueCart">
                  <h6 className="cartTitle">CART TOTAL</h6>
                  <div style={{ display: "flex", gap: "1px" }}>
                    <p className="totalTitle totalword">Total :</p>
                    <p className="totalTitle totalamount">{`${currency.currency} ${
                      currency.symbol
                    } ${(totalOfCartItems * currency.value).toFixed(2)}`}</p>
                  </div>
                  <div className="checkout-responsive">
                  {cartData.length > 0 ? (
                    <Link className="customiseBtn cutomiseButton1" to="/proces-to-checkout">
                      Proceed To Checkout
                    </Link>
                  ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default MyCart;
