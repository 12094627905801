import Carousel from "react-bootstrap/Carousel";
// import SLiderImg1 from "./../assets/img/banner-1.jpg"
// import SLiderImg2 from "./../assets/img/banner-2.jpg"
// import SLiderImg3 from "./../assets/img/banner-3.jpg"
// import SLiderImg4 from "./../assets/img/banner-4.jpg"
import SLiderImg5 from "./../assets/img/banner-5.jpg";
import SLiderImg6 from "./../assets/img/banner-6.jpg";
import SLiderImg7 from "./../assets/img/banner-7.jpg";
import SLiderImg8 from "./../assets/img/banner-8.jpg";
import YouTube from 'react-youtube';
import React, { useEffect, useState } from "react";
import axios from "axios";


// import ExampleCarouselImage from 'components/ExampleCarouselImage';

function HaderSLider() {
  const [imageData,setImageData] = useState([]);
  const [videoEnded, setVideoEnded] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_BASE_URL }/bannerMaster/get-active-banner`)
    .then((res)=>setImageData(res.data))
    .catch((err)=>{})
  },[])
  const opts = {
    height: '500',
    width: '100%',
    playerVars: {
      autoplay: 1,
      mute: 1,
      controls: 0,
      showinfo: 0,
      modestbranding: 1,
      loop: 1,
      rel: 0,
      origin: window.location.origin,
    },
  };
const   onEnd = (event) => {
  setVideoEnded(true);
  event.target.seekTo(0); // Seek back to the beginning of the video
};
const handleSlide = (selectedIndex) => {
  // If the video hasn't ended yet and the selected slide is the video slide, prevent the carousel from sliding
  if (!videoEnded && selectedIndex === imageData.length) {
    return false;
  }
  setActiveIndex(selectedIndex);
};
  return (
    <>
  <Carousel fade pause="hover" activeIndex={activeIndex} onSelect={handleSlide}>
    {imageData && imageData.map((item) => {
      if (item.BannerType === "image" || item.BannerType === undefined ) {
        return (
          <Carousel.Item key={item.id}>
            <a href={item.ButtonUrl}>
              <img className="sliderBanner" src={`${process.env.REACT_APP_BASE_URL}/${item.BannerImage}`} alt="banner" />
            </a>
          </Carousel.Item>
        );
      } else {
        return (
          <Carousel.Item key={item.id}>
            <div style={{ width: '100%' }}>
              <YouTube
                videoId={item.BannerImage}
                opts={opts}
                onEnd={onEnd}
                className="youtube-video"
              />
            </div>
          </Carousel.Item>
        );
      }
    })}
  </Carousel>
</>

  
  );
}

export default HaderSLider;
