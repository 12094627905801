import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import BottomHeader from "../Component/BottomHeader";
import Footer from "../Component/Footer";
import HaderTwo from "../Component/HaderTwo";
import ScrollButton from "../Component/ScrollButton";
import SliderTwo from "../Component/SliderTwo";
import Subscribe from "../Component/Subscribe";

const FullCustome = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="Full Custom" SubTitle="Full Custom" />
          <BottomHeader />
          <Container>
            <div className="sizeChartContainer">
              <p>
                We cast your foot to make a mold. We then create an exact
                replica of your foot to make your boots from. This is an art
                that we have perfected. For youth boots we can allow for growing
                room at your request. We can cast your feet at an event we
                attend, at our shops in Florida (USA) and Ahmedabad (India), or
                you can do the casting yourself.
              </p>
              <Row>
                <Col lg="6" md="6">
                  <iframe
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen=""
                    className="mt-4"
                    frameborder="0"
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/ME3xvqArasA"
                  ></iframe>
                </Col>
                <Col lg="6" md="6">
                  <h4>
                    Call us for a casting kit (deposit required) for casting
                    your own feet. Watch Video for casting instructions.
                  </h4>
                  <p>The custom boots are purely handmade</p>
                  <p>The carbon fiber comfortably hugs your foot.</p>
                  <p>
                    This unique light weight design will give you the best power
                    transfer and control.
                  </p>
                  <p>
                    You choose colors and extras (name, glitter*, etc.) to be
                    placed on the boot.
                  </p>
                  <h3> Glitter is extra $100.00</h3>
                  <h4>Checkout our Gallery for custom boots pictures</h4>
                </Col>
              </Row>
            </div>
          </Container>
          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};

export default FullCustome;
