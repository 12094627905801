import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../Component/Footer";
import HaderTwo from "../../Component/HaderTwo";
import ScrollButton from "../../Component/ScrollButton";
import SliderTwo from "../../Component/SliderTwo";
import Subscribe from "../../Component/Subscribe";
import ConformOrderModal from "../../assets/modal/ConformModal";
import "../../css/checkout.css";
import { getCart, updateQuantity } from "../../helpers/backen_helper";
import { storeCart } from "../../state/action";
import Address from "./Address";
import OrderItems from "./OrderItems";

import image from "../../assets/img/logo.png";
// import RenderRazorpay from "./RanderRazorPay";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const Checkout = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [addresses, setaddresses] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const dispatch = useDispatch();

  const cartData = useSelector((state) => state.cart);
  const currency = useSelector((state) => state.selectedCurrency);
  const totalOfCartItems = useSelector((state) => state.cartTotal);

  const fetchData = async () => {
    try {
      const caartData = await getCart(dispatch);
      dispatch(storeCart(caartData.data));
    } catch (error) {}
  };

  const handleCheakout = async () => {
    try {
      setButtonLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/orderMaster/processPayment`,
        {
          currency: currency.currency,
          keyId: process.env.REACT_APP_RAZORPAY_KEY_ID,
          KeySecret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
          country: addresses.CountryName,
        }
      );

      // const response = await axios.post(
      //   `${process.env.REACT_APP_BASE_URL}/orderMaster/processPayment`,
      //   {
      //     ShippingAddId: addresses._id,
      //     Currency: currency.currency,
      //     OrderItems: cartData,
      //     keyId: process.env.REACT_APP_RAZORPAY_KEY_ID,
      //     KeySecret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
      //     SubTotal: totalOfCartItems * currency.value,
      //     Email: addresses.EmailId,
      //     Address: addresses.Address,
      //     Mobile: addresses.MobileNo,
      //   }
      // );

      setButtonLoading(false);
      const paymentOrderId = response?.PaymentOrder?.id || null;
      const paymentAmount = response?.PaymentOrder?.amount || null;
      const paymentCurrency = response?.PaymentOrder?.currency || null;

      DisplayRazorpay(
        paymentOrderId,
        paymentAmount,
        paymentCurrency,
        addresses.FirstName,
        addresses.MobileNo,
        addresses.EmailId
      );
    } catch (error) {
      console.error("Error during checkout:", error);
      setButtonLoading(false);
    }
  };

  const DisplayRazorpay = async (
    orderId,
    _amount,
    _currency,
    _name,
    _contact,
    _email
  ) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("razorpay failed to load.");
      return;
    }
    if (!orderId) {
      alert("order id not found");
      return;
    }

    var options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      KeySecret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
      amount: _amount,
      currency: _currency,
      name: "Simmonsrana",
      description: "Test Transaction",
      image: image,
      order_id: orderId,
      prefill: {
        name: _name,
        email: _email,
        contact: _contact,
      },
      handler: async function (res) {
        setIsLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/orderMaster/saveOrder`,
          {
            ShippingAddId: addresses._id,
            Currency: currency.currency,
            OrderItems: cartData,
            SubTotal: totalOfCartItems * currency.value,
            Email: addresses.EmailId,
            Address: addresses.Address,
            Mobile: addresses.MobileNo,
            TransactionObj:res
          }
        );
        setIsLoading(false);

        navigate("/congo/orderSuccess");
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#000000",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.description, response.error.reason);
    });

    // const paymentObject = new window.Razorpay(options)
    rzp1.open();
  };

  useEffect(() => {
    if (!cartData) {
      fetchData();
      setIsLoading(false);
    }
    setIsLoading(false);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <HaderTwo />
          <SliderTwo Title="Checkout" SubTitle=" Checkout" />

          <section className="inner-section checkout-part">
            <div className="container">
              <div className="row">
                <OrderItems addresses={addresses}></OrderItems>

                <Address selectedAddress={setaddresses}></Address>
                {addresses && (
                  <div className="col-lg-12">
                    <div className="account-card mb-0">
                      <div className="account-title">
                        <h4>proceed to payment</h4>
                      </div>

                      <div className="checkout-proced">
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#contact-add"
                          onClick={() => handleCheakout()}
                          className="checkOutBtn"
                          disabled={buttonLoading}
                        >
                          {buttonLoading ? "Loading..." : "Continue"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>

          <Subscribe />
          <Footer />
          <ScrollButton />
        </div>
      )}
    </React.Fragment>
  );
};
export default Checkout;
