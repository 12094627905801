import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import freeShipping from "../../assets/img/free-shipping.png";

function OrderItems(props) {
  const currency = useSelector((state) => state.selectedCurrency);

  const [CartSummery, setCartSummery] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const CartSummery = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/orderMaster/getCartSummery`
        );
        setCartSummery(CartSummery.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData().then(() => {
      setIsLoading(false);
    });

    //  getProductGroupData();
  }, []);

  return (
    <div className="col-lg-12 ">
      <div className="alert-info">
        <p>you are just a few step away from placing your order</p>
      </div>
      <div className="account-card">
        <div className="account-title">
          <h4>Your order</h4>
        </div>

        <div className="account-content">
          <div className="table-scroll">
            <table className="table-list">
              <thead>
                <tr>
                  <th scope="col">Sr No</th>
                  <th scope="col">Product</th>
                  <th scope="col">Name</th>
                  <th scope="col">Price</th>
                  <th scope="col">quantity</th>
                  {props.addresses && props.addresses.CountryName === "India" ? (
                    <th scope="col">tax</th>
                  ) : null }
                  <th scope="col">sub total</th>
                </tr>
              </thead>
              <tbody>
                {CartSummery
                  ? CartSummery?.cartItems.map((item, index) => (
                      <tr key={index}>
                        <td className="table-serial">
                          <h6>{index + 1}</h6>
                        </td>
                        <td className="table-image">
                          <img
                            className="wishListImg"
                            src={`${process.env.REACT_APP_BASE_URL}/${
                              item.ProductImages[0]
                                ? item.ProductImages[0].Image
                                : null
                            }`}
                            alt="product"
                          />
                        </td>
                        <td className="table-name">
                          <h6>{item.productName}</h6>
                        </td>
                        <td className="table-price">
                          <h6 style={{ color: "#49b857" }}>
                            {currency.symbol}
                            {(item.MRP * currency.value).toFixed(2)}
                            <small>/piece</small>
                          </h6>
                        </td>
                        <td className="table-quantity">
                          <h6> {item.Qty ? item.Qty : 0}</h6>
                        </td>
                        {props.addresses && props.addresses.CountryName === "India" ? (
                          <td className="table-quantity">
                            <h6>
                              {" "}
                              {currency.symbol}{" "}
                              {(item.totalTax * currency.value).toFixed(2)}
                            </h6>
                          </td>
                        ) :  null }

                        <td className="table-action">
                          <h6 className="total-price">
                            {" "}
                            {currency.symbol}{" "}
                            {(item.totalPrice * currency.value).toFixed(2)}
                          </h6>
                        </td>
                      </tr>
                    ))
                  : null}

                {props.addresses && props.addresses.CountryName === "India" ? (
                  <>
                    <tr>
                      <td colSpan="1" className="text-right">
                        <strong>Total:</strong>
                      </td>
                      <td colSpan="4" style={{ paddingRight:'100px',fontWeight:'bold' }}>
                        Amount to be Paid:
                      </td>
                      <td>
                        {`${currency.symbol} ${(
                          CartSummery?.totalTax * currency.value
                        )?.toFixed(2)}`}
                      </td>
                      <td>
                        <strong>
                          {`${currency.symbol} ${(
                            CartSummery?.totalWithoutTax * currency.value
                          )?.toFixed(2)}`}
                        </strong>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="5" className="text-right">
                        <strong>Total (Including Tax):</strong>
                      </td>
                      {/* <td colSpan="4"></td> */}
                      <td colSpan="2">
                        <strong>
                          {`${currency.symbol} ${(
                            CartSummery?.totalIncludingTax * currency.value
                          )?.toFixed(2)}`}
                        </strong>
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td colSpan="1" className="text-right">
                        <strong>Total :</strong>
                      </td>
                      <td colSpan="4" style={{ paddingRight:'100px',fontWeight:'bold' }}>
                        Amount to be Paid:
                      </td>
                      <td colSpan="2">
                        <strong>
                          {`${currency.symbol} ${(
                            CartSummery?.totalWithoutTax * currency.value
                          )?.toFixed(2)}`}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5" className="text-right">
                        <strong>Applied Offers :</strong>
                      </td>
                      <td colSpan="2">
                        {CartSummery?.ShippingCharge === 0 ? (
                          <img
                            src={freeShipping}
                            alt="free shipping"
                            style={{ height: "70px", width: "70px" }}
                          ></img>
                        ) : (
                          "no offers applied"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5" className="text-right">
                        <strong>Shipping Charge:</strong>
                      </td>
                      <td colSpan="2">
                        {`${currency.symbol} ${(
                          CartSummery?.ShippingCharge * currency.value
                        )?.toFixed(2)}`}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5" className="text-right">
                        <strong>Grand Total:</strong>
                      </td>
                      <td colSpan="2">
                        {`${currency.symbol} ${(
                          CartSummery?.totalIncludingShippingCharge *
                          currency.value
                        ).toFixed(2)}`}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderItems;
