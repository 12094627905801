import { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { AiOutlineShoppingCart, AiTwotoneDelete } from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getCart,
  removeFromCart,
  updateQuantity,
} from "../helpers/backen_helper";

import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";

function OffCanvasExample({ Color, name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const notify = (message, icon) =>
    toast(message, {
      duration: 2000,
      position: "top-right",

      // Styling
      style: {},
      className: "",

      // Custom Icon
      icon: icon,

      // Change colors of success/error/loading icon
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },

      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isQtyLoading, setIsQtyLoading] = useState(false);
  const [selectedIndex, setIndex] = useState(null);

  const cartData = useSelector((state) => state.cart);
  const currency = useSelector((state) => state.selectedCurrency);

  const updateQuantityOfProduct = async (productId, quantity) => {
    try {
      setIsQtyLoading(true);
      await updateQuantity(productId, quantity);
      getCart(dispatch);
      setIsQtyLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    getCart(dispatch);
  }, [show]);

  return (
    <>
      <FaIcons.FaShoppingCart
        onClick={handleShow}
        style={{ color: Color }}
        color={Color}
      />
      <Offcanvas
        style={{ zIndex: "999999" }}
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="addCartTitle">
              <h6 style={{ color: "#8b2883" }}>Add Cart</h6>
              {cartData ? (
                cartData.length > 0 ? (
                  <h6 style={{ color: "#8b2883" }}>
                    Total ({cartData ? cartData.length : null})
                  </h6>
                ) : null
              ) : null}
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="cart-list">
            {cartData ? (
              cartData.length > 0 ? (
                cartData.map((item, index) => (
                  <li className="cart-item" key={index}>
                    <div className="cart-media">
                      <a href="#">
                        <img
                          className="wishListImg"
                          src={`${process.env.REACT_APP_BASE_URL}/${
                            item.ProductImages[0]
                              ? item.ProductImages[0].Image
                              : null
                          }`}
                          alt="img"
                        />
                      </a>
                      <button className="cart-delete">
                        <i className="far fa-trash-alt">
                          <AiTwotoneDelete
                            className="deleteIcon"
                            style={{ color: "red" }}
                            onClick={async () => {
                              const success = await removeFromCart(item._id);
                              if (success) {
                                getCart(dispatch);
                                notify("item removed from cart.", "🗑️");
                              }
                            }}
                          />
                        </i>
                      </button>
                    </div>
                    <div className="cart-info-group">
                      <div className="cart-info">
                        <h6>
                          <a href="product-single.html">
                            {item.ProductName }
                          </a>
                        </h6>

                        <p>
                          {`${currency.currency} ${currency.symbol} `}
                          {(
                            item.MRP * currency.value
                          ).toFixed(2)}
                        </p>
                      </div>
                      <div className="cart-action-group">
                        <div className="counterMainDiv">
                          <button
                            disabled={isQtyLoading || item.Qty < 2}
                            className="incrementBtn"
                            onClick={() => {
                              updateQuantityOfProduct(item._id, {
                                Qty: item.Qty - 1,
                              });
                              setIndex(index);
                            }}
                          >
                            -
                          </button>{" "}
                          <span className="counting">
                            {!isQtyLoading ? (
                              item.Qty ? (
                                item.Qty
                              ) : (
                                0
                              )
                            ) : selectedIndex === index ? (
                              <FontAwesomeIcon icon={faCircleNotch} spin />
                            ) : (
                              item.Qty
                            )}
                          </span>{" "}
                          <button
                            disabled={isQtyLoading}
                            className="incrementBtn"
                            onClick={() => {
                              updateQuantityOfProduct(item._id, {
                                Qty: item.Qty + 1,
                              });
                              setIndex(index);
                            }}
                          >
                            +
                          </button>
                        </div>

                        <h6>
                          <p>{`${currency.symbol} ${(
                            item.Qty *
                            (item.MRP * currency.value)
                          ).toFixed(2)}`}</p>
                        </h6>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <div className="d-flex justify-content-center">
                  <h4>Your cart is Empty</h4>
                </div>
              )
            ) : null}
          </ul>
          {cartData ? (
            cartData.length > 0 ? (
              <div className="cart-footer">
                {/* <button className="coupon-btn">Do you have a coupon code?</button> */}

                <Link className="cart-checkout-btn" to="/my-cart">
                  <span className="checkout-label">
                    View Cart <AiOutlineShoppingCart />
                  </span>
                </Link>
                
              </div>
            ) : null
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function AddToCart() {
  return (
    <>
      {["end"].map((placement, idx) => (
        <OffCanvasExample key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default AddToCart;
